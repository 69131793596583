import {
  CommandBar,
  DefaultButton,
  DetailsList,
  Dialog,
  DialogFooter,
  DialogType,
  IColumn,
  ICommandBarItemProps,
  Panel,
  PanelType,
  PrimaryButton,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from '@fluentui/react';
import React, { FC, useState, useEffect, useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import moment from 'moment';
import IAgentApp from '../../../../../../Models/API/IAgentApp';
import {
  INewAgentAppFunction,
  INewAgentAppSearchFunction,
  agentAppServiceContext,
} from '../../../../../../Services/API/AgentAppService';
import {
  AuthenticationType,
  IAgentAppBaseFunction,
  IAgentAppFunction,
  IAgentAppSearchFunction,
} from '../../../../../../Models/API/IAgentAppFunction';
import FunctionForm from './FunctionsForm';
import { trackPageView } from '../../../../../../Services/AppInsights';
import ConfigurationService from '../../../../../../Services/ConfigurationService';
import SearchFunctionForm from './SearchFunctionsForm';
import { useTranslation } from 'react-i18next';
import { LanguageServiceContext } from '../../../../../../Services/LanguageService';

const defaultNewFunction: INewAgentAppFunction = {
  DisplayName: '',
  Description: '',
  ReturnValue: '',
  ActionUrl: '',
  SystemPrompt: '',
  FunctionType: 'Function',
  AuthenticationType: AuthenticationType.PowerAutomate,
  Headers: [],
  OAuthParameters: {
    ClientId: '',
    ClientSecret: '',
    TokenEndpoint: '',
    Scope: '',
  },
};

const defaultNewSearchFunction: INewAgentAppSearchFunction = {
  DisplayName: '',
  Description: '',
  ActionUrl: '',
  SystemPrompt: '',
  InputParameters: '',
  OutputParameters: '',
  FunctionType: 'SearchFunction',
  TopResults: 5,
};

const AgentFunctions: FC = () => {
  const { agentApp } = useOutletContext<{
    agentApp: IAgentApp;
    setAgentApp: (value: IAgentApp) => void;
  }>();
  const agentAppsService = useContext(agentAppServiceContext);
  const languageService = useContext(LanguageServiceContext);

  const [commandBarButtons, setCommandBarButtons] = useState<ICommandBarItemProps[]>([]);
  const [functions, setFunctions] = useState<IAgentAppBaseFunction[] | null>();
  const [selectedFunctions, setSelectedFunctions] = useState<IAgentAppBaseFunction[]>([]);
  const [currentFunction, setCurrentFunction] = useState<
    IAgentAppBaseFunction | INewAgentAppFunction | INewAgentAppSearchFunction
  >();
  const [savingFunction, setSavingFunction] = useState<boolean>(false);
  const [hideRemoveFunctionDialog, setHideRemoveFunctionDialog] = useState<boolean>(true);

  const { t } = useTranslation();

  const columns: IColumn[] = [
    {
      key: 'DisplayName',
      name: t('MAIN.DISPLAY_NAME'),
      minWidth: 200,
      isResizable: true,
    },
    {
      key: 'FunctionType',
      name: t('MAIN.TYPE'),
      minWidth: 200,
      isResizable: true,
    },
    {
      key: 'ReturnValue',
      name: t('MAIN.RETURN_VALUE'),
      minWidth: 200,
      isResizable: true,
    },
    {
      key: 'Parameters',
      name: t('MAIN.NUM_PARAMETERS'),
      minWidth: 200,
      isResizable: true,
    },
    {
      key: 'Created',
      name: t('MAIN.CREATED'),
      minWidth: 100,
      isResizable: true,
    },
  ];

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${agentApp.DisplayName} Functions`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAgentAppFunction = async () => {
    setFunctions(undefined);
    const functions = await agentAppsService?.GetAllAgentFunctions(agentApp.Id);
    if (functions) {
      setFunctions(functions);
    }
  };

  useEffect(() => {
    const execute = async () => {
      await getAgentAppFunction();
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentApp]);

  // Runs when the selected functions change
  useEffect(() => {
    setCommandBarButtons([
      {
        key: 'add',
        text: t('MAIN.ADD'),
        iconProps: { iconName: 'Add' },
        subMenuProps: {
          items: [
            {
              key: 'function',
              text: t('CATALOG.FUNCTION'),
              iconProps: { iconName: 'LightningBolt' },
              onClick: (event, item) => {
                setCurrentFunction(defaultNewFunction);
              },
            },
            {
              key: 'searchFunction',
              text: t('CATALOG.SEARCH_FUNCTION'),
              iconProps: { iconName: 'LightningBolt' },
              onClick: (event, item) => {
                setCurrentFunction(defaultNewSearchFunction);
              },
            },
          ],
        },
      },
      {
        key: 'edit',
        text: t('MAIN.EDIT'),
        iconProps: { iconName: 'Edit' },
        onClick: (event, item) => {
          setCurrentFunction(selectedFunctions[0]);
        },
        disabled: selectedFunctions.length !== 1,
      },
      {
        key: 'delete',
        text: t('MAIN.DELETE'),
        iconProps: { iconName: 'Delete' },
        onClick: async (event, item) => {
          setHideRemoveFunctionDialog(false);
        },
        disabled: selectedFunctions.length === 0,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFunctions, languageService?.language]);

  const onRenderItemColumn = (
    item: IAgentAppBaseFunction,
    index?: number,
    column?: IColumn
  ): any => {
    if (column?.key === 'Required') {
      return item.DisplayName ? 'Yes' : 'No';
    } else if (column?.key === 'Parameters') {
      if (item.FunctionType === 'Function')
        return (item as IAgentAppFunction).Parameters.Properties.size;
    } else if (column?.key === 'ReturnValue') {
      if (item.FunctionType === 'Function') {
        return (item as IAgentAppFunction).ReturnValue;
      } else {
        return 'NA';
      }
    } else if (column?.key === 'Created') {
      return item.Created ? moment(item.Created as string).format('DD MMM yyyy') : '';
    } else {
      return item![column?.key as keyof IAgentAppBaseFunction];
    }
  };

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedFunctions(selection.getSelection() as IAgentAppBaseFunction[]);
    },
  });

  const removeFunctions = async () => {
    setHideRemoveFunctionDialog(true);
    if (selectedFunctions && selectedFunctions.length) {
      setFunctions(undefined);
      for (var i = 0; i < selectedFunctions.length; i++) {
        const selectedFunction = selectedFunctions[i];
        if (selectedFunction.FunctionType === 'Function') {
          await agentAppsService?.DeleteFunction(agentApp.Id, selectedFunctions[i].Id);
        } else if (selectedFunction.FunctionType === 'SearchFunction') {
          await agentAppsService?.DeleteSearchFunction(agentApp.Id, selectedFunctions[i].Id);
        }
      }
      getAgentAppFunction();
    }
  };

  const upsertFunction = async () => {
    if (!savingFunction) {
      setSavingFunction(true);
      if (currentFunction) {
        if (Object.hasOwn(currentFunction, 'Id')) {
          if (currentFunction.FunctionType === 'Function') {
            await agentAppsService?.UpdateFunction(
              agentApp.Id,
              currentFunction as IAgentAppFunction
            );
          } else if (currentFunction.FunctionType === 'SearchFunction') {
            await agentAppsService?.UpdateSearchFunction(
              agentApp.Id,
              currentFunction as IAgentAppSearchFunction
            );
          }
        } else {
          if (currentFunction.FunctionType === 'Function') {
            await agentAppsService?.CreateFunction(
              agentApp.Id,
              currentFunction as INewAgentAppFunction
            );
          } else if (currentFunction.FunctionType === 'SearchFunction') {
            var fn = currentFunction as INewAgentAppSearchFunction;
            await agentAppsService?.CreateSearchFunction(agentApp.Id, fn);
          }
        }
      }
      await getAgentAppFunction();
      setCurrentFunction(undefined);
      setSavingFunction(false);
    }
  };

  const onRenderFooterContent = React.useCallback(
    () => {
      const isFunctionValid =
        currentFunction?.FunctionType === 'Function' &&
        (!currentFunction?.DisplayName ||
          currentFunction.DisplayName.length < 5 ||
          !/^[ a-zA-Z0-9]*$/.test(currentFunction.DisplayName) ||
          !currentFunction.Description ||
          currentFunction.Description.length < 20);

      const isSearchFunctionValid =
        currentFunction?.FunctionType === 'SearchFunction' &&
        (!currentFunction?.DisplayName ||
          currentFunction.DisplayName.length < 5 ||
          !/^[ a-zA-Z0-9]*$/.test(currentFunction.DisplayName) ||
          !currentFunction.Description ||
          currentFunction.Description.length < 1 ||
          !currentFunction.ActionUrl ||
          currentFunction.ActionUrl.length < 15);

      return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <DefaultButton onClick={() => setCurrentFunction(undefined)}>Cancel</DefaultButton>
          <PrimaryButton
            style={{ minWidth: 80 }}
            onClick={upsertFunction}
            text={savingFunction ? '' : 'Save'}
            disabled={isFunctionValid || isSearchFunctionValid}
          >
            {savingFunction && <Spinner size={SpinnerSize.small} />}
          </PrimaryButton>
        </Stack>
      );
    },
    // eslint-disable-next-line
    [currentFunction, savingFunction]
  );

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0 }}>
        <Stack.Item>
          <CommandBar items={commandBarButtons} ariaLabel='Fields actions' />
        </Stack.Item>
        <Stack.Item verticalFill style={{ minHeight: 0, overflowY: 'auto', position: 'relative' }}>
          <DetailsList
            className='functions-list'
            setKey='items'
            items={functions || []}
            columns={columns}
            selection={selection}
            selectionMode={SelectionMode.single}
            onRenderItemColumn={onRenderItemColumn}
            ariaLabelForGrid='Item details'
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            styles={{ root: { verticalAlign: 'middle' } }}
          />
          {(functions === undefined || functions === null || functions.length === 0) && (
            <>
              {functions !== undefined && (functions === null || functions.length === 0) && (
                <Text
                  variant='large'
                  block
                  style={{
                    textAlign: 'center',
                    color: 'rgba(255,255,255,0.3)',
                  }}
                >
                  No functions found
                </Text>
              )}
              {functions === undefined && <Spinner size={SpinnerSize.large} />}
            </>
          )}
        </Stack.Item>
      </Stack>
      <Dialog
        hidden={hideRemoveFunctionDialog}
        onDismiss={() => setHideRemoveFunctionDialog(true)}
        modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } } }}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Remove Functions',
          subText: 'Are you sure you want to remove the following functions?',
        }}
      >
        <Stack tokens={{ childrenGap: 5 }}>
          {selectedFunctions.map(perm => {
            return (
              <Text key={perm.Id} block>
                {perm.DisplayName}
              </Text>
            );
          })}
        </Stack>
        <DialogFooter>
          <PrimaryButton onClick={removeFunctions} text='Remove' />
          <DefaultButton onClick={() => setHideRemoveFunctionDialog(true)} text='Cancel' />
        </DialogFooter>
      </Dialog>
      <Panel
        isOpen={currentFunction !== undefined}
        onDismiss={() => setCurrentFunction(undefined)}
        headerText={
          currentFunction && currentFunction.DisplayName
            ? currentFunction?.FunctionType === 'Function'
              ? 'Update Function'
              : 'Update search function'
            : currentFunction?.FunctionType === 'Function'
            ? 'Add Function'
            : 'Add search function'
        }
        closeButtonAriaLabel='Close'
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
        type={PanelType.medium}
      >
        {currentFunction ? (
          <>
            {currentFunction.FunctionType === 'Function' ? (
              <FunctionForm
                agentFunction={currentFunction as INewAgentAppFunction}
                setAgentFunction={setCurrentFunction}
              />
            ) : (
              <SearchFunctionForm
                agentFunction={
                  currentFunction as IAgentAppSearchFunction | INewAgentAppSearchFunction
                }
                setAgentFunction={setCurrentFunction}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </Panel>
    </>
  );
};

export default AgentFunctions;
