import { useState, useEffect, useContext } from 'react';
import {
  IconButton,
  Stack,
  Text,
  Dropdown,
  IDropdownOption,
  Callout,
  Spinner,
  SpinnerSize,
  DefaultButton,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { UserServiceContext } from '../../Services/API/UserService';
import IUserSettings from '../../Models/API/IUserSettings';
import AuthenticationService from '../../Services/AuthenticationService';
import IUserInformation from '../../Models/API/IUserInformation';
import styles from './UserMenu.module.scss';
import { LanguageServiceContext } from '../../Services/LanguageService';
import { useTranslation } from 'react-i18next';

const UserMenu = () => {
  const languageContext = useContext(LanguageServiceContext);
  const userService = useContext(UserServiceContext);
  const [user, setUser] = useState<IUserInformation>();
  const [settings, setSettings] = useState<IUserSettings | null>(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [savingAnalytic, setSavingAnalytic] = useState(false);
  const [savingLanguage, setSavingLanguage] = useState(false);
  const buttonId = useId('cogwheel');
  const [languageOptions, setLanguageOptions] = useState<IDropdownOption[]>([]);
  const { t } = useTranslation();

  const analyticsOptions: IDropdownOption[] = [
    { key: 'true', text: t('USER_SETTING.ANALYTICS_ON') as string },
    { key: 'false', text: t('USER_SETTING.ANALYTICS_OFF') as string },
  ];

  const handleLanguageChange = async (_event: any, option?: IDropdownOption) => {
    if (option) {
      const language = option.key as string;

      const updatedSettings: IUserSettings = {
        ...settings!,
        CurrentLanguage: language,
      };

      setSettings(updatedSettings);

      setSavingLanguage(true);
      await saveSettings(updatedSettings);
      setSavingLanguage(false);

      languageContext?.setLanguage(language);
    }
  };

  useEffect(() => {
    const init = async () => {
      const languages = await userService!.GetAvailableLanguages();
      const langOptions = languages?.map(lang => ({
        key: lang.Code,
        text: lang.DisplayName,
      }));

      setLanguageOptions(langOptions!);

      const userSettings = await userService!.GetSettings();
      setSettings(userSettings);

      const userInfo = await userService?.GetUserInformation();
      setUser(userInfo!);

      languageContext?.setLanguage(userSettings?.CurrentLanguage ?? 'en');
    };

    init();
  }, []);

  useEffect(() => {
    if (languageContext?.optInAnalytics !== null && languageContext?.optInAnalytics !== undefined) {
      if (!settings) return;
      setSettings({ ...settings, OptIntoAnalytics: languageContext?.optInAnalytics });
    }
  }, [languageContext?.optInAnalytics]);

  const handleAnalyticsChange = async (_: any, option?: IDropdownOption) => {
    if (!option || !settings) return;

    const updatedSettings: IUserSettings = {
      ...settings,
      OptIntoAnalytics: option.key === 'true',
    };

    setSettings(updatedSettings);

    setSavingAnalytic(true);
    await saveSettings(updatedSettings);
    languageContext?.setOptInAnalytics(option.key === 'true');
    setSavingAnalytic(false);
  };

  const saveSettings = async (updatedSettings: IUserSettings) => {
    await userService?.SaveSettings(updatedSettings);
  };

  const toggleSettings = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSettingsOpen(prev => !prev);
  };

  return (
    <Stack horizontal verticalAlign='center'>
      <Text variant='medium'>{user?.DisplayName}</Text>

      <IconButton
        iconProps={{ iconName: 'Settings' }}
        title='Settings'
        onClick={toggleSettings}
        id={buttonId}
      />

      {isSettingsOpen && (
        <Callout
          target={`#${buttonId}`}
          onDismiss={() => setIsSettingsOpen(false)}
          className={styles.userMenuCallout}
        >
          <Text className={styles.userMenuTitle}>{t('USER_SETTING.TITLE')}</Text>
          <Stack className={styles.stackItem}>
            <span className={styles.userMenuLabel}>{t('USER_SETTING.LANGUAGE_TITLE')}</span>

            {savingLanguage ? (
              <Spinner className={styles.userMenuSpinner} size={SpinnerSize.medium} />
            ) : (
              <Dropdown
                selectedKey={settings?.CurrentLanguage ?? 'en'}
                disabled={savingLanguage}
                options={languageOptions}
                onChange={handleLanguageChange}
                className={styles.userMenuDropdown}
              />
            )}
          </Stack>
          <Stack className={styles.stackItem}>
            <span className={styles.userMenuLabel}>{t('USER_SETTING.ANALYTICS_TITLE')}</span>

            {savingAnalytic ? (
              <Spinner className={styles.userMenuSpinner} size={SpinnerSize.medium} />
            ) : (
              <Dropdown
                selectedKey={settings?.OptIntoAnalytics?.toString() ?? 'true'}
                disabled={savingAnalytic}
                options={analyticsOptions}
                onChange={handleAnalyticsChange}
                className={styles.userMenuDropdown}
              />
            )}

            <DefaultButton
              onClick={() => AuthenticationService.Default.Logout()}
              className={styles.userMenuLogout}
            >
              {t('USER_SETTING.LOGOUT')}
            </DefaultButton>
          </Stack>
        </Callout>
      )}
    </Stack>
  );
};

export default UserMenu;
