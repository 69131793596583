import { FC, useContext, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import IAutomateApp from "../../../../../../Models/API/IAutomateApp";
import { automateAppServiceContext } from "../../../../../../Services/API/AutomateAppService";
import IPermission from "../../../../../../Models/API/IPermission";
import PermissionManagement from "../../../../../../Components/PermissionManagement/PermissionManagement";
import { trackPageView } from "../../../../../../Services/AppInsights";
import ConfigurationService from "../../../../../../Services/ConfigurationService";
import { useTranslation } from "react-i18next";

const AutomateAppPermissions: FC = () => {
  const { automateApp } = useOutletContext<{
    automateApp: IAutomateApp;
  }>();
  const automateAppsService = useContext(automateAppServiceContext);
  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${automateApp.DisplayName} Permissions`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _addPermission = async (objectId: string, permissionType: string) => {
    return await automateAppsService!.CreatePermission(
      automateApp.Id,
      objectId,
      permissionType as any
    );
  };

  const _deletePermission = async (objectId: string) => {
    await automateAppsService!.DeletePermission(automateApp.Id, objectId);
  };

  const _reloadPermissions = async () => {
    var perms = await automateAppsService!.GetPermissions(automateApp.Id);
    return perms as IPermission[];
  };

  const _checkPermissions = async (objectId: string) => {
    var perms = await automateAppsService!.CheckUserPermissions(
      objectId,
      automateApp.Id
    );
    return perms ?? [];
  };

  return (
    <PermissionManagement
      resourceType={"AutomateApp"}
      resourceId={automateApp.Id}
      permissions={{
        Read: {
          DisplayName: t("PERMISSIONS.READ"),
          Description: t("PERMISSIONS.READ_DESCRIPTION_2"),
        },
        Write: {
          DisplayName: t("PERMISSIONS.WRITE"),
          Description: t("PERMISSIONS.WRITE_DESCRIPTION_4"),
        },
        FullControl: {
          DisplayName: t("PERMISSIONS.FULL_CONTROL"),
          Description: t("PERMISSIONS.FULL_CONTROL_DESCRIPTION_4"),
        },
        Owner: {
          DisplayName: t("PERMISSIONS.OWNER"),
          Description: t("PERMISSIONS.OWNER_DESCRIPTION_4"),
        },
      }}
      isOwner={automateApp.PermissionType === "Owner"}
      addPermission={_addPermission}
      deletePermission={_deletePermission}
      reloadPermissions={_reloadPermissions}
      checkPermissions={_checkPermissions}
    />
  );
};

export default AutomateAppPermissions;
