import { FC, useContext, useEffect, useState } from "react";
import { LibraryServiceContext } from "../../../../../../../../Services/API/LibraryService";
import ILibrary from "../../../../../../../../Models/API/ILibrary";
import { useOutletContext } from "react-router-dom";
import {
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { trackPageView } from "../../../../../../../../Services/AppInsights";
import { useTranslation } from "react-i18next";

const LibrarySettings: FC = () => {
  var librariesService = useContext(LibraryServiceContext);
  const { library, setLibrary } = useOutletContext<{
    library: ILibrary;
    setLibrary: (value: ILibrary) => void;
  }>();
  const [tempLibrary, setTempLibrary] = useState<ILibrary>(library);
  const [saving, setIsSaving] = useState<boolean>(false);
  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateLibrary = async () => {
    if (tempLibrary && !saving) {
      setIsSaving(true);
      var refreshedLibrary = await librariesService?.Update(tempLibrary!.Id, {
        Title: tempLibrary.Title,
        Description: tempLibrary.Description,
      });
      if (refreshedLibrary !== null) {
        setLibrary(refreshedLibrary!);
      }
      setIsSaving(false);
    }
  };

  return (
    (tempLibrary && (
      <>
        <Stack
          style={{ minHeight: 0, overflowY: "auto" }}
          verticalFill
          tokens={{ childrenGap: 20 }}
        >
          <Stack.Item>
            <TextField
              required={true}
              label={t("LIBRARIES.LIBRARY_TITLE")}
              resizable={false}
              value={tempLibrary.Title}
              onChange={(event, newValue) =>
                setTempLibrary({
                  ...tempLibrary,
                  Title: newValue!,
                })
              }
            />
            <Text variant="xSmall">{t("MAIN.MIN_CHARACTERS", {count: 3})}</Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              label={t("LIBRARIES.LIBRARY_DESCRIPTION")}
              multiline
              rows={3}
              resizable={false}
              value={tempLibrary.Description}
              onChange={(event, newValue) =>
                setTempLibrary({
                  ...tempLibrary,
                  Description: newValue,
                })
              }
            />
          </Stack.Item>
          <Stack.Item>
            <PrimaryButton
              style={{ minWidth: 80 }}
              onClick={updateLibrary}
              disabled={!tempLibrary.Title || tempLibrary.Title.length < 3}
              text={saving ? "" : t("MAIN.SAVE")}
            >
              {saving && <Spinner hidden={!saving} size={SpinnerSize.small} />}
            </PrimaryButton>
          </Stack.Item>
        </Stack>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default LibrarySettings;
