import {
  CommandBar,
  DefaultButton,
  DetailsList,
  Dialog,
  DialogFooter,
  DialogType,
  IColumn,
  ICommandBarItemProps,
  Icon,
  Pivot,
  PivotItem,
  PrimaryButton,
  ProgressIndicator,
  SearchBox,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
} from '@fluentui/react';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import './DocumentPicker.scss';
import IDocument from '../../Models/API/IDocument';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import moment from 'moment';
import { chatAppServiceContext } from '../../Services/API/ChatAppService';
import IChatApp from '../../Models/API/IChatApp';
import { removeDiacritics } from '../../Utilities/Strings';
import IUserDocument from '../../Models/API/IUserDocument';
import { UserServiceContext } from '../../Services/API/UserService';
import { VALID_UPLOAD_FILE_EXTENSIONS } from '../../Pages/CatalogsEditor/Catalog/Applications/ChatApps/Libraries/Library/Documents/Documents';
import { DialogServiceContext } from '../../Services/Dialogs/DialogService';
import axios, { AxiosError, AxiosProgressEvent, CancelTokenSource } from 'axios';
import { t as translation } from 'i18next';
import { useTranslation } from 'react-i18next';
import { LanguageServiceContext } from '../../Services/LanguageService';

export interface IPickerDocument {
  id: number;
  name: string;
  type: 'library' | 'user';
}

export interface IDocumentPickerProps {
  chatApp: IChatApp;
  onClose: () => void;
  onSelect: (documents: IPickerDocument[]) => void;
}

const DocumentPicker: FC<IDocumentPickerProps> = ({ chatApp, onClose, onSelect }) => {
  const chatAppService = useContext(chatAppServiceContext);
  const userService = useContext(UserServiceContext);
  const languageService = useContext(LanguageServiceContext);

  const { t } = useTranslation();

  // Library Documents
  const [documents, setDocuments] = useState<IDocument[] | null>(null);
  const [filteredDocuments, setFilteredDocuments] = useState<IDocument[] | null | undefined>(
    undefined
  );
  const [selectedDocuments, setSelectedDocuments] = useState<IDocument[]>([]);
  const [filterKeyword, setFilterKeyword] = useState<string | undefined>(undefined);
  // User Documents
  const [userDocuments, setUserDocuments] = useState<IUserDocument[] | null>(null);
  const [filteredUserDocuments, setFilteredUserDocuments] = useState<
    IUserDocument[] | null | undefined
  >(undefined);
  const [selectedUserDocuments, setSelectedUserDocuments] = useState<IUserDocument[]>([]);
  const [filterUserKeyword, setFilterUserKeyword] = useState<string | undefined>(undefined);
  const [commandBarButtons, setCommandBarButtons] = useState<ICommandBarItemProps[]>([]);
  const [deleting, setDeleting] = useState<boolean>(false);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const dialogService = useContext(DialogServiceContext);
  const [hideUploadDialog, setHideUploadDialog] = useState<boolean>(true);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | undefined>(
    undefined
  );

  const libraryDocumentColumns: IColumn[] = [
    {
      key: 'Icon',
      name: '',
      minWidth: 20,
      maxWidth: 20,
      isResizable: false,
    },
    {
      key: 'FileName',
      name: t('LIBRARIES.FILE_NAME'),
      minWidth: 50,
      isResizable: true,
    },
    {
      key: 'LibraryTitle',
      name: t('LIBRARIES.LIBRARY'),
      minWidth: 100,
      isResizable: true,
    },
    {
      key: 'Created',
      name: t('MAIN.CREATED'),
      minWidth: 100,
      maxWidth: 100,
      isResizable: false,
    },
  ];
  
  const userDocumentColumns: IColumn[] = [
    {
      key: 'Icon',
      name: '',
      minWidth: 20,
      maxWidth: 20,
      isResizable: false,
    },
    {
      key: 'FileName',
      name: t('LIBRARIES.FILE_NAME'),
      minWidth: 50,
      isResizable: true,
    },
    {
      key: 'FileDate',
      name: t('MAIN.CREATED'),
      minWidth: 100,
      maxWidth: 100,
      isResizable: false,
    },
  ];

  // Runs when the filter keyword changes
  useEffect(() => {
    if (documents && filterKeyword !== undefined) {
      setFilteredDocuments(
        filterKeyword
          ? documents?.filter(doc => {
              return (
                removeDiacritics(doc.FileName.toLowerCase()).indexOf(
                  removeDiacritics(filterKeyword.toLowerCase())
                ) !== -1 ||
                removeDiacritics(doc.Metadata['title'].toLowerCase()).indexOf(
                  removeDiacritics(filterKeyword.toLowerCase())
                ) !== -1
              );
            })
          : documents
      );
    }
  }, [filterKeyword, documents]);

  // Runs when the user filter keyword changes
  useEffect(() => {
    if (userDocuments && filterUserKeyword !== undefined) {
      setFilteredUserDocuments(
        filterUserKeyword
          ? userDocuments?.filter(doc => {
              return (
                removeDiacritics(doc.FileName.toLowerCase()).indexOf(
                  removeDiacritics(filterUserKeyword.toLowerCase())
                ) !== -1
              );
            })
          : userDocuments
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterUserKeyword, userDocuments]);

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedDocuments(selection.getSelection() as IDocument[]);
    },
  });

  const userSelection = new Selection({
    onSelectionChanged: () => {
      setSelectedUserDocuments(userSelection.getSelection() as IUserDocument[]);
    },
  });

  useEffect(() => {
    initDocuments();
    initUserDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initDocuments = async () => {
    setDocuments([]);
    setFilteredDocuments(undefined);
    let docs = await chatAppService?.GetDocuments(chatApp.Id);
    docs = docs!.sort((docA, docB) => docA.FileName.localeCompare(docB.FileName));
    setFilteredDocuments(docs);
    setDocuments(docs);
  };

  const initUserDocuments = async () => {
    setUserDocuments([]);
    setFilteredUserDocuments(undefined);
    let userDocs = await userService?.GetUserDocuments();
    userDocs = userDocs!.sort((docA, docB) => docA.FileName.localeCompare(docB.FileName));
    setFilteredUserDocuments(userDocs);
    setUserDocuments(userDocs);
  };

  const onRenderItemColumn = (item: IDocument, index?: number, column?: IColumn): any => {
    if (column?.key === 'Icon') {
      return (
        <Icon
          {...getFileTypeIconProps({
            extension: item.FileName.split('.')[item.FileName.split('.').length - 1],
            size: 20,
            imageFileType: 'svg',
          })}
        />
      );
    } else if (column?.key === 'Created') {
      return moment(item.Metadata.created).format('DD MMM yyyy');
    } else {
      return item![column?.key as keyof IDocument];
    }
  };

  const onRenderUserItemColumn = (item: IUserDocument, index?: number, column?: IColumn): any => {
    if (column?.key === 'Icon') {
      return (
        <Icon
          {...getFileTypeIconProps({
            extension: item.FileName.split('.')[item.FileName.split('.').length - 1],
            size: 20,
            imageFileType: 'svg',
          })}
        />
      );
    } else if (column?.key === 'FileDate') {
      return moment(item.FileDate).format('DD MMM yyyy');
    } else {
      return item![column?.key as keyof IUserDocument];
    }
  };

  const _onSelect = () => {
    let documents: IPickerDocument[] = [];

    selectedDocuments.forEach(doc => {
      documents.push({
        id: doc.Id,
        name: doc.FileName,
        type: 'library',
      });
    });

    selectedUserDocuments.forEach(doc => {
      documents.push({
        id: doc.Id,
        name: doc.FileName,
        type: 'user',
      });
    });

    onSelect(documents);
  };

  // Runs when the selected documents change
  useEffect(() => {
    let barButtons: ICommandBarItemProps[] = [];
    barButtons = [
      ...barButtons,
      {
        key: 'upload',
        text: t('MAIN.UPLOAD'),
        iconProps: { iconName: !hideUploadDialog ? 'HourGlass' : 'Upload' },
        onClick: (event, item) => {
          inputRef.current?.click();
        },
      },
      {
        key: 'delete',
        text: t('MAIN.DELETE'),
        iconProps: { iconName: deleting ? 'HourGlass' : 'Delete' },
        onClick: (event, item) => {
          deleteDocuments();
        },
        disabled: selectedUserDocuments.length === 0,
      },
    ];
    setCommandBarButtons(barButtons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserDocuments, deleting, languageService?.language]);

  const deleteDocuments = async () => {
    if (!deleting) {
      setDeleting(true);
      const tasks = selectedUserDocuments.map(async doc => {
        await userService?.DeleteUserDocument(doc.Id);
      });
      await Promise.all(tasks);
      initUserDocuments();
      setDeleting(false);
    }
  };

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      let newFiles = new Array<File>();
      for (var i = 0; i < files.length; i++) {
        newFiles.push(files[i]);
      }
      if (
        newFiles.filter(file => {
          const extension = file.name.split('.')[file.name.split('.').length - 1];
          return VALID_UPLOAD_FILE_EXTENSIONS.indexOf(extension.toLowerCase()) === -1;
        }).length > 0
      ) {
        dialogService?.showWarningDialog(t('CHAT.DOCUMENT_UPLOAD'), t('CHAT.FILE_EXTENSION'));
      } else {
        const CancelToken = axios.CancelToken;
        const cancelTokenSource = CancelToken.source();
        setCancelTokenSource(cancelTokenSource);
        setHideUploadDialog(false);
        setUploadPercentage(0);
        try {
          await userService!.UploadUserDocument(
            files[0],
            cancelTokenSource.token,
            (progressEvent: AxiosProgressEvent) => {
              setUploadPercentage(progressEvent.loaded / progressEvent.total!);
            }
          );
          initUserDocuments();
        } catch (error) {
          const axiosError = error as AxiosError;
          if (axiosError.response?.status === 409 || axiosError.response?.status === 422) {
            dialogService?.showWarningDialog(
              t('CHAT.DOCUMENT_UPLOAD'),
              (axiosError.response.data as any).title
            );
          } else if (axiosError.response?.status === 400) {
            const errorMsg = (axiosError.response.data as any).errors[''][0];
            if (errorMsg.toLowerCase().indexOf('request body too large') !== -1) {
              dialogService?.showWarningDialog(t('CHAT.DOCUMENT_UPLOAD'), t('CHAT.FILE_SIZE'));
            } else {
              dialogService?.showWarningDialog(t('CHAT.DOCUMENT_UPLOAD'), errorMsg);
            }
          }
        }
      }
      event.target.value = '';
      setHideUploadDialog(true);
    }
  };

  const cancelUpload = async () => {
    cancelTokenSource!.cancel('Operation canceled');
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        type: DialogType.largeHeader,
        title: t('LIBRARIES.DOCUMENT_PICKER'),
      }}
      modalProps={{
        isBlocking: true,
        styles: {
          main: {
            width: '700px !important',
            maxWidth: '700px !important',
          },
        },
      }}
    >
      <Stack>
        <StackItem>
          <Pivot>
            {chatApp.EnableLibraryReferences && (
              <PivotItem
                itemKey='libraries'
                headerText={t('LIBRARIES.DOCUMENTS')}
                itemIcon='Folder'
              >
                <Stack style={{ marginTop: 10 }}>
                  <Stack.Item>
                    <SearchBox
                      value={filterKeyword}
                      onChange={(evt, newValue) => setFilterKeyword(newValue)}
                      placeholder={t('FILTERS.FILTER_BY_FILE_NAME')}
                      iconProps={{ iconName: 'Filter' }}
                      underlined={true}
                    />
                  </Stack.Item>
                  <StackItem style={{ height: 300, overflowY: 'auto' }}>
                    <DetailsList
                      setKey='documents'
                      items={filteredDocuments || []}
                      columns={libraryDocumentColumns}
                      selection={selection}
                      selectionMode={SelectionMode.multiple}
                      onRenderItemColumn={onRenderItemColumn}
                      ariaLabelForGrid='Documents'
                      listProps={{
                        renderedWindowsAhead: 0,
                        renderedWindowsBehind: 0,
                      }}
                      className='documents-list'
                    />
                    {(filteredDocuments === undefined || filteredDocuments?.length === 0) && (
                      <>
                        {filteredDocuments?.length === 0 && (
                          <Text
                            variant='large'
                            block
                            style={{
                              textAlign: 'center',
                              opacity: 0.5,
                            }}
                          >
                            {t('LIBRARIES.NO_DOCUMENTS')}
                          </Text>
                        )}
                        {filteredDocuments === undefined && <Spinner size={SpinnerSize.large} />}
                      </>
                    )}
                  </StackItem>
                </Stack>
              </PivotItem>
            )}
            {chatApp.EnableAttachments && (
              <PivotItem
                itemKey='uploads'
                headerText={t('LIBRARIES.UPLOADS')}
                itemIcon='CloudUpload'
              >
                <Stack style={{ marginTop: 10 }}>
                  <Stack.Item>
                    <CommandBar items={commandBarButtons} ariaLabel='Library actions' />
                    <SearchBox
                      value={filterUserKeyword}
                      onChange={(evt, newValue) => setFilterUserKeyword(newValue)}
                      placeholder={t('FILTERS.FILTER_BY_FILE_NAME')}
                      iconProps={{ iconName: 'Filter' }}
                      underlined={true}
                    />
                    <input
                      ref={inputRef}
                      type='file'
                      multiple={false}
                      accept={VALID_UPLOAD_FILE_EXTENSIONS.map(ext => `.${ext}`).join(',')}
                      hidden={true}
                      onChange={onFileChange}
                    />
                  </Stack.Item>
                  <StackItem style={{ height: 256, overflowY: 'auto' }}>
                    <DetailsList
                      setKey='userDocuments'
                      items={filteredUserDocuments || []}
                      columns={userDocumentColumns}
                      selection={userSelection}
                      selectionMode={SelectionMode.multiple}
                      onRenderItemColumn={onRenderUserItemColumn}
                      ariaLabelForGrid='Uploads'
                      listProps={{
                        renderedWindowsAhead: 0,
                        renderedWindowsBehind: 0,
                      }}
                      className='documents-list'
                    />
                    {(filteredUserDocuments === undefined ||
                      filteredUserDocuments?.length === 0) && (
                      <>
                        {filteredUserDocuments?.length === 0 && (
                          <Text
                            variant='large'
                            block
                            style={{
                              textAlign: 'center',
                              opacity: 0.5,
                            }}
                          >
                            {t('LIBRARIES.NO_DOCUMENTS')}
                          </Text>
                        )}
                        {filteredUserDocuments === undefined && (
                          <Spinner size={SpinnerSize.large} />
                        )}
                      </>
                    )}
                  </StackItem>
                </Stack>
                <Dialog
                  hidden={hideUploadDialog}
                  onDismiss={() => setHideUploadDialog(true)}
                  modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                  }}
                  dialogContentProps={{
                    showCloseButton: false,
                    type: DialogType.normal,
                    title: t('CHAT.DOCUMENT_UPLOAD'),
                  }}
                >
                  {(uploadPercentage < 1 && (
                    <ProgressIndicator
                      description={`${t('MAIN.UPLOADING')}...`}
                      percentComplete={uploadPercentage}
                    />
                  )) || <ProgressIndicator description={`${t('MAIN.PROCESSING')}...`} />}
                  {uploadPercentage < 1 && (
                    <DialogFooter>
                      <PrimaryButton
                        disabled={uploadPercentage === 1}
                        onClick={cancelUpload}
                        text={t('MAIN.CANCEL')}
                      />
                    </DialogFooter>
                  )}
                </Dialog>
              </PivotItem>
            )}
          </Pivot>
        </StackItem>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          disabled={selectedDocuments.length === 0 && selectedUserDocuments.length === 0}
          onClick={_onSelect}
          text={t('MAIN.SELECT')}
        />
        <DefaultButton onClick={onClose} text={t('MAIN.CLOSE')} />
      </DialogFooter>
    </Dialog>
  );
};

export default DocumentPicker;
