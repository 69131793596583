import { Panel, PanelType, Stack, Text, TextField } from "@fluentui/react";
import React, { useContext, useEffect, useRef } from "react";
import greenLogo from "../../Assets/Images/chat_jti_logo.png";
import Typing from "../Typing/Typing";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import "./ConversationDebug.scss";
import {
  IAttachment,
  IChatMessage,
  MessageFeedback,
} from "../../Models/API/IConversation";
import CodeComponent from "../Code/CodeComponent";
import remarkGfm from "remark-gfm";
import { Link } from "react-router-dom";
import { ChatServiceContext } from "../../Services/API/ChatService";
import ITraceMessage from "../../Models/API/ITraceMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBug,
  faCopy,
  faSpinner,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import ReactTimeAgo from "react-time-ago";
import classNames from "classnames";
import UserMessageAttachments from "../UserMessageAttachments/UserMessageAttachments";
import LatexRemarkPlugin from "../LatexRemarkPlugin/LatexRemarkPlugin";
import { ParseBeforeMarkdown } from "../Conversation/Conversation";
import AssistantMessageSources from "../AssistantMessageSources/AssistantMessageSources";
import TraceMessage from "../TraceMessage/TraceMessage";
import { useTranslation } from "react-i18next";

export interface IConversationDebugProps {
  messages: IChatMessage[];
  setMessages: (messages: IChatMessage[]) => void;
  typing: boolean;
}

const ConversationDebug: React.FC<IConversationDebugProps> = ({
  messages,
  setMessages,
  typing,
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatService = useContext(ChatServiceContext);
  const [traceMessage, setTraceMessage] = React.useState<ITraceMessage | null>(
    null
  );
  const [loadingTraceMessage, setLoadingTraceMessage] =
    React.useState<boolean>(false);
  const [traceMessageId, setTraceMessageId] = React.useState<string>();
  const [ignoreMessagesUpdate, setIgnoreMessagesUpdate] =
    React.useState<boolean>(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (!ignoreMessagesUpdate) {
      scrollToBottom();
    } else {
      setIgnoreMessagesUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const copyToClipboardAction = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  const loadTraceMessage = async (messageId: string) => {
    if (!loadingTraceMessage) {
      setLoadingTraceMessage(true);
      setTraceMessageId(messageId);
      const message = await chatService!.GetTraceMessage(messageId);
      if (message) setTraceMessage(message);
      setLoadingTraceMessage(false);
    }
  };

  const giveFeedback = async (
    message: IChatMessage,
    feedback: MessageFeedback
  ) => {
    if (message.Feedback !== feedback) {
      message.Feedback = feedback;
      setIgnoreMessagesUpdate(true);
      setMessages(
        messages.map((msg) =>
          msg.Id === message.Id ? { ...msg, feedback: feedback } : msg
        )
      );
      await chatService!.GiveMessageFeedback(message.Id, feedback);
    }
  };

  const timeAgo = (msg: IChatMessage) => {
    return <ReactTimeAgo date={new Date(msg.DateTime)} locale="en-US" />;
  };

  return (
    <>
      <Stack
        className="conversation-debug"
        grow
        tokens={{ childrenGap: 20 }}
        verticalFill
        style={{ minHeight: 0 }}
      >
        <Stack.Item grow shrink style={{ overflowY: "scroll" }}>
          <Stack
            grow
            tokens={{ childrenGap: 15 }}
            verticalAlign="end"
            style={{ minHeight: "100%", padding: "16px" }}
          >
            {messages.map((msg, index) => {
              if (msg.Role === "user") {
                return (
                  <React.Fragment key={index}>
                    <Stack.Item className="msg user-msg">
                      <Stack tokens={{ childrenGap: 5 }}>
                        <Stack.Item>
                          <div className="text">{msg.Content}</div>
                        </Stack.Item>
                        {msg.Attachments && msg.Attachments.length > 0 && (
                          <Stack.Item>
                            <UserMessageAttachments
                              attachments={msg.Attachments}
                            />
                          </Stack.Item>
                        )}
                        <Stack
                          horizontal
                          horizontalAlign="end"
                          verticalAlign="center"
                          tokens={{ childrenGap: 10 }}
                        >
                          {msg.Id !== "TemporaryUserId" && (
                            <Stack.Item>
                              <button
                                type="button"
                                title={t('DEBUG.DEBUG_ANSWER')}
                                onClick={() => loadTraceMessage(msg.Id)}
                              >
                                {(!loadingTraceMessage ||
                                  (loadingTraceMessage &&
                                    traceMessageId !== msg.Id)) && (
                                  <FontAwesomeIcon icon={faBug} />
                                )}
                                {loadingTraceMessage &&
                                  traceMessageId === msg.Id && (
                                    <FontAwesomeIcon
                                      className="spinner"
                                      icon={faSpinner}
                                    />
                                  )}
                              </button>
                            </Stack.Item>
                          )}
                          <Stack.Item align="end">
                            <button
                              type="button"
                              title={t('DEBUG.COPY_MESSAGE')}
                              onClick={() => copyToClipboardAction(msg.Content)}
                            >
                              <FontAwesomeIcon icon={faCopy} />
                            </button>
                          </Stack.Item>
                          <Stack.Item>
                            <div
                              className="message-time"
                              style={{ textAlign: "right" }}
                            >
                              {timeAgo(msg)}
                            </div>
                          </Stack.Item>
                        </Stack>
                      </Stack>
                    </Stack.Item>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={index}>
                    <Stack.Item className="msg bot-msg">
                      <div>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                          <Stack.Item grow>
                            <img
                              alt="ChatJTI Logo"
                              width={40}
                              title="ChatJTI"
                              src={greenLogo}
                            />
                          </Stack.Item>
                          <Stack
                            tokens={{ childrenGap: 10 }}
                            style={{
                              maxWidth: "calc(100% - 20px)",
                              boxSizing: "border-box",
                            }}
                          >
                            <Stack.Item className="text">
                              <Text>
                                <ReactMarkdown
                                  remarkPlugins={[remarkGfm, LatexRemarkPlugin]}
                                  linkTarget={"_blank"}
                                  components={{
                                    p(props) {
                                      return <p>{props.children}</p>;
                                    },
                                    a(props) {
                                      if (props.children[0] === "↩")
                                        return <></>;
                                      return (
                                        <Link
                                          className={props.className}
                                          target={
                                            props.href?.startsWith("http")
                                              ? "_blank"
                                              : "_self"
                                          }
                                          to={props.href!}
                                        >
                                          {props.children[0]}
                                        </Link>
                                      );
                                    },
                                    pre(props) {
                                      const { children } = props;
                                      return <pre>{children}</pre>;
                                    },
                                    code(props) {
                                      return (
                                        <CodeComponent
                                          {...props}
                                        ></CodeComponent>
                                      );
                                    },
                                  }}
                                  children={ParseBeforeMarkdown(msg.Content)}
                                ></ReactMarkdown>
                              </Text>
                              {msg.Sources && msg.Sources.length > 0 && (
                                <div style={{ marginTop: 10 }}>
                                  <AssistantMessageSources
                                    sources={msg.Sources}
                                  />
                                </div>
                              )}
                            </Stack.Item>
                            {typing && msg.Id === "TemporaryAssistantId" && (
                              <Stack.Item>
                                <Typing />
                              </Stack.Item>
                            )}
                            {msg.Id !== "TemporaryAssistantId" && (
                              <Stack
                                horizontal
                                horizontalAlign="start"
                                verticalAlign="center"
                                tokens={{ childrenGap: 10 }}
                              >
                                <Stack.Item>
                                  <div className="message-time">
                                    {timeAgo(msg)}
                                  </div>
                                </Stack.Item>
                                <Stack.Item shrink>
                                  <button
                                    type="button"
                                    title="Thumbs Down"
                                    className={classNames(
                                      msg.Feedback === "Negative" && "active"
                                    )}
                                    onClick={() =>
                                      giveFeedback(msg, "Negative")
                                    }
                                  >
                                    <FontAwesomeIcon icon={faThumbsDown} />
                                  </button>
                                </Stack.Item>
                                <Stack.Item shrink>
                                  <button
                                    type="button"
                                    title="Thumbs Up"
                                    className={classNames(
                                      msg.Feedback === "Positive" && "active"
                                    )}
                                    onClick={() =>
                                      giveFeedback(msg, "Positive")
                                    }
                                  >
                                    <FontAwesomeIcon icon={faThumbsUp} />
                                  </button>
                                </Stack.Item>
                                <Stack.Item shrink>
                                  <button
                                    type="button"
                                    title={t('DEBUG.COPY_ANSWER')}
                                    onClick={() =>
                                      copyToClipboardAction(msg.Content)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faCopy} />
                                  </button>
                                </Stack.Item>
                              </Stack>
                            )}
                          </Stack>
                        </Stack>
                      </div>
                    </Stack.Item>
                  </React.Fragment>
                );
              }
            })}
            <div className="chatScrollElement" ref={messagesEndRef}></div>
          </Stack>
        </Stack.Item>
      </Stack>
      {traceMessage !== null && (
        <Panel
          isOpen={traceMessage !== null}
          onDismiss={() => setTraceMessage(null)}
          type={PanelType.extraLarge}
          closeButtonAriaLabel="Close"
          isLightDismiss={true}
          headerText={t('DEBUG.DEBUG_USER_MESSAGE')}
        >
          <TraceMessage traceMessage={traceMessage} />
        </Panel>
      )}
    </>
  );
};

export default ConversationDebug;
