import { Stack, PrimaryButton, Text, StackItem, Link, Nav } from "@fluentui/react";
import styles from "./Default.module.scss";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppCatalogServiceContext } from "../../../../Services/AppCatalogService";
import { aiToolAppServiceContext } from "../../../../Services/API/AiToolAppService";
import IAIToolApp from "../../../../Models/API/IAIToolApp";
import { aiIntegratedAppServiceContext } from "../../../../Services/API/AiIntegratedAppService";
import {
  IDocumentationResponse,
  IMarkdownFile,
} from "../../../../Models/API/IDocumentationResponse";
import Method from "../../../ApiReference/Method/Method";

const Default: FC = () => {
  const appCatalogService = useContext(AppCatalogServiceContext);
  const [app, setApp] = useState<IAIToolApp>();
  const [docs, setDocs] = useState<IDocumentationResponse | null>(null);
  const [_, setOriginalTheme] = useState<string>();
  const [selectedDoc, setSelectedDoc] = useState<IMarkdownFile | null>(null);
  const [openedGroup, setOpenedGroup] = useState<string | null>(null);

  var aiToolAppService = useContext(aiToolAppServiceContext);
  var aiIntegratedAppService = useContext(aiIntegratedAppServiceContext);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    setOriginalTheme(appCatalogService?.darkMode ? "darkMode" : "lightMode");

    const fetchData = async () => {
      let aiTool = await aiToolAppService?.Get(parseInt(params.appId!));
      if (aiTool) {
        setApp(aiTool);
        document.title = `${aiTool?.DisplayName} Documentation`;
        let docs = await aiIntegratedAppService?.GetDocumentation(
          aiTool?.AppUrl!,
          "api/documentation"
        );
        docs?.files.forEach((element) => {
          element.content = atob(element.content);
        });

        if (docs) setDocs(docs);
      }
    };

    if (params.appId) {
      fetchData();
    }
  }, []);

  const formatMarkdownFileName = (name: string) => {
    return name.replace(".md", "").replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const getGroupName = (filePath: string) => {
    const parts = filePath.split("\\").filter(Boolean);
    if (parts.length > 1) {
      return parts[0].replace(/\b\w/g, (char) => char.toUpperCase());
    }
    return "General";
  };

  const getItemName = (filePath: string, fileName: string) => {
    const parts = filePath.split("\\").filter(Boolean);
    return parts.length > 2
      ? formatMarkdownFileName(parts[parts.length - 2])
      : formatMarkdownFileName(fileName);
  };

  const groupedDocs: { [key: string]: IMarkdownFile[] } = {};
  docs?.files.forEach((file) => {
    const groupName = getGroupName(file.path);
    if (!groupedDocs[groupName]) {
      groupedDocs[groupName] = [];
    }
    groupedDocs[groupName].push(file);
  });

  return (
    <Stack horizontal style={{ height: "auto", minHeight: "60vh" }}>
      <StackItem styles={{ root: { width: 250 } }}>
        {!docs ? (
          <div style={{ marginTop: "20px" }}>Loading documentation...</div>
        ) : (
          <Nav
            styles={{
              root: { padding: "0px" },
              groupContent: { marginBottom: "0px" },
              link: { padding: "6px 10px" },
            }}
            selectedKey="home"
            groups={Object.keys(groupedDocs).map((groupName) => ({
              name: groupName,
              isExpanded: openedGroup === groupName,
              onHeaderClick: () => setOpenedGroup(openedGroup === groupName ? null : groupName),
              links: groupedDocs[groupName].map((file) => ({
                key: getItemName(file.path, file.name),
                name: getItemName(file.path, file.name),
                url: ``,
                onClick: () => {
                  setSelectedDoc(file);
                },
              })),
            }))}
          />
        )}
      </StackItem>

      <StackItem
        grow
        styles={{ root: { padding: 20, width: "70vw", minHeight: "60vh", overflowY: "auto" } }}
      >
        {selectedDoc ? (
          <Method key={selectedDoc.path} content={selectedDoc.content} host={app?.AppUrl} />
        ) : (
          <Stack tokens={{ childrenGap: 20 }}>
            <StackItem>
              <Text variant="superLarge">API Reference</Text>
            </StackItem>
            <StackItem>
              <Text variant="xxLarge">ChatJTI API</Text>
            </StackItem>
            <StackItem>
              <Link href={`mailto:bogdan.ciungu@jti.com`}>JTI IT Innovation Team</Link>
            </StackItem>
            <PrimaryButton className={styles.button} onClick={() => navigate(-1)}>
              Back
            </PrimaryButton>
          </Stack>
        )}
      </StackItem>
    </Stack>
  );
};

export default Default;
