import moment from 'moment';

import 'moment/locale/fr'; // French
import 'moment/locale/pl'; // Polish
import 'moment/locale/es'; // Spanish
import 'moment/locale/cs'; // Czech
import 'moment/locale/it'; // Italian
import 'moment/locale/ro'; // Romanian
import 'moment/locale/sk'; // Slovakian
import 'moment/locale/nl'; // Dutch
import 'moment/locale/de'; // German
import 'moment/locale/pt'; // Portuguese
import 'moment/locale/ru'; // Russian
import 'moment/locale/ja'; // Japanese

// Function to set the locale globally
export const setMomentLocale = (locale: string) => {
  moment.locale(locale);
};

// Export moment so that it can be used in other components
export default moment;
