import { FC, useContext, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import IAutomateApp, {
  IAutomateAppOutputParameter,
} from "../../../../../../Models/API/IAutomateApp";

import { automateAppServiceContext } from "../../../../../../Services/API/AutomateAppService";
import AutomateAppParameters from "../../../../../../Components/AutomateAppParameters/AutomateAppParameters";
import { IColumn } from "@fluentui/react";
import { trackPageView } from "../../../../../../Services/AppInsights";
import ConfigurationService from "../../../../../../Services/ConfigurationService";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

const columns= ( t: TFunction<"translation", undefined, "translation"> ) => [
  {
    key: "DisplayName",
    name: t("MAIN.DISPLAY_NAME"),
    minWidth: 300,
    isResizable: true,
  },
  {
    key: "Type",
    name: t("MAIN.TYPE"),
    minWidth: 400,
    isResizable: true,
  },
] satisfies IColumn[];

export interface IAutomateOutputParamsProps {}

const AutomateOutputParams: FC<IAutomateOutputParamsProps> = () => {
  const { automateApp, setAutomateApp } = useOutletContext<{
    automateApp: IAutomateApp;
    setAutomateApp: (value: IAutomateApp) => void;
  }>();
  const automateAppsService = useContext(automateAppServiceContext);
  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${automateApp.DisplayName} Output Parameters`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAutomateAppParameters = async (
    parameters: IAutomateAppOutputParameter[]
  ) => {
    const refreshedAutomateApp = await automateAppsService?.Update(
      automateApp.Id,
      {
        ...automateApp,
        OutputParameters: parameters,
      }
    );
    if (refreshedAutomateApp) {
      setAutomateApp(refreshedAutomateApp);
    }
  };

  return (
    <>
      <AutomateAppParameters
        parameters={
          automateApp.OutputParameters ? automateApp.OutputParameters : []
        }
        setParameters={updateAutomateAppParameters}
        columns={columns(t)}
        showRequired={false}
      />
    </>
  );
};

export default AutomateOutputParams;
