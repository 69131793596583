import { Theme, ThemeProvider } from "@fluentui/react";
import { FC, createContext, useEffect, useState } from "react";
import lightLogo from "../Assets/Images/jti_logo_light.png";
import darkLogo from "../Assets/Images/jti_logo_dark.png";
import generateTheme from "../Utilities/ThemeGenerator";
import classNames from "classnames";

export const DarkTheme = generateTheme({
  backgroundColor: "#122632",
  primaryColor: "#4ab8a5",
  textColor: "#ffffff",
});

export const LightTheme = generateTheme({
  backgroundColor: "#FDFDFC",
  primaryColor: "#4ab8a5",
  textColor: "#0D0D0D",
});

export interface NavigationTitle {
  title: string;
  subtitle: string;
}

export interface ThemeProps {
  title: string;
  subtitle: string;
  logoBase64: string | null | undefined;
  backgroundColor: string;
  primaryColor: string;
  secondaryColor: string;
}

export interface IAppCatalogService {
  readonly themeLogo: string;
  title: string;
  subtitle: string;
  isDefaultTheme: boolean;
  isThemeLoading: boolean;
  logo: string;
  darkMode: boolean;
  ChangeNavTitle(titles: NavigationTitle): void;
  ChangeTheme(props?: ThemeProps): Promise<void>;
  SetDefaultTheme(): Promise<void>;
  GetCurrentTheme(): Theme;
  SwitchTheme(darkMode: boolean): Promise<void>;
}

export const AppCatalogServiceContext = createContext<
  IAppCatalogService | undefined
>(undefined);

const AppCatalogService: FC = ({ children }: any) => {
  const [logo, setLogo] = useState<string>("");
  const [titles, setTitles] = useState<{ title: string; subtitle: string }>({
    title: "",
    subtitle: "",
  });
  const [isDefaultTheme, setIsDefaultTheme] = useState<boolean>(true);
  const [darkMode, setDarkMode] = useState<boolean>(true);
  const [theme, setTheme] = useState<Theme>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const isLightEnabled = localStorage.getItem("lightMode") === "true";
    setDarkMode(!isLightEnabled);
    if (!isLightEnabled) {
      setThemeTemp(DarkTheme);
    } else {
      setThemeTemp(LightTheme);
    }
  }, [darkMode]);

  const changeTheme = async (themeProps: ThemeProps) => {
    setLoading(true);
    const theme = generateTheme({
      backgroundColor: themeProps.backgroundColor,
      primaryColor: themeProps.primaryColor,
      textColor: themeProps.secondaryColor,
    });
    setIsDefaultTheme(false);
    const titles = {
      title: themeProps.title,
      subtitle: themeProps.subtitle,
    };
    setThemeTemp(theme);
    setTitles(titles);
    if (themeProps.logoBase64) {
      setLogo(`data:image/png;base64, ${themeProps.logoBase64}`);
    } else {
      setDefaultLogo();
    }

    setLoading(false);
  };

  const setDefaultLogo = async () => {
    const data = await fetch(darkMode ? darkLogo : lightLogo);
    const blob = await data.blob();
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function () {
      const b64 = reader.result;
      setLogo(`${b64}`);
    };
  };

  const setDefaultTheme = async () => {
    setLoading(true);
    setThemeTemp(currentTheme());
    await setDefaultLogo();
    setDefaultTitle();
    setIsDefaultTheme(true);
    setLoading(false);
  };

  const setDefaultTitle = async () => {
    setTitles({
      title: "GPT Playground",
      subtitle: "Chat JTI",
    });
  };

  const currentTheme = (): Theme => {
    if (darkMode) return DarkTheme;
    else return LightTheme;
  };

  const setThemeTemp = (theme: Theme | null) => {
    if (theme) {
      theme.fonts = {
        tiny: {
          fontSize: 12,
        },
        xSmall: {
          fontSize: 13,
        },
        small: {
          fontSize: 14,
        },
        smallPlus: {
          fontSize: 15,
        },
        medium: {
          fontSize: 16,
        },
        mediumPlus: {
          fontSize: 17,
        },
        large: {
          fontSize: 19,
        },
        xLarge: {
          fontSize: 21,
        },
        xLargePlus: {
          fontSize: 23,
        },
        xxLarge: {
          fontSize: 25,
        },
        xxLargePlus: {
          fontSize: 27,
        },
        superLarge: {
          fontSize: 30,
        },
        mega: {
          fontSize: 40,
        },
      };
      setTheme(theme);
    }
  };

  useEffect(() => {
    document.body.setAttribute("data-theme", darkMode ? "dark" : "light");
  }, [darkMode]);

  useEffect(() => {
    if (theme) {
      var keys = Object.keys(theme.palette);
      for (var i = 0; i < keys.length; i++) {
        let kebab = keys[i].replace(/([A-Z])/g, "-$1").toLowerCase();
        document.documentElement.style.setProperty(
          `--${kebab}`,
          (theme.palette as any)[keys[i]]
        );
      }
    }
  }, [theme]);

  const appCatalogService: IAppCatalogService = {
    GetCurrentTheme(): Theme {
      return theme!;
    },
    get themeLogo(): string {
      return logo;
    },
    get title(): string {
      return titles.title;
    },
    get subtitle(): string {
      return titles.subtitle;
    },
    get isDefaultTheme(): boolean {
      return isDefaultTheme;
    },
    get isThemeLoading(): boolean {
      return loading;
    },
    ChangeNavTitle(titles: NavigationTitle): void {
      setTitles(titles);
    },
    async ChangeTheme(props: ThemeProps): Promise<void> {
      if (props) {
        await changeTheme(props);
      } else {
        await setDefaultTheme();
      }
    },
    async SetDefaultTheme(): Promise<void> {
      await setDefaultTheme();
    },
    get logo(): string {
      return darkMode ? darkLogo : lightLogo;
    },
    get darkMode(): boolean {
      return darkMode;
    },
    async SwitchTheme(darkMode: boolean): Promise<void> {
      localStorage.setItem("lightMode", (!darkMode).toString());
      setDarkMode(darkMode);
    },
  };

  return (
    <AppCatalogServiceContext.Provider value={appCatalogService}>
      <ThemeProvider
        theme={theme}
        className={classNames(darkMode && "dark-mode")}
        style={{ height: "100%", minHeight: 0 }}
      >
        {children}
      </ThemeProvider>
    </AppCatalogServiceContext.Provider>
  );
};

export default AppCatalogService;
