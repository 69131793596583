import { FC, useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import IChatApp from '../../../../../../Models/API/IChatApp';
import './Webpages.scss';
import {
  chatAppServiceContext,
  INewChatAppWebpage,
} from '../../../../../../Services/API/ChatAppService';
import {
  Stack,
  CommandBar,
  SearchBox,
  DetailsList,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  ICommandBarItemProps,
  IColumn,
  Text,
  Selection,
  Icon,
  StackItem,
  TextField,
} from '@fluentui/react';
import { removeDiacritics } from '../../../../../../Utilities/Strings';
import { trackPageView } from '../../../../../../Services/AppInsights';
import ConfigurationService from '../../../../../../Services/ConfigurationService';
import IChatAppWebpage from '../../../../../../Models/API/IChatAppWebpage';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { LanguageServiceContext } from '../../../../../../Services/LanguageService';

const newWebPageForm: INewChatAppWebpage = {
  Urls: '',
};

export interface IChatAppsWebpagesProps {}

const ChatAppsWebpages: FC<IChatAppsWebpagesProps> = () => {
  const chatAppService = useContext(chatAppServiceContext);
  const languageService = useContext(LanguageServiceContext);

  const { t } = useTranslation();

  const { chatApp } = useOutletContext<{ chatApp: IChatApp }>();

  const [chatAppWebpages, setChatAppWebpages] = useState<IChatAppWebpage[] | null>(null);
  const [filteredWebpages, setFilteredWebpages] = useState<IChatAppWebpage[] | null | undefined>(
    undefined
  );

  const [filterKeyword, setFilterKeyword] = useState<string | undefined>(undefined);
  const [selectedWebpages, setSelectedWebpages] = useState<IChatAppWebpage[]>([]);
  const [commandBarButtons, setCommandBarButtons] = useState<ICommandBarItemProps[]>([]);

  const [hideRemoveWebpagesDialog, setHideRemoveWebpagesDialog] = useState<boolean>(true);

  const [hideNewDialog, setHideNewDialog] = useState<boolean>(true);

  const [newWebpage, setNewWebpage] = useState<INewChatAppWebpage>(newWebPageForm);

  const [creatingWebpage, setCreatingWebpage] = useState<boolean>(false);

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadWebPages = async () => {
    setChatAppWebpages([]);
    setFilteredWebpages(undefined);
    let chatAppWebpages = await chatAppService!.GetChatWebpages(chatApp.Id);
    if (chatAppWebpages) {
      chatAppWebpages = chatAppWebpages.sort((permA, permB) => permA.Url.localeCompare(permB.Url));
      setFilteredWebpages(chatAppWebpages);
      setChatAppWebpages(chatAppWebpages);
    }
  };

  const onRenderItemColumn = (item: IChatAppWebpage, index?: number, column?: IColumn): any => {
    if (column?.key === 'Icon') {
      return <Icon style={{ fontSize: '1rem' }} iconName='World' />;
    } else if (column?.key === 'Url') {
      return item.Url;
    } else {
      return item![column?.key as keyof IChatAppWebpage];
    }
  };

  const selection = new Selection({
    canSelectItem(item, index) {
      return true;
    },
    onSelectionChanged: () => {
      setSelectedWebpages(selection.getSelection() as IChatAppWebpage[]);
    },
  });

  const resetState = (): void => {
    setHideRemoveWebpagesDialog(true);
    setChatAppWebpages([]);
    setFilteredWebpages(undefined);
    setSelectedWebpages([]);
    setFilterKeyword(undefined);
    setNewWebpage(newWebPageForm);
  };

  const removeWebpages = async (): Promise<void> => {
    const promises: (Promise<void | null> | undefined)[] = [];
    const selectedWebs = [...selectedWebpages];
    resetState();
    for (let i = 0; i < selectedWebs.length; i++) {
      const perm = selectedWebs[i];
      promises.push(chatAppService!.DeleteChatWebpage(chatApp.Id, perm.Id));
    }
    await Promise.allSettled(promises);
    reloadWebPages();
  };

  const createWebpage = async (): Promise<void> => {
    if (!creatingWebpage) {
      setCreatingWebpage(true);
      await chatAppService?.AddChatWebpage(chatApp.Id, newWebpage);
      setCreatingWebpage(false);
      setHideNewDialog(true);
      reloadWebPages();
      resetState();
    }
  };

  const shimmerColumns = (t: TFunction<'translation', undefined, 'translation'>) =>
    [
      {
        key: 'Icon',
        name: '',
        minWidth: 20,
        maxWidth: 20,
        isResizable: true,
      },
      {
        key: 'Url',
        name: t('MAIN.URL'),
        minWidth: 100,
        isResizable: true,
      },
      {
        key: 'Created',
        name: t('MAIN.DATE_ADDED'),
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
        onRender: (item: IChatAppWebpage) =>
          item.Created ? moment(item.Created + 'Z').fromNow() : t('MAIN.NA'),
      },
      {
        key: 'actions',
        name: '',
        minWidth: 60,
        maxWidth: 60,
        isResizable: false,
        onRender: (item: IChatAppWebpage) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <Icon
                iconName='OpenInNewWindow'
                styles={{ root: { fontSize: 20, cursor: 'pointer' } }}
                onClick={() => {
                  if (item.Url) {
                    window.open(item.Url, '_blank');
                  } else {
                    alert(t('CATALOG.NO_FINAL_URL_AVAILABLE'));
                  }
                }}
                title={t('CATALOG.VISIT_FINAL_URL')}
              />
            </div>
          );
        },
      },
    ] satisfies IColumn[];

  useEffect(() => {
    setCommandBarButtons([
      {
        key: 'add',
        text: t('MAIN.ADD'),
        iconProps: { iconName: 'Add' },
        onClick: (event, item) => {
          setHideNewDialog(false);
        },
      },
      {
        key: 'remove',
        text: t('MAIN.REMOVE'),
        iconProps: { iconName: 'Delete' },
        onClick: (event, item) => {
          setHideRemoveWebpagesDialog(false);
        },
        disabled: selectedWebpages.length === 0,
      },
      {
        key: 'refresh',
        text: t('MAIN.REFRESH'),
        iconProps: { iconName: 'Refresh' },
        onClick: (event, item) => {
          reloadWebPages();
        },
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWebpages, chatApp, languageService?.language]);

  // Runs when the filter keyword changes
  useEffect(() => {
    if (chatAppWebpages && filterKeyword !== undefined) {
      setFilteredWebpages(
        filterKeyword
          ? chatAppWebpages?.filter(perm => {
              return (
                removeDiacritics(perm.Url.toLowerCase()).indexOf(
                  removeDiacritics(filterKeyword.toLowerCase())
                ) !== -1
              );
            })
          : chatAppWebpages
      );
    }
  }, [filterKeyword, chatAppWebpages]);

  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${chatApp.DisplayName} Web Pages`;
    const execute = async () => {
      reloadWebPages();
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0 }}>
        <Stack.Item>
          <CommandBar items={commandBarButtons} ariaLabel='Agents actions' />
          <SearchBox
            value={filterKeyword}
            onChange={(evt, newValue) => {
              setFilterKeyword(newValue);
            }}
            placeholder={t('FILTERS.FILTER_BY_WEBPAGE_TITLE_OR_URL')}
            iconProps={{ iconName: 'Filter' }}
            underlined={true}
          />
        </Stack.Item>
        <Stack.Item verticalFill style={{ overflowY: 'auto', minHeight: 0, position: 'relative' }}>
          <DetailsList
            setKey='items'
            items={filteredWebpages || []}
            columns={shimmerColumns(t)}
            selection={selection}
            selectionMode={SelectionMode.multiple}
            onRenderItemColumn={onRenderItemColumn}
            ariaLabelForGrid='Item details'
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            className='documents-list'
          />
          {(filteredWebpages === undefined || filteredWebpages?.length === 0) && (
            <>
              {filteredWebpages?.length === 0 && (
                <Text
                  variant='large'
                  block
                  style={{
                    textAlign: 'center',
                    opacity: 0.5,
                  }}
                >
                  {t('TABLE.NO_WEBPAGES')}
                </Text>
              )}
              {filteredWebpages === undefined && <Spinner size={SpinnerSize.large} />}
            </>
          )}
        </Stack.Item>
      </Stack>

      <Dialog
        hidden={hideRemoveWebpagesDialog}
        onDismiss={() => setHideRemoveWebpagesDialog(true)}
        modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } } }}
        dialogContentProps={{
          type: DialogType.normal,
          title: t('CATALOG.REMOVE_WEBPAGES'),
          subText: t('CATALOG.REMOVE_WEBPAGES_CONFIRMATION'),
        }}
      >
        <Stack tokens={{ childrenGap: 5 }}>
          {selectedWebpages.map(perm => {
            return (
              <Text key={perm.Url} block>
                {perm.Url}
              </Text>
            );
          })}
        </Stack>
        <DialogFooter>
          <PrimaryButton onClick={removeWebpages} text={t('MAIN.REMOVE')} />
          <DefaultButton
            onClick={() => setHideRemoveWebpagesDialog(true)}
            text={t('MAIN.CANCEL')}
          />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={hideNewDialog}
        minWidth={500}
        onDismiss={() => setHideNewDialog(true)}
        dialogContentProps={{
          title: t('CATALOG.ADD_WEBPAGES'),
          subText: t('CATALOG.ADD_WEBPAGES_CONFIRMATION'),
        }}
        modalProps={{
          isBlocking: true,
        }}
      >
        <Stack tokens={{ childrenGap: 20 }}>
          <StackItem>
            <Stack.Item>
              <TextField
                label={t('MAIN.URLS')}
                rows={5}
                multiline
                resizable={false}
                value={newWebpage.Urls}
                onChange={(event, newValue) =>
                  setNewWebpage({
                    ...newWebpage,
                    Urls: newValue,
                  })
                }
              />
            </Stack.Item>
          </StackItem>
        </Stack>
        <DialogFooter>
          <PrimaryButton
            style={{ minWidth: 80 }}
            onClick={createWebpage}
            disabled={newWebpage.Urls!.length < 3}
            text={creatingWebpage ? '' : t('MAIN.CREATE')}
          >
            {creatingWebpage && <Spinner size={SpinnerSize.small} />}
          </PrimaryButton>
          <DefaultButton onClick={() => setHideNewDialog(true)} text={t('MAIN.CANCEL')} />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ChatAppsWebpages;
