import './Navigation.scss';
import { Link, NavLink } from 'react-router-dom';
import { Link as FluentLink, IIconProps, PrimaryButton, Stack, Text } from '@fluentui/react';
import { FC, useContext } from 'react';
import { AppCatalogServiceContext } from '../../../Services/AppCatalogService';
import IAppCatalog from '../../../Models/API/IAppCatalog';
import { useTranslation } from 'react-i18next';

const globalNavButton: IIconProps = { iconName: 'GlobalNavButton' };

interface INavigationProps {
  catalog: IAppCatalog;
  openPanel(): void;
}

const Navigation: FC<INavigationProps> = ({ catalog, openPanel }) => {
  const appCatalogService = useContext(AppCatalogServiceContext);
  const themeLogo = appCatalogService!.themeLogo;
  const title = appCatalogService!.title;
  const subtitle = appCatalogService!.subtitle;
  const { t } = useTranslation();

  return (
    <Stack
      horizontal
      className='header'
      tokens={{ padding: 16, childrenGap: 30 }}
      grow
      verticalAlign='center'
    >
      <Stack.Item shrink>
        <Link to={`/catalogs`}>
          <img src={themeLogo} alt='' />
        </Link>
      </Stack.Item>
      <Stack horizontal className='nav' grow tokens={{ childrenGap: 0 }}>
        <Stack.Item>
          <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to='/catalogs'>
            <FluentLink>{t('MAIN.BACK_TO')} ChatJTI</FluentLink>
          </NavLink>
        </Stack.Item>
      </Stack>
      <Stack style={{ textAlign: 'right' }} className='hideInMobile'>
        <Stack.Item>
          <Text variant='mediumPlus'>{subtitle}</Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant='mediumPlus'>
            <strong>{title}</strong>
          </Text>
        </Stack.Item>
      </Stack>
      <Stack style={{ textAlign: 'right' }} className='hideInDesktop showInMobile'>
        <Stack.Item>
          <PrimaryButton
            iconProps={globalNavButton}
            // style={{ width: 20 }}
            styles={{
              root: {
                maxWidth: '1rem',
                minWidth: '1rem',
              },
            }}
            onClick={() => openPanel()}
          />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export { Navigation };
