import { Pivot, PivotItem, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { FC, useState } from 'react';
import { useLocation, Navigate, Outlet, useOutletContext } from 'react-router-dom';
import IAgentApp from '../../../../../Models/API/IAgentApp';
import { useTranslation } from 'react-i18next';

export interface IAgentAppProps {}

const AgentApps: FC<IAgentAppProps> = () => {
  const location = useLocation();
  const { baseApp: agentApp, setBaseApp: setAgentApp } = useOutletContext<{
    baseApp: IAgentApp;
    setBaseApp: (value: IAgentApp) => void;
  }>();

  const { t } = useTranslation();

  const [currentKey, setCurrentKey] = useState<string>(location.pathname.split('/')[6]);

  return (
    <>
      <Stack style={{ minHeight: 0 }} tokens={{ childrenGap: 20 }} grow verticalFill>
        {agentApp ? (
          <>
            <Stack.Item>
              <Pivot
                aria-label='Agent App Options'
                selectedKey={currentKey}
                onLinkClick={item => setCurrentKey(item?.props.itemKey!)}
              >
                {(agentApp.PermissionType === 'Write' ||
                  agentApp.PermissionType === 'FullControl' ||
                  agentApp.PermissionType === 'Owner') && (
                  <PivotItem
                    itemKey='functions'
                    headerText={t('SETTINGS.FUNCTIONS')}
                    itemIcon='LightningBolt'
                  >
                    <Navigate to='functions' replace />
                  </PivotItem>
                )}
                {(agentApp.PermissionType === 'FullControl' ||
                  agentApp.PermissionType === 'Owner') && (
                  <PivotItem
                    itemKey='permissions'
                    headerText={t('PERMISSIONS.PERMISSIONS')}
                    itemIcon='Permissions'
                  >
                    <Navigate to='permissions' replace />
                  </PivotItem>
                )}
                {(agentApp.PermissionType === 'Write' ||
                  agentApp.PermissionType === 'FullControl' ||
                  agentApp.PermissionType === 'Owner') && (
                  <PivotItem itemKey='settings' headerText={t('MAIN.SETTINGS')} itemIcon='Settings'>
                    <Navigate to='settings' replace />
                  </PivotItem>
                )}
              </Pivot>
            </Stack.Item>
            <Stack.Item grow verticalFill style={{ minHeight: 0 }}>
              <Outlet context={{ agentApp, setAgentApp }}></Outlet>
            </Stack.Item>
          </>
        ) : (
          <Spinner size={SpinnerSize.large} />
        )}
      </Stack>
    </>
  );
};

export default AgentApps;
