import {
  TextField,
  Checkbox,
  IIconProps,
  IDropdownOption,
  Dropdown,
  Label,
  ActionButton,
} from '@fluentui/react';
import { FC } from 'react';
import { Property } from './FunctionsForm';
import styles from './FunctionFormProperty.module.scss';
import { useId } from '@fluentui/react-hooks';
import { useTranslation } from 'react-i18next';

export const FieldTypes: IDropdownOption[] = [
  { key: 'string', text: 'String' },
  { key: 'integer', text: 'Integer' },
  { key: 'double', text: 'Double' },
  { key: 'boolean', text: 'Boolean' },
];

const deleteIcon: IIconProps = { iconName: 'Delete' };

export interface IFunctionFormPropertyProps {
  property: Property;
  deleteProperty(property: Property): void;
  setProperty(property: Property): void;
}

const FunctionFormProperty: FC<IFunctionFormPropertyProps> = ({
  property,
  deleteProperty,
  setProperty,
}) => {
  const name = useId('name');
  const type = useId('type');
  const required = useId('required');
  const description = useId('description');

  const { t } = useTranslation();

  return (
    <>
      <section className={styles.propContainer}>
        <div>
          <Label htmlFor={name}>Name</Label>
          <TextField
            id={name}
            value={property!.name}
            onChange={(evt, newValue) => {
              setProperty({
                ...property,
                name: newValue!,
              });
            }}
          ></TextField>
        </div>
        <div>
          <Label htmlFor={type}>Type</Label>
          <Dropdown
            id={type}
            selectedKey={property.type}
            onChange={(evt, option, index) => {
              setProperty({
                ...property,
                type: option!.key as any,
              });
            }}
            placeholder={t('MAIN.SELECT_TYPE')}
            options={FieldTypes}
          />
        </div>

        <div className={styles.description}>
          <Label htmlFor={description} className={styles.descriptionLabel}>
            Description:
          </Label>
          <TextField
            id={description}
            multiline
            rows={2}
            value={property!.description}
            onChange={(evt, newValue) => {
              setProperty({
                ...property,
                description: newValue!,
              });
            }}
          ></TextField>
        </div>

        <div className={styles.bottom}>
          <div>
            <Checkbox
              label='Required'
              id={required}
              checked={property!.required}
              onChange={(evnt, checked) => {
                setProperty({
                  ...property,
                  required: checked!,
                });
              }}
            />
          </div>
          <ActionButton
            iconProps={deleteIcon}
            allowDisabledFocus
            title='Delete property'
            ariaLabel='Delete property'
            onClick={() => deleteProperty(property)}
          >
            Delete Property
          </ActionButton>
        </div>
      </section>
      <div className={styles.separator}></div>
    </>
  );
};

export default FunctionFormProperty;
