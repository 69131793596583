import { FC, useContext, useEffect, useState } from 'react';
import { catalogServiceContext } from '../../Services/API/CatalogService';
import IAppCatalog from '../../Models/API/IAppCatalog';
import ConfigurationService from '../../Services/ConfigurationService';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  DetailsList,
  IColumn,
  Link,
  SearchBox,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
} from '@fluentui/react';
import moment from 'moment';
import CatalogLogo from '../../Components/CatalogLogo/CatalogLogo';
import { removeDiacritics } from '../../Utilities/Strings';
import { trackPageView } from '../../Services/AppInsights';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const CatalogsView: FC = () => {
  const catalogsService = useContext(catalogServiceContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [catalogs, setCatalogs] = useState<IAppCatalog[] | null | undefined>(undefined);
  const [filteredCatalogs, setFilteredCatalogs] = useState<IAppCatalog[] | null | undefined>(
    undefined
  );
  const [filterKeyword, setFilterKeyword] = useState<string | undefined>(undefined);
  const [isMounted, setIsMounted] = useState<boolean>(true);

  const shimmerColumns = ( t: TFunction<"translation", undefined, "translation"> ) => [
    {
      key: 'Icon',
      name: '',
      minWidth: 20,
      maxWidth: 40,
    },
    {
      key: 'Title',
      name: t('MAIN.TITLE'),
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: 'Description',
      name: t('MAIN.DESCRIPTION'),
      minWidth: 200,
    },
    {
      key: 'NumDisplayApps',
      name: t('MAIN.NUMBER_OF_APPS'),
      minWidth: 200,
    },
    {
      key: 'OwnerName',
      name: t('MAIN.OWNER'),
      minWidth: 100,
    },
    {
      key: 'Created',
      name: t('MAIN.CREATED'),
      minWidth: 100,
    },
  ] satisfies IColumn[];

  const reloadCatalogs = async () => {
    setCatalogs([]);
    setFilteredCatalogs(undefined);
    let catalogs = await catalogsService!.GetAllVisible();
    if (catalogs) {
      catalogs = catalogs
        .filter(c => c.NumDisplayApps > 0)
        .sort((catA, catB) => catA.DisplayName.localeCompare(catB.DisplayName));
      setFilteredCatalogs(catalogs);
      setCatalogs(catalogs);
    }
  };

  useEffect(() => {
    trackPageView();
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - Catalogs`;
    if (isMounted) reloadCatalogs();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCatalogClickHandler = async (catalog: IAppCatalog) => {
    navigate(`/catalogs/${catalog.Id}`);
  };

  const onRenderItemColumn = (item: IAppCatalog, index?: number, column?: IColumn): any => {
    if (column?.key === 'Icon') {
      return (
        <div style={{ textAlign: 'center' }}>
          <CatalogLogo
            skipLoad={item.LogoUrl === null}
            key={item.Id}
            height={20}
            catalogId={item.Id}
          />
        </div>
      );
    } else if (column?.key === 'OwnerName') {
      return item.OwnerNames[0];
    } else if (column?.key === 'Created') {
      return moment(item.Created).format('DD MMM yyyy');
    } else if (column?.key === 'Title') {
      return <Link onClick={() => onCatalogClickHandler(item)}>{item.DisplayName}</Link>;
    } else if (column?.key === 'Description') {
      return <span title={item.Description}>{item.Description}</span>;
    } else {
      return item![column?.key as keyof IAppCatalog];
    }
  };

  // Runs when the filter keyword changes
  useEffect(() => {
    if (catalogs && filterKeyword !== undefined) {
      setFilteredCatalogs(
        filterKeyword
          ? catalogs?.filter(
              catalog =>
                removeDiacritics(catalog.DisplayName.toLowerCase()).indexOf(
                  removeDiacritics(filterKeyword.toLowerCase())
                ) !== -1
            )
          : catalogs
      );
    }
  }, [filterKeyword, catalogs]);

  return (
    <>
      <Stack grow verticalAlign='center' verticalFill style={{ minHeight: 0 }}>
        <StackItem>
          <SearchBox
            value={filterKeyword}
            onChange={(_, newValue) => setFilterKeyword(newValue)}
            placeholder={t('FILTERS.FILTER_BY_DISPLAY_NAME')}
            iconProps={{ iconName: 'Filter' }}
            underlined={true}
          />
        </StackItem>
        <StackItem grow verticalFill style={{ minHeight: 0, overflowY: 'scroll' }}>
          <DetailsList
            className='catalogs-list'
            setKey='items'
            items={filteredCatalogs || []}
            columns={shimmerColumns(t)}
            selectionMode={SelectionMode.none}
            onRenderItemColumn={onRenderItemColumn}
            ariaLabelForGrid='Item details'
            styles={{ root: { verticalAlign: 'middle' } }}
          />
          {(filteredCatalogs === undefined || filteredCatalogs?.length === 0) && (
            <>
              {filteredCatalogs?.length === 0 && (
                <Text
                  variant='large'
                  block
                  style={{
                    textAlign: 'center',
                    opacity: 0.5,
                  }}
                >
                  {t('TABLE.NO_CATALOGS')}
                </Text>
              )}
              {filteredCatalogs === undefined && <Spinner size={SpinnerSize.large} />}
            </>
          )}
        </StackItem>
      </Stack>
      <Outlet />
    </>
  );
};

export default CatalogsView;
