import data from "./../Configuration/settings.json";

interface IConfiguration {
  PageTitle: string;
  AppVersion: string;
  AppVersionDate?: string;
  InstrumentationKey: string;
  Contact: IConfiguration_Contact;
  AzureAd?: IConfiguration_AzureAd;
  API?: IConfiguration_API;
  BOT?: IConfiguration_Bot;
  ApiHub?: IConfiguration_ApiHub;
  ServiceFlow?: IConfiguration_ServiceFlow;
  Spo?: IConfiguration_Spo;
}

interface IConfiguration_Contact {
  RequestAccessLink: string;
  Target: string;
  ContactSupportEmail: string;
  ContactSupportSubject: string;
}

interface IConfiguration_AzureAd {
  Authority?: string;
  ClientId?: string;
  RedirectUri?: string;
  Scopes?: Array<string>;
}

interface IConfiguration_API {
  BaseUri?: string;
  Scopes?: Array<string>;
}

interface IConfiguration_Bot {
  BaseUri?: string;
  Scopes?: Array<string>;
}

interface IConfiguration_ApiHub {
  BaseUri?: string;
  Scopes?: Array<string>;
}

interface IConfiguration_ServiceFlow {
  BaseUri?: string;
  Scopes?: Array<string>;
}

interface IConfiguration_Spo {
  Scopes?: Array<string>;
}

export default class ConfigurationService {
  private static _default: ConfigurationService;

  public static get Default(): ConfigurationService {
    if (this._default == null) {
      this._default = new ConfigurationService();
    }
    return this._default;
  }

  public get Configuration(): IConfiguration {
    return data;
  }
}
