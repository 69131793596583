import { FC, createContext, useContext } from 'react';
import { ApiHttpServiceContext } from './ApiHttpService';
import IUserInformation from '../../Models/API/IUserInformation';
import IUserPermission, { ResourceType } from '../../Models/API/IUserPermission';
import IUserDocument from '../../Models/API/IUserDocument';
import { AxiosProgressEvent, CancelToken } from 'axios';
import IUserSettings from '../../Models/API/IUserSettings';
import { CacheServiceContext } from "./CacheService";
import IAvailableLanguages from '../../Models/API/IAvailableLanguages';

export interface IUserService {
  GetUserInformation(): Promise<IUserInformation | null>;
  GetUserPermissions(
    principalId: string,
    resourceType: ResourceType,
    resourceId: number
  ): Promise<IUserPermission[] | null>;
  GetUserDocuments(): Promise<IUserDocument[] | null>;
  UploadUserDocument(
    file: File,
    cancelToken?: CancelToken | undefined,
    onUploadProgress?: ((progressEvent: AxiosProgressEvent) => void) | undefined
  ): Promise<IUserDocument | null>;
  DeleteUserDocument(documentId: number): Promise<void | null>;
  OptIntoAnalytics(optIn: boolean): Promise<void | null>;
  SaveSettings(userSettings: IUserSettings): Promise<IUserSettings | null>;
  GetSettings(): Promise<IUserSettings | null>;
  GetAvailableLanguages() : Promise<IAvailableLanguages[] | null>;
}

export const UserServiceContext = createContext<IUserService | undefined>(undefined);

const UserService: FC = ({ children }: any) => {
  const apiHttpService = useContext(ApiHttpServiceContext);
  const cacheService = useContext(CacheServiceContext);
  
  const userService: IUserService = {
    async GetUserInformation() {
      return await apiHttpService!.Get<IUserInformation>(`users/me`, {}, true, false);
    },
    async GetUserPermissions(principalId: string, resourceType: ResourceType, resourceId: number) {
      return await apiHttpService!.Post<IUserPermission[]>(`users/permissions`, {
        PrincipalId: principalId,
        ResourceType: resourceType,
        ResourceId: resourceId,
      });
    },
    async GetUserDocuments() {
      return await apiHttpService!.Get<IUserDocument[]>(`users/documents`);
    },
    async UploadUserDocument(
      file: File,
      cancelToken: CancelToken | undefined = undefined,
      onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined = undefined
    ) {
      var formData = new FormData();
      formData.append('file', file);
      return await apiHttpService!.Post<IUserDocument>(
        `users/documents`,
        formData,
        {},
        cancelToken,
        onUploadProgress,
        true
      );
    },
    async DeleteUserDocument(documentId: number) {
      await apiHttpService!.Delete<void>(`users/documents/${documentId}`);
    },
    async OptIntoAnalytics(optIn: boolean): Promise<void | null> {
      await apiHttpService!.Put<void>(`users/opt-in-analytics`, {
        OptIntoAnalytics: optIn,
      });
    },
    async SaveSettings(userSettings: IUserSettings) {
      var reply = await apiHttpService!.Put<IUserSettings>("users/settings", userSettings);
      cacheService!.ClearCache("settings");
      return reply;
    },
    async GetSettings() {
      return await apiHttpService!.Get<IUserSettings>("users/settings");
    },
    async GetAvailableLanguages() {
      return await apiHttpService!.Get<IAvailableLanguages[]>("users/languages");
    },
  };

  return <UserServiceContext.Provider value={userService}>{children}</UserServiceContext.Provider>;
};

export default UserService;
