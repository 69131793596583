import { FC, useState } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import IAppCatalog from "../../../Models/API/IAppCatalog";
import { Stack, Pivot, PivotItem } from "@fluentui/react";
import { useTranslation } from "react-i18next";

export interface INewChatAppProps {}

const CatalogOptions: FC<INewChatAppProps> = () => {
  const { catalog, setCatalog } = useOutletContext<{
    catalog: IAppCatalog;
    setCatalog: (value: IAppCatalog) => void;
  }>();

  const location = useLocation();
  const [currentKey, setCurrentKey] = useState<string>(
    location.pathname.split("/")[3]
  );

  const { t } = useTranslation();

  return (
    <>
      <Stack tokens={{ childrenGap: 20 }} verticalFill grow style={{ minHeight: 0 }}>
        <Stack.Item>
          <Pivot
            aria-label="Catalog Options"
            selectedKey={currentKey}
            onLinkClick={(item) => setCurrentKey(item?.props.itemKey!)}
          >
            {catalog.PermissionType !== null && (
              <PivotItem itemKey="apps" headerText={t("CATALOG.APPS")} itemIcon="CubeShape">
                <Navigate to="apps" replace />
              </PivotItem>
            )}
            {(catalog.PermissionType === "FullControl" ||
              catalog.PermissionType === "Owner") && (
              <PivotItem
                itemKey="permissions"
                headerText={t("PERMISSIONS.PERMISSIONS")}
                itemIcon="Permissions"
              >
                <Navigate to="permissions" replace />
              </PivotItem>
            )}
            {(catalog.PermissionType === "FullControl" ||
              catalog.PermissionType === "Owner") && (
              <PivotItem
                itemKey="settings"
                headerText={t("MAIN.SETTINGS")}
                itemIcon="Settings"
              >
                <Navigate to="settings" replace />
              </PivotItem>
            )}
          </Pivot>
        </Stack.Item>
        <Stack.Item grow verticalFill style={{ minHeight: 0 }}>
          <Outlet context={{ catalog, setCatalog }}></Outlet>
        </Stack.Item>
      </Stack>
    </>
  );
};

export default CatalogOptions;
