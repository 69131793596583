import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import IAppCatalog from "../../../../../Models/API/IAppCatalog";
import {
  Stack,
  TextField,
  DefaultButton,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Text,
} from "@fluentui/react";
import {
  INewAutomateApp,
  automateAppServiceContext,
} from "../../../../../Services/API/AutomateAppService";
import { trackPageView } from "../../../../../Services/AppInsights";
import { useTranslation } from "react-i18next";

const defaultNewAutomateApp: INewAutomateApp = {
  DisplayName: "",
  Description: "",
};

export interface INewAutomateAppProps {}

const NewAutomateApp: FC<INewAutomateAppProps> = () => {
  const navigate = useNavigate();
  const automateAppService = useContext(automateAppServiceContext);

  const { catalog } = useOutletContext<{ catalog: IAppCatalog }>();

  const [newAutomateApp, setNewAutomateApp] = useState<INewAutomateApp>(
    defaultNewAutomateApp
  );
  const [creatingAutomateApp, setCreatingAutomateApp] =
    useState<boolean>(false);

  const { t } = useTranslation();
  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createAutomateApp = async (): Promise<void> => {
    if (!creatingAutomateApp) {
      newAutomateApp.AppCatalogId = catalog.Id;
      setCreatingAutomateApp(true);
      const newApp = await automateAppService?.Create(newAutomateApp);
      if (newApp != null) {
        navigate(`../${newApp!.Id.toString()}`);
      } else {
        setCreatingAutomateApp(false);
      }
    }
  };

  return (
    <>
      <Stack tokens={{ childrenGap: 20 }}>
        <Stack.Item>
          <TextField
            required={true}
            label={t("MAIN.DISPLAY_NAME")}
            value={newAutomateApp.DisplayName}
            onChange={(event, newValue) =>
              setNewAutomateApp({
                ...newAutomateApp,
                DisplayName: newValue,
              })
            }
          />
          <Text variant="xSmall">{t("MAIN.MIN_CHARACTERS", {count: 3})}</Text>
        </Stack.Item>
        <Stack.Item>
          <TextField
            required={true}
            label={t("MAIN.DESCRIPTION")}
            multiline
            rows={3}
            resizable={false}
            value={newAutomateApp.Description}
            onChange={(event, newValue) =>
              setNewAutomateApp({
                ...newAutomateApp,
                Description: newValue,
              })
            }
          />
          <Text variant="xSmall">
            {t("MAIN.MIN_AND_MAX_CHARACTERS", {min: 20, max: 1000})}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <DefaultButton
              onClick={() => navigate(`/catalogseditor/${catalog.Id}`)}
              text={t("MAIN.CANCEL")}
            />
            <PrimaryButton
              style={{ minWidth: 80 }}
              onClick={createAutomateApp}
              disabled={
                !newAutomateApp.DisplayName ||
                newAutomateApp.DisplayName.length < 3 ||
                !newAutomateApp.Description ||
                newAutomateApp.Description.length < 20 ||
                newAutomateApp.Description.length > 1000
              }
              text={creatingAutomateApp ? "" : t("MAIN.CREATE")}
            >
              {creatingAutomateApp && <Spinner size={SpinnerSize.small} />}
            </PrimaryButton>
          </Stack>
        </Stack.Item>
      </Stack>
    </>
  );
};

export default NewAutomateApp;
