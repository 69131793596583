import { FC } from "react";
import styles from "./Maintenance.module.scss";
import logo from "../../Assets/Images/jti_logo.svg";
import maintenance from "../../Assets/Images/maintenance.svg";
import { Image, Stack, Text } from "@fluentui/react";
import { Navigation } from "../../Components/Navigation/Navigation";
import { maxWebsiteWidth } from "../Start/AppStart";
import { Footer } from "../../Components/Footer/Footer";
import { useTranslation } from "react-i18next";

const Maintenance: FC = () => {

  const { t } = useTranslation();

  return (
    <Stack
      horizontalAlign="center"
      grow
      verticalFill
      style={{ height: "100%", minHeight: 0 }}
    >
      <Stack style={{ maxWidth: maxWebsiteWidth, width: "100%" }}>
        <Navigation maintenance={true} />
      </Stack>
      <Stack
        grow
        verticalFill
        style={{
          minHeight: 0,
          maxWidth: maxWebsiteWidth,
          width: "100%",
        }}
        tokens={{ padding: "0 2rem" }}
      >
        <div className={styles.maintenance}>
          <Image
            className={styles.image}
            src={maintenance}
            alt="Site under maintenance"
          />
          <Text variant="superLarge">{t("MAINTENANCE.UNDER_MAINTENANCE")}</Text>
          <Text variant="small">
            {t("MAINTENANCE.MAINTENANCE_DESCRIPTION")}
          </Text>
        </div>
      </Stack>
      <Stack style={{ maxWidth: maxWebsiteWidth, width: "100%" }}>
        <Footer maintenance={true} />
      </Stack>
    </Stack>
  );
};

export default Maintenance;
