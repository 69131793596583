import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  Dropdown,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { FC, useState } from "react";
import MetadataField from "../MetadataField/MetadataField";
import IMetadataField, {
  MetadataFieldTypes,
} from "../../../Models/API/IMetadataField";
import { useTranslation } from "react-i18next";

type AddMetadataFieldProps = {
  hidden: boolean;
  onFieldAdd: (field: IMetadataField) => Promise<void>;
  onCancel: () => void;
  canAddField: (field: IMetadataField) => boolean;
};

const defaultNewField: IMetadataField = {
  DisplayName: "",
  Description: "",
  Required: false,
  Type: "String",
  DefaultValue: "",
};

const AddMetadataField: FC<AddMetadataFieldProps> = ({
  hidden,
  onFieldAdd,
  onCancel,
  canAddField,
}: AddMetadataFieldProps) => {
  const [newField, setNewField] = useState<IMetadataField>(defaultNewField);
  const [addingField, setAddingField] = useState<boolean>(false);

  const cancel = () => {
    setNewField(defaultNewField);
    onCancel();
  };

  const add = () => {
    if (!addingField) {
      setAddingField(true);
      onFieldAdd(newField).then(() => {
        setNewField(defaultNewField);
        setAddingField(false);
      });
    }
  };

  const { t } = useTranslation();

  return (
    <Dialog
      hidden={hidden}
      onDismiss={cancel}
      dialogContentProps={{
        title: t("METADATA.NEW_FIELD"),
        subText: t("METADATA.NEW_FIELD_SUBTITLE"),
      }}
      modalProps={{
        isBlocking: true,
      }}
    >
      <Stack tokens={{ childrenGap: 20 }}>
        <Stack.Item>
          <Dropdown
            label={t("METADATA.FIELD_TYPE")}
            selectedKey={newField.Type}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={(evt, option, index) => {
              setNewField({
                ...newField,
                DefaultValue: "",
                Type: option!.key as any,
              });
            }}
            placeholder={t("MAIN.SELECT_OPTION")}
            options={MetadataFieldTypes}
          />
        </Stack.Item>
        <Stack.Item>
          <TextField
            required={true}
            label={t('MAIN.DISPLAY_NAME')}
            value={newField.DisplayName}
            onChange={(event, newValue) =>
              setNewField({
                ...newField,
                DisplayName: newValue!,
              })
            }
          ></TextField>
          {!canAddField(newField) && (
            <Text style={{ marginTop: 5 }} variant={"xSmall"} block>
              {t('METADATA.FIELD_NAME_ALREADY_EXISTS')}
            </Text>
          )}
          <Text variant="xSmall">{t('MAIN.MIN_CHARACTERS', {count: 3})}</Text>
        </Stack.Item>
        <Stack.Item>
          <TextField
            label={t('MAIN.DESCRIPTION')}
            multiline
            rows={3}
            resizable={false}
            value={newField.Description}
            onChange={(event, newValue) =>
              setNewField({
                ...newField,
                Description: newValue!,
              })
            }
          />
        </Stack.Item>
        <Stack.Item>
          <MetadataField
            label={t('MAIN.DEFAULT_VALUE')}
            value={newField.DefaultValue}
            setValue={(value) => {
              setNewField({
                ...newField!,
                DefaultValue: value!,
              });
            }}
            field={newField!}
          ></MetadataField>
        </Stack.Item>
        <Stack.Item>
          <Checkbox
            label={t('MAIN.REQUIRED')}
            checked={newField.Required}
            onChange={(event, checked) =>
              setNewField({
                ...newField,
                Required: checked!,
              })
            }
          />
        </Stack.Item>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          onClick={add}
          text={addingField ? "" : t('METADATA.ADD_FIELD')}
          disabled={
            !(
              newField.DisplayName !== "" &&
              newField.DisplayName.length >= 3 &&
              canAddField(newField)
            )
          }
        >
          {addingField && <Spinner size={SpinnerSize.small} />}
        </PrimaryButton>
        <DefaultButton onClick={cancel} text={t('MAIN.CANCEL')} />
      </DialogFooter>
    </Dialog>
  );
};

export default AddMetadataField;
