import { FC, useEffect, useState } from "react";
import { IAutomateAppOutputParameter } from "../../../Models/API/IAutomateApp";
import {
  PrimaryButton,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import { AutomateRunReturn } from "../../../Services/API/AutomateAppService";
import JSZip from "jszip";
import saveAs from "file-saver";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";

export interface IOutputParamsProps {
  appName: string;
  outputParameters: IAutomateAppOutputParameter[];
  response: AutomateRunReturn | null | undefined;
}

const OutputParams: FC<IOutputParamsProps> = ({
  appName,
  outputParameters,
  response,
}) => {
  const [outputParams, setOutputParams] = useState<
    IAutomateAppOutputParameter[]
  >([]);
  const [downloading, setDownloading] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setOutputParams([]);
    if (response) {
      const outParams: IAutomateAppOutputParameter[] = [];
      const extraValues: string[] = [];
      for (const property in response) {
        const outParam = outputParameters.find(
          (x) => x.InternalName === property
        );
        if (outParam) {
          let outToAdd: IAutomateAppOutputParameter = {
            ...outParam,
            Value: response[outParam.InternalName!],
          };
          outParams.push(outToAdd);
        } else {
          extraValues.push(property);
        }
      }
      setOutputParams(outParams);
    }
  }, [response, outputParameters]);

  const getArrayTypeField = (
    param: IAutomateAppOutputParameter,
    value: any
  ) => {
    switch (param.ArrayType) {
      case "String":
      case "Number":
        return <Text block>{value}</Text>;
      case "Boolean":
        return <Text block>{value.toString()}</Text>;
      case "File":
        const fileName = value.name;
        return (
          <>
            <Stack grow horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item>
                <PrimaryButton
                  title={t("MAIN.DOWNLOAD")}
                  onClick={() => downloadFiles(param.Value)}
                >
                  {t("MAIN.DOWNLOAD")}
                </PrimaryButton>
              </Stack.Item>
              <Stack.Item>
                <Text block>{fileName}</Text>
              </Stack.Item>
            </Stack>
          </>
        );
    }
  };

  const downloadFiles = async (files: any) => {
    if (downloading) {
      return;
    }
    setDownloading(true);
    if (files.length > 1) {
      const zip = new JSZip();
      for (let i = 0; i < files.length; i++) {
        const doc = files[i];
        const decodedString = atob(doc.contentBytes);
        const downloadedDoc = decodedString;
        zip.file(
          doc.name,
          new Blob([downloadedDoc!], {
            type: "application/octet-stream",
          })
        );
      }
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `${appName}.zip`);
    } else {
      const downloadedDoc = files[0].contentBytes;
      const decodedString = atob(downloadedDoc);
      saveAs(
        new Blob([decodedString!], {
          type: "application/octet-stream",
        }),
        files[0].name
      );
    }
    setDownloading(false);
  };

  const getFormField = (param: IAutomateAppOutputParameter) => {
    switch (param.Type) {
      case "Boolean":
        return <Text block>{param.Value.toString()}</Text>;
      case "String":
        return <ReactMarkdown>{param.Value}</ReactMarkdown>;
      case "Number":
        return <Text block>{param.Value}</Text>;
      case "Array":
        return (
          <Stack grow tokens={{ childrenGap: 10 }}>
            {param.Value.map((x: any, index: number) => (
              <Stack.Item key={index}>
                <>
                  {param.ArrayType ? <>{getArrayTypeField(param, x)}</> : <></>}
                </>
              </Stack.Item>
            ))}
          </Stack>
        );
      case "File":
        const fileName = param.Value.name;
        const data = "data:application/octet-stream;base64,";
        const base64 = `${data}${param.Value.contentBytes}`;

        return (
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 20 }}>
            <PrimaryButton
              href={base64}
              download={fileName}
              target="_blank"
              title={t("MAIN.DOWNLOAD")}
            >
              {t("MAIN.DOWNLOAD")}
            </PrimaryButton>
            <Text block>{t("MAIN.FILENAME")}: {fileName}</Text>
          </Stack>
        );
    }
  };

  return (
    <>
      <Stack grow style={{ display: "block" }} tokens={{ childrenGap: 20 }}>
        <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 20 }}>
          <StackItem style={{ minWidth: 250, fontWeight: 700 }}>{t("MAIN.NAME")}</StackItem>
          <StackItem grow style={{ fontWeight: 700 }}>{t("MAIN.VALUE")}</StackItem>
        </Stack>
        {outputParams.map((param, index) => {
          return (
            <Stack
              verticalAlign="center"
              horizontal
              key={index}
              tokens={{ childrenGap: 20 }}
            >
              <StackItem style={{ minWidth: 250 }} title={param.Description}>
                <strong>{param.DisplayName}</strong>
              </StackItem>
              <StackItem grow>{getFormField(param)}</StackItem>
            </Stack>
          );
        })}
      </Stack>
    </>
  );
};

export default OutputParams;
