import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import IChatApp from "../../../../../../../Models/API/IChatApp";
import {
  chatAppServiceContext,
  INewChatAppSPLibrary,
} from "../../../../../../../Services/API/ChatAppService";
import {
  Stack,
  Spinner,
  SpinnerSize,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  StackItem,
  IDropdownOption,
  Dropdown,
  Text,
  ComboBox,
  IComboBox,
  IComboBoxOption,
  Label,
} from "@fluentui/react";
import {
  ISharePointLibrary,
  ISharePointSite,
  spoServiceContext,
} from "../../../../../../../Services/API/SpoService";
import { ISpLibraryKey } from "../../../../../../../Models/API/ISpLibrary";
import { useTranslation } from "react-i18next";

const newSpLibraryForm: INewChatAppSPLibrary = {
  SiteId: "",
  LibraryId: "",
  LibraryTitle: "",
  SiteTitle: "",
};

interface NewSPLibraryProps {
  exisitingLibrariesKeys: ISpLibraryKey[] | null | undefined;
  isRefresh: boolean;
  setHideNewDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onLibraryCreated?: () => void;
}

const NewSPLibrary: FC<NewSPLibraryProps> = ({
  exisitingLibrariesKeys,
  isRefresh,
  setHideNewDialog,
  onLibraryCreated,
}) => {
  const { t } = useTranslation();
  const chatAppService = useContext(chatAppServiceContext);
  const spoService = useContext(spoServiceContext);

  const { chatApp } = useOutletContext<{ chatApp: IChatApp }>();

  const [newSpLibrary, setNewSpLibrary] =
    useState<INewChatAppSPLibrary>(newSpLibraryForm);
  const [creatingSpLibrary, setCreatingSpLibrary] = useState<boolean>(false);

  const [filteredSpoSites, setFilteredSpoSites] = useState<ISharePointSite[]>(
    []
  );

  const [siteLibraries, setSiteLibraries] = useState<
    ISharePointLibrary[] | undefined
  >([]);
  const [selectedSiteId, setSelectedSiteId] = useState<string | undefined>(
    undefined
  );
  const [selectedLibraryId, setSelectedLibraryId] = useState<
    string | undefined
  >(undefined);
  const [selectedSiteTitle, setSelectedSiteTitle] = useState<
    string | undefined
  >(undefined);
  const [selectedLibraryTitle, setSelectedLibraryTitle] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    searchSpoSites("*");
  }, []);

  const searchSpoSites = async (text: string) => {
    let fetchedSites = await spoService?.SearchSpoSites(text || "*");
    fetchedSites = fetchedSites?.sort((a, b) => {
      if (a.displayName && b.displayName) {
        return a.displayName.localeCompare(b.displayName);
      }
      return 0;
    });
    setFilteredSpoSites(fetchedSites || []);
  };

  const createSpLibrary = async (): Promise<void> => {
    if (!creatingSpLibrary) {
      setCreatingSpLibrary(true);
      await chatAppService?.AddChatSpLibrary(chatApp.Id, newSpLibrary);

      setCreatingSpLibrary(false);
      setHideNewDialog(true);
      resetState();

      if (onLibraryCreated) {
        onLibraryCreated();
      }
    }
  };

  const onSiteChange = async (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    setSiteLibraries(undefined);
    setSelectedLibraryId(undefined);
    setSelectedLibraryTitle(undefined);

    const siteId = option?.key as string;
    const siteTitle = option?.text as string;
    setSelectedSiteId(siteId);
    setSelectedSiteTitle(siteTitle);

    if (siteId) {
      let libraries = await spoService?.GetAllSpoLibraries(siteId);

      if (exisitingLibrariesKeys && exisitingLibrariesKeys.length > 0) {
        libraries = libraries?.filter(
          (l) =>
            !exisitingLibrariesKeys.some(
              (key) => key.LibraryId === l.id && key.SiteId === siteId
            )
        );
      }

      libraries = libraries?.sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      });

      setSiteLibraries(libraries || undefined);
    }

    setNewSpLibrary({
      ...newSpLibrary,
      SiteTitle: siteTitle,
      SiteId: siteId,
    });
  };

  const onLibraryChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {
    const libraryId = option?.key as string;
    const libraryName = option?.text as string;
    setSelectedLibraryId(libraryId);
    setSelectedLibraryTitle(libraryName);

    setNewSpLibrary({
      ...newSpLibrary,
      LibraryTitle: libraryName,
      LibraryId: libraryId,
    });
  };

  const resetState = () => {
    setFilteredSpoSites([]);
    setSiteLibraries([]);
    setSelectedSiteId(undefined);
    setSelectedLibraryId(undefined);
  };

  useEffect(() => {
    if (isRefresh) {
      resetState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout | undefined>(
    undefined
  );
  const onSearchTextChanged = async (text: string) => {
    if (timeoutRef !== undefined) clearTimeout(timeoutRef);
    setTimeoutRef(
      setTimeout(() => {
        searchSpoSites(text);
        setTimeoutRef(undefined);
      }, 500)
    );
  };

  return (
    <Dialog
      minWidth={500}
      hidden={false}
      onDismiss={() => {
        if (!creatingSpLibrary) {
          setHideNewDialog(true);
        }
      }}
      dialogContentProps={{
        title: t("CATALOG.ADD_SP_LIBRARIES"),
        subText: t("CATALOG.ADD_SP_LIBRARIES_CONFIRMATION"),
      }}
      modalProps={{
        isBlocking: !creatingSpLibrary,
      }}
    >
      {creatingSpLibrary && (
        <Stack
          verticalAlign="center"
          horizontalAlign="center"
          tokens={{ childrenGap: 20 }}
        >
          <Spinner size={SpinnerSize.large} />
          <Text
            variant="large"
            block
            style={{
              textAlign: "center",
              opacity: 0.5,
            }}
          >
            {t("MAIN.CREATING")} "{selectedSiteTitle}-{selectedLibraryTitle}"{" "}
            {t("MAIN.SP_LIBRARY")}...
          </Text>
        </Stack>
      )}
      {!creatingSpLibrary && (
        <>
          <Stack tokens={{ childrenGap: 20 }}>
            <StackItem>
              <Stack.Item>
                <Stack
                  horizontal
                  verticalAlign="center"
                  tokens={{ childrenGap: 5 }}
                >
                  <Label>{t("CATALOG.SELECT_SP_SITES")}</Label>
                  {timeoutRef !== undefined && (
                    <Spinner size={SpinnerSize.xSmall} />
                  )}
                </Stack>
                <ComboBox
                  allowFreeform={true}
                  autoComplete={"off"}
                  placeholder={t("CATALOG.SELECT_SP_SITES_PLACEHOLDER")}
                  options={filteredSpoSites.map((site) => ({
                    key: site.id,
                    text: site.displayName || site.name,
                    data: {
                      webUrl: site.webUrl,
                    },
                  }))}
                  onRenderOption={(option) => (
                    <Stack style={{ marginBottom: 5 }}>
                      <Text block>
                        <strong>{option?.text}</strong>
                      </Text>
                      <Text block variant="tiny">
                        {option?.data?.webUrl}
                      </Text>
                    </Stack>
                  )}
                  onInputValueChange={onSearchTextChanged}
                  openOnKeyboardFocus={true}
                  onChange={onSiteChange}
                />
              </Stack.Item>
              <Stack.Item>
                <Dropdown
                  key={selectedSiteId || "no-site"}
                  label={t("CATALOG.SELECT_LIBRARY")}
                  placeholder={t("CATALOG.SELECT_LIBRARY_PLACEHOLDER")}
                  options={
                    siteLibraries?.map((library) => ({
                      key: library.id,
                      text: library.name,
                    })) || []
                  }
                  onChange={onLibraryChange}
                  selectedKey={selectedLibraryId}
                  disabled={
                    !selectedSiteId ||
                    !siteLibraries ||
                    siteLibraries.length === 0
                  }
                  onRenderPlaceholder={() => (
                    <Stack style={{ height: "100%" }}>
                      {!selectedSiteId ? (
                        <span>{t("CATALOG.CHOOSE_SITE_FIRST")}</span>
                      ) : siteLibraries === undefined ? (
                        <Stack
                          horizontalAlign="center"
                          verticalAlign="center"
                          style={{ height: "100%" }}
                        >
                          <Spinner size={SpinnerSize.small} />
                        </Stack>
                      ) : siteLibraries.length === 0 ? (
                        <span>{t("TABLE.NO_LIBRARIES")}</span>
                      ) : (
                        <span>{t("CATALOG.SELECT_LIBRARY_PLACEHOLDER")}</span>
                      )}
                    </Stack>
                  )}
                />
              </Stack.Item>
            </StackItem>
          </Stack>
          <DialogFooter>
            <PrimaryButton
              style={{ minWidth: 80 }}
              onClick={createSpLibrary}
              disabled={!selectedSiteId || !selectedLibraryId}
              text={creatingSpLibrary ? "" : t("MAIN.CREATE")}
            ></PrimaryButton>
            <DefaultButton
              onClick={() => setHideNewDialog(true)}
              text={t("MAIN.CANCEL")}
            />
          </DialogFooter>
        </>
      )}
    </Dialog>
  );
};

export default NewSPLibrary;
