import {
  CommandBar,
  DefaultButton,
  DetailsList,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { FC, useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { INewLibrary } from '../../../../../../../Services/API/LibraryService';
import IMetadataField from '../../../../../../../Models/API/IMetadataField';
import moment from 'moment';
import AddMetadataField from '../../../../../../../Components/Metadata/AddMetadataField/AddMetadataField';
import { chatAppServiceContext } from '../../../../../../../Services/API/ChatAppService';
import { trackPageView } from '../../../../../../../Services/AppInsights';
import { useTranslation } from 'react-i18next';
import { LanguageServiceContext } from '../../../../../../../Services/LanguageService';

const defaultNewLib: INewLibrary = {
  Title: '',
  Description: '',
};

const NewLibrary: FC = () => {
  const chatAppService = useContext(chatAppServiceContext);
  const languageService = useContext(LanguageServiceContext);

  const params = useParams();
  const navigate = useNavigate();
  const [newLibrary, setNewLibrary] = useState<INewLibrary>(defaultNewLib);
  const [fields, setFields] = useState<IMetadataField[]>([]);
  const [selectedFields, setSelectedFields] = useState<IMetadataField[]>([]);
  const [commandBarButtons, setCommandBarButtons] = useState<ICommandBarItemProps[]>([]);
  const [hideNewFieldDialog, setHideNewFieldDialog] = useState<boolean>(true);
  const [creatingLibrary, setCreatingLibrary] = useState<boolean>(false);
  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: IColumn[] = [
    {
      key: 'DisplayName',
      name: t('MAIN.DISPLAY_NAME'),
      minWidth: 200,
    },
    {
      key: 'DefaultValue',
      name: t('MAIN.DEFAULT_VALUE'),
      minWidth: 200,
    },
    {
      key: 'Type',
      name: t('MAIN.TYPE'),
      minWidth: 200,
    },
    {
      key: 'Required',
      name: t('MAIN.REQUIRED'),
      minWidth: 100,
    },
  ];

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedFields(selection.getSelection() as IMetadataField[]);
    },
  });

  const onRenderItemColumn = (item: IMetadataField, index?: number, column?: IColumn): any => {
    if (column?.key === 'Required') {
      return item.Required ? t('MAIN.YES') : t('MAIN.NO');
    } else if (column?.key === 'DefaultValue') {
      switch (item.Type) {
        case 'DateTime':
          return item.DefaultValue ? moment(item.DefaultValue as string).format('DD MMM yyyy') : '';
        case 'Boolean':
          return item.DefaultValue ? t('MAIN.YES') : t('MAIN.NO');
        case 'Double':
        case 'Integer':
        case 'String':
          return item.DefaultValue as string;
        case 'StringList':
          return item.DefaultValue ? (item.DefaultValue as string[]).join(',') : '';
      }
    } else {
      return item![column?.key as keyof IMetadataField];
    }
  };

  // Runs when the selected libraries change
  useEffect(() => {
    setCommandBarButtons([
      {
        key: 'add',
        text: t('MAIN.ADD'),
        iconProps: { iconName: 'Add' },
        subMenuProps: {
          items: [
            {
              key: 'field',
              text: t('MAIN.FIELD'),
              iconProps: { iconName: 'TextField' },
              onClick: (event, item) => {
                setHideNewFieldDialog(false);
              },
            },
          ],
        },
      },
      {
        key: 'remove',
        text: t('MAIN.REMOVE'),
        iconProps: { iconName: 'Delete' },
        onClick: (event, item) => {
          setFields(
            fields.filter(field => {
              return selectedFields.filter(f => f.DisplayName === field.DisplayName).length === 0;
            })
          );
        },
        disabled: selectedFields.length === 0,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFields, languageService?.language]);

  const addField = async (field: IMetadataField) => {
    setFields([
      ...fields,
      {
        DisplayName: field.DisplayName,
        Description: field.Description,
        Required: field.Required,
        Type: field.Type,
        DefaultValue:
          field.DefaultValue === ''
            ? field.Type === 'StringList'
              ? []
              : null
            : field.DefaultValue,
      },
    ]);
    setHideNewFieldDialog(true);
  };

  const createLibrary = async (): Promise<void> => {
    if (!creatingLibrary) {
      setCreatingLibrary(true);
      newLibrary.Metadata = {
        Fields: fields,
      };
      const newLib = await chatAppService?.CreateChatAppLibrary(
        parseInt(params.appId!),
        newLibrary
      );
      if (newLib != null) {
        navigate(`../${newLib.Id}`, { relative: 'path' });
      } else {
        setCreatingLibrary(false);
      }
    }
  };

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0, overflowY: 'auto' }} tokens={{ childrenGap: 20 }}>
        <Stack.Item>
          <TextField
            required={true}
            label={t('MAIN.TITLE')}
            value={newLibrary.Title}
            onChange={(event, newValue) =>
              setNewLibrary({
                ...newLibrary,
                Title: newValue,
              })
            }
          />
          <Text variant='xSmall'>{t('MAIN.MIN_CHARACTERS', { count: 3 })}</Text>
        </Stack.Item>
        <Stack.Item>
          <TextField
            label={t('MAIN.DESCRIPTION')}
            multiline
            rows={3}
            resizable={false}
            value={newLibrary.Description}
            onChange={(event, newValue) =>
              setNewLibrary({
                ...newLibrary,
                Description: newValue,
              })
            }
          />
        </Stack.Item>
        <Stack.Item verticalFill style={{ minHeight: 0, overflowY: 'auto' }}>
          <CommandBar items={commandBarButtons} ariaLabel='Fields actions' />
          <DetailsList
            className='metadata-list'
            setKey='items'
            items={fields || []}
            columns={columns}
            selection={selection}
            selectionMode={SelectionMode.multiple}
            onRenderItemColumn={onRenderItemColumn}
            ariaLabelForGrid='Item details'
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            styles={{ root: { verticalAlign: 'middle' } }}
          />
          {fields.length === 0 && (
            <Text
              variant='large'
              block
              style={{
                textAlign: 'center',
                opacity: 0.5,
              }}
            >
              {t('TABLE.NO_FIELDS')}
            </Text>
          )}
        </Stack.Item>
        <Stack.Item>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <DefaultButton onClick={() => navigate(-1)} text={t('MAIN.CANCEL')} />
            <PrimaryButton
              style={{ minWidth: 80 }}
              onClick={createLibrary}
              disabled={!newLibrary.Title || newLibrary.Title.length < 3}
              text={creatingLibrary ? '' : t('MAIN.CREATE')}
            >
              {creatingLibrary && <Spinner size={SpinnerSize.small} />}
            </PrimaryButton>
          </Stack>
        </Stack.Item>
      </Stack>
      <AddMetadataField
        canAddField={field =>
          fields.find(
            f => f.DisplayName.trim().toLowerCase() === field.DisplayName.trim().toLowerCase()
          ) === undefined
        }
        hidden={hideNewFieldDialog}
        onCancel={() => setHideNewFieldDialog(true)}
        onFieldAdd={addField}
      ></AddMetadataField>
    </>
  );
};

export default NewLibrary;
