import { ChangeEvent, FC, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import IAppCatalog from '../../../../../../Models/API/IAppCatalog';
import {
  Stack,
  TextField,
  DefaultButton,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Text,
} from '@fluentui/react';
import { trackPageView } from '../../../../../../Services/AppInsights';
import {
  aiToolAppServiceContext,
  INewAiToolApp,
} from '../../../../../../Services/API/AiToolAppService';
import styles from './NewAiToolApp.module.scss';
import { DialogServiceContext } from '../../../../../../Services/Dialogs/DialogService';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

const VALID_UPLOAD_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif'];

const defaultNewAiToolApp: INewAiToolApp = {
  DisplayName: '',
  Description: '',
};

export interface INewAutomateAppProps {}

const NewAiToolApp: FC<INewAutomateAppProps> = () => {
  const navigate = useNavigate();
  const aiToolAppService = useContext(aiToolAppServiceContext);
  const dialogService = useContext(DialogServiceContext);

  const { catalog } = useOutletContext<{ catalog: IAppCatalog }>();

  const [newAiToolApp, setNewAiToolApp] = useState<INewAiToolApp>(defaultNewAiToolApp);
  const [creatingAiToolApp, setCreatingAiToolApp] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState<FileList | null>();
  const [imageData, setImageData] = useState<string | null>(null);
  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createAiToolApp = async (): Promise<void> => {
    if (!creatingAiToolApp) {
      newAiToolApp.AppCatalogId = catalog.Id;
      setCreatingAiToolApp(true);
      const newApp = await aiToolAppService?.Create(newAiToolApp);
      if (newApp != null) {
        if (files) {
          await uploadLogo(newApp.Id);
        }

        navigate(`../${newApp!.Id.toString()}`);
      } else {
        setCreatingAiToolApp(false);
      }
    }
  };

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      var file = files[0];

      if (file) {
        const extension = file.name.split('.')[file.name.split('.').length - 1];
        if (VALID_UPLOAD_FILE_EXTENSIONS.indexOf(extension.toLowerCase()) === -1) {
          dialogService?.showWarningDialog(t("CATALOG.LOGO_UPLOAD"), t("CHAT.FILE_EXTENSION"));
        } else {
          setFiles(files);
          setImageData(URL.createObjectURL(file));
        }
      }
    }
  };

  const uploadLogo = async (catalogId: number) => {
    if (files) {
      var file = files[0];
      try {
        await aiToolAppService!.UpdateLogo(catalogId, file);
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 409 || axiosError.response?.status === 422) {
          dialogService?.showWarningDialog(
            t("CATALOG.LOGO_UPLOAD"),
            (axiosError.response.data as any).details
          );
        } else if (axiosError.response?.status === 400) {
          const errorMsg = (axiosError.response.data as any).errors[''][0];
          if (errorMsg.toLowerCase().indexOf('request body too large') !== -1) {
            dialogService?.showWarningDialog(
              t("CATALOG.LOGO_UPLOAD"),
              t("CHAT.FILE_SIZE")
            );
          } else {
            dialogService?.showWarningDialog(t("CHAT.DOCUMENT_UPLOAD"), errorMsg);
          }
        }
      }
    }
  };

  return (
    <>
      <section className={styles.gridContainer}>
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack.Item>
            <TextField
              required={true}
              label={t("MAIN.TITLE")}
              value={newAiToolApp.DisplayName}
              onChange={(event, newValue) =>
                setNewAiToolApp({
                  ...newAiToolApp,
                  DisplayName: newValue,
                })
              }
            />
            <Text variant='xSmall'>{t("MAIN.MIN_CHARACTERS", {count: 3})}</Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              required={true}
              label={t("MAIN.DESCRIPTION")}
              multiline
              rows={3}
              resizable={false}
              value={newAiToolApp.Description}
              onChange={(event, newValue) =>
                setNewAiToolApp({
                  ...newAiToolApp,
                  Description: newValue,
                })
              }
            />
            <Text variant='xSmall'>{t("MAIN.MIN_AND_MAX_CHARACTERS", {min: 20, max: 1000})}</Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              label={t("MAIN.URL")}
              value={newAiToolApp.AppUrl}
              onChange={(event, newValue) =>
                setNewAiToolApp({
                  ...newAiToolApp,
                  AppUrl: newValue,
                })
              }
            />
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton
                onClick={() => navigate(`/catalogseditor/${catalog.Id}`)}
                text='Cancel'
              />
              <PrimaryButton
                style={{ minWidth: 80 }}
                onClick={createAiToolApp}
                disabled={
                  !newAiToolApp.DisplayName ||
                  newAiToolApp.DisplayName.length < 3 ||
                  !newAiToolApp.Description ||
                  newAiToolApp.Description.length < 20 ||
                  newAiToolApp.Description.length > 1000
                }
                text={creatingAiToolApp ? '' : t("MAIN.CREATE")}
              >
                {creatingAiToolApp && <Spinner size={SpinnerSize.small} />}
              </PrimaryButton>
            </Stack>
          </Stack.Item>
        </Stack>

        <Stack className={styles.logoSection}>
          <section>{imageData ? <img src={imageData} alt='some logo' /> : <></>}</section>
          <Stack.Item>
            <PrimaryButton
              style={{ minWidth: 80 }}
              onClick={() => inputRef.current?.click()}
              text={t("CATALOG.UPLOAD_LOGO")}
            />
            <input
              ref={inputRef}
              type='file'
              accept={VALID_UPLOAD_FILE_EXTENSIONS.map(ext => `.${ext}`).join(',')}
              hidden={true}
              onChange={onFileChange}
            />
          </Stack.Item>
        </Stack>
      </section>
    </>
  );
};

export default NewAiToolApp;
