import { FC, createContext, useContext } from 'react';
import AuthenticationService from '../AuthenticationService';
import { HttpServiceContext } from './HttpService';
import { CacheServiceContext } from './CacheService';
import { AxiosProgressEvent, AxiosResponse, CancelToken } from 'axios';

export interface ISpoHttpService {
  Get<T>(
    path: string,
    headers?: any | undefined,
    useCache?: boolean,
    throwError?: boolean
  ): Promise<T | null>;
  Post<T>(
    path: string,
    data?: any | undefined,
    headers?: any | undefined,
    cancelToken?: CancelToken | undefined,
    onUploadProgress?: ((progressEvent: AxiosProgressEvent) => void) | undefined,
    throwError?: boolean
  ): Promise<T | null>;
  Put<T>(path: string, data?: any | undefined, headers?: any | undefined): Promise<T | null>;
  Delete<T>(path: string, headers?: any | undefined, throwError?: boolean): Promise<T | null>;
}

export const SpoHttpServiceContext = createContext<ISpoHttpService | undefined>(undefined);

const SpoHttpService: FC = ({ children }: any) => {
  const httpService = useContext(HttpServiceContext);
  const cacheService = useContext(CacheServiceContext);

  const spoHttpService: ISpoHttpService = {
    async Get<T>(
      path: string,
      headers?: any | undefined,
      useCache: boolean = false,
      throwError?: boolean
    ) {
      if (headers === undefined || headers === null) {
        headers = {};
      }
      headers['Authorization'] = `Bearer ${await AuthenticationService.Default.SpoAccessToken()}`;
      headers['Content-Type'] = 'application/json';
      headers['ConsistencyLevel'] = 'eventual';
      if (useCache) {
        return await cacheService!.GetCache<T>(path, async () => {
          return await httpService!.Get<T>(`${path}`, headers, throwError);
        });
      } else {
        return await httpService!.Get<T>(`${path}`, headers, throwError);
      }
    },
    async Post<T>(
      path: string,
      data?: any | undefined,
      headers?: any | undefined,
      cancelToken?: CancelToken | undefined,
      onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined = undefined,
      throwError: boolean = false
    ) {
      if (headers === undefined) {
        headers = {};
      }
      headers['Authorization'] = `Bearer ${await AuthenticationService.Default.SpoAccessToken()}`;
      return await httpService!.Post<T>(
        `${path}`,
        data,
        headers,
        cancelToken,
        onUploadProgress,
        throwError
      );
    },
    async Put<T>(path: string, data?: any | undefined, headers?: any | undefined) {
      if (headers === undefined) {
        headers = {};
      }
      headers['Authorization'] = `Bearer ${await AuthenticationService.Default.SpoAccessToken()}`;
      return await httpService!.Put<T>(`${path}`, data, headers);
    },
    async Delete<T>(path: string, headers?: any | undefined, throwError?: boolean) {
      if (headers === undefined) {
        headers = {};
      }
      headers['Authorization'] = `Bearer ${await AuthenticationService.Default.SpoAccessToken()}`;
      return await httpService!.Delete<T>(`${path}`, headers, throwError);
    },
  };

  return (
    <SpoHttpServiceContext.Provider value={spoHttpService}>
      {children}
    </SpoHttpServiceContext.Provider>
  );
};

export default SpoHttpService;
