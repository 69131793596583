import { Pivot, PivotItem, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { FC, useContext, useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { LibraryServiceContext } from "../../../../../../../Services/API/LibraryService";
import ILibrary from "../../../../../../../Models/API/ILibrary";
import ConfigurationService from "../../../../../../../Services/ConfigurationService";
import { useTranslation } from "react-i18next";

const Library: FC = () => {
  const librariesService = useContext(LibraryServiceContext);
  const { setSelectedLibrary } = useOutletContext<{
    setSelectedLibrary: (value: ILibrary) => void;
  }>();

  let { libraryId } = useParams();
  const location = useLocation();
  const [currentKey, setCurrentKey] = useState<string>(
    location.pathname.split("/")[8]
  );

  const [library, setLibrary] = useState<ILibrary | null>(null);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    const execute = async () => {
      let lib: ILibrary | null = null;
      if (isMounted) lib = await librariesService!.Get(parseInt(libraryId!));
      if (isMounted) setLibrary(lib!);
      document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${lib?.Title}`;
    };
    execute();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (library) {
      setSelectedLibrary(library);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [library]);

  return (
    (library && (
      <Stack verticalFill style={{ minHeight: 0 }} tokens={{ childrenGap: 20 }}>
        <Stack.Item>
          <Pivot
            aria-label="Library Options"
            selectedKey={currentKey}
            onLinkClick={(item) => setCurrentKey(item?.props.itemKey!)}
          >
            {library.PermissionType !== null && (
              <PivotItem
                itemKey="documents"
                headerText={t("LIBRARIES.DOCUMENTS")}
                itemIcon="TextDocument"
              >
                <Navigate to="documents" replace />
              </PivotItem>
            )}
            {(library.PermissionType === "FullControl" ||
              library.PermissionType === "Owner") && (
              <PivotItem
                itemKey="permissions"
                headerText={t("PERMISSIONS.PERMISSIONS")}
                itemIcon="Permissions"
              >
                <Navigate to="permissions" replace />
              </PivotItem>
            )}
            {(library.PermissionType === "Write" ||
              library.PermissionType === "FullControl" ||
              library.PermissionType === "Owner") && (
              <PivotItem
                itemKey="metadata"
                headerText={t("METADATA.METADATA")}
                itemIcon="TextField"
              >
                <Navigate to="metadata" replace />
              </PivotItem>
            )}
            {(library.PermissionType === "FullControl" ||
              library.PermissionType === "Owner") && (
              <PivotItem
                itemKey="settings"
                headerText={t("MAIN.SETTINGS")}
                itemIcon="Settings"
              >
                <Navigate to="settings" replace />
              </PivotItem>
            )}
          </Pivot>
        </Stack.Item>
        <Stack.Item grow verticalFill style={{ minHeight: 0 }}>
          <Outlet context={{ library, setLibrary }}></Outlet>
        </Stack.Item>
      </Stack>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default Library;
