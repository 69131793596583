import { Children, createContext, FC, useContext } from "react";
import AuthenticationService from "../AuthenticationService";
import { HttpServiceContext } from "./HttpService";

export interface IAiServiceCatalogApiHttpService {
  Get<T>(
    baseUrl: string,
    path: string,
    headers?: any | undefined,
    throwError?: boolean
  ): Promise<T | null>;
}

export const AiServiceCatalogApiHttpServiceContext = createContext<
  IAiServiceCatalogApiHttpService | undefined
>(undefined);

const AiServiceCatalogApiHttpService: FC = ({ children }: any) => {
  const httpService = useContext(HttpServiceContext);

  const apiHttpService: IAiServiceCatalogApiHttpService = {
    async Get<T>(baseUrl: string, path: string, headers?: any | undefined, throwError?: boolean) {
      if (headers === undefined || headers === null) {
        headers = {};
      }
      headers["Authorization"] = `Bearer ${await AuthenticationService.Default.APIAccessToken()}`;
      return await httpService!.Get<T>(
        `${baseUrl.replace(/\/$/, "")}/${path.replace(/^\//, "")}`,
        headers,
        throwError
      );
    },
  };

  return (
    <AiServiceCatalogApiHttpServiceContext.Provider value={apiHttpService}>
      {children}
    </AiServiceCatalogApiHttpServiceContext.Provider>
  );
};

export default AiServiceCatalogApiHttpService;
