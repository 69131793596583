import {
  PrimaryButton,
  Slider,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { FC, useContext, useEffect, useState } from "react";
import { SettingsServiceContext } from "../../../Services/API/SettingsService";
import ISettings from "../../../Models/API/ISettings";
import { DialogServiceContext } from "../../../Services/Dialogs/DialogService";
import { trackPageView } from "../../../Services/AppInsights";
import { useTranslation } from "react-i18next";

const TrainingSettings: FC = () => {
  const settingsService = useContext(SettingsServiceContext);
  const dialogService = useContext(DialogServiceContext);
  const [settings, setSettings] = useState<ISettings | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const execute = async () => {
      var reply = await settingsService!.Get();
      if (isMounted) setSettings(reply);
    };
    execute();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSettings = async () => {
    setSaving(true);
    await settingsService?.Save(settings!);
    dialogService?.showSuccessDialog(
      t('SETTINGS.SETTINGS_SAVED'),
      t('SETTINGS.SETTINGS_SAVED_DESCRIPTION')
    );
    setSaving(false);
  };

  return (
    (settings && (
      <>
        <Stack horizontal wrap tokens={{ childrenGap: 20 }}>
          <Stack.Item style={{ maxWidth: 300, width: 300 }}>
            <Slider
              label={t('SETTINGS.MAXIMUM_TOKEN_PER_CHUNK')}
              max={200}
              min={10}
              step={10}
              value={settings!.min_size_chunk}
              showValue
              onChange={(val) =>
                setSettings({ ...settings, min_size_chunk: val })
              }
            />
            <Text variant={"xSmall"} block>
              {t("SETTINGS.UPLOAD_CHUNK_MIN_TOKENS")}
            </Text>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 300, width: 300 }}>
            <Slider
              label={t('SETTINGS.MAXIMUM_TOKEN_PER_CHUNK')}
              max={1000}
              step={10}
              min={settings!.min_size_chunk}
              value={settings!.max_size_chunk}
              showValue
              onChange={(val) =>
                setSettings({ ...settings, max_size_chunk: val })
              }
            />
            <Text variant={"xSmall"} block>
              {t("SETTINGS.PROCESSING_CHUNK_MIN_TOKENS")}
            </Text>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 300, width: 300 }}>
            <Slider
              label={t('SETTINGS.MAXIMUN_TOKEN_PER_SENTENCE')}
              max={500}
              step={10}
              min={0}
              value={settings.max_sentence_tokens}
              showValue
              onChange={(val) =>
                setSettings({ ...settings, max_sentence_tokens: val })
              }
            />
            <Text variant={"xSmall"} block>
              {t("SETTINGS.SENTENCE_PREPEND_MAX_TOKENS")}
            </Text>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 800, width: 800 }}>
            <TextField
              value={settings.gpt_vision_system_prompt}
              onChange={(event) =>
                setSettings({
                  ...settings,
                  gpt_vision_system_prompt: event.currentTarget.value,
                })
              }
              label={t('SETTINGS.GPT_VISION_PROMT')}
              multiline
              rows={10}
            />
            <Text variant={"xSmall"} block>
              {t("SETTINGS.GPT_VISION_PROMT_DESCRIPTION")}
            </Text>
          </Stack.Item>

          <Stack.Item style={{ maxWidth: 800, width: 800 }}>
            <TextField
              value={settings.gpt_vision_user_prompt}
              onChange={(event) =>
                setSettings({
                  ...settings,
                  gpt_vision_user_prompt: event.currentTarget.value,
                })
              }
              label={t('SETTINGS.GPT_VISION_PROMT_USER')}
              multiline
              rows={10}
            />
            <Text variant={"xSmall"} block>
            {t("SETTINGS.GPT_VISION_PROMT_USER_DESCRIPTION")}
            </Text>
          </Stack.Item>
        </Stack>
        <Stack style={{ marginTop: 20 }}>
          <Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                onClick={saveSettings}
                text={saving ? `${t('MAIN.SAVING')}...` : t('MAIN.SAVE')}
                allowDisabledFocus
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default TrainingSettings;
