import { createContext, FC, useContext } from "react";
import AiServiceCatalogApiHttpService, {
  AiServiceCatalogApiHttpServiceContext,
  IAiServiceCatalogApiHttpService,
} from "./AiServiceCatalogApiHttpService";
import { IDocumentationResponse } from "../../Models/API/IDocumentationResponse";

export interface IAiIntegratedAppService {
  GetDocumentation(baseUrl: string, path: string): Promise<IDocumentationResponse | null>;
}

export const aiIntegratedAppServiceContext = createContext<IAiIntegratedAppService | undefined>(
  undefined
);

const AiIntegratedAppService: FC = ({ children }: any) => {
  const apiHttpService = useContext(AiServiceCatalogApiHttpServiceContext);

  const aiIntegratedAppService: IAiIntegratedAppService = {
    async GetDocumentation(baseUrl: string, path: string): Promise<IDocumentationResponse | null> {
      return await apiHttpService!.Get<IDocumentationResponse>(baseUrl, path);
    },
  };

  return (
    <aiIntegratedAppServiceContext.Provider value={aiIntegratedAppService}>
      {children}
    </aiIntegratedAppServiceContext.Provider>
  );
};

export default AiIntegratedAppService;
