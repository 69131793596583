import { FC, useContext, useEffect, useState } from 'react';
import { DialogServiceContext } from '../../../Services/Dialogs/DialogService';
import { SettingsServiceContext } from '../../../Services/API/SettingsService';
import ISettings from '../../../Models/API/ISettings';
import {
  ComboBox,
  Dropdown,
  IComboBoxOption,
  IDropdownOption,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
  Toggle,
} from '@fluentui/react';
import { chatAppServiceContext } from '../../../Services/API/ChatAppService';
import IChatApp from '../../../Models/API/IChatApp';
import { trackPageView } from '../../../Services/AppInsights';
import { useTranslation } from 'react-i18next';
import { UserServiceContext } from '../../../Services/API/UserService';
import MultipleLanguageSetting from '../../../Components/MultipleLanguageSetting/MultipleLangugeSetting';

const ChatJtiSettings: FC = () => {
  const settingsService = useContext(SettingsServiceContext);
  const dialogService = useContext(DialogServiceContext);
  const chatAppService = useContext(chatAppServiceContext);
  const userService = useContext(UserServiceContext);
  const [settings, setSettings] = useState<ISettings | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [chatApps, setChatApps] = useState<IChatApp[] | null>();

  const [languageOptions, setLanguageOptions] = useState<IDropdownOption[]>([]);

  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const execute = async () => {
      const apps = await chatAppService?.GetAll();
      if (isMounted) setChatApps(apps);
      var reply = await settingsService!.Get();
      if (isMounted) setSettings(reply);
      const languages = await userService!.GetAvailableLanguages();
      const langOptions = languages?.map(lang => ({
        key: lang.Code,
        text: lang.DisplayName,
      }));

      setLanguageOptions(langOptions!);
    };
    execute();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSettingsChange = (key: string, value: string) => {
    setSettings(prev => (prev ? { ...prev, [key]: value } : prev));
  };

  const saveSettings = async () => {
    setSaving(true);
    await settingsService?.Save(settings!);
    dialogService?.showSuccessDialog(
      t('SETTINGS.SETTINGS_SAVED'),
      t('SETTINGS.SETTINGS_SAVED_DESCRIPTION')
    );
    setSaving(false);
  };

  const options = (): IComboBoxOption[] => {
    let options = chatApps!.map(app => {
      return {
        key: app.Id,
        text: app.DisplayName,
      };
    });

    options = [
      {
        key: 0,
        text: t('MAIN.NONE'),
      },
      ...options,
    ];

    return options;
  };

  return (
    (settings && (
      <>
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack.Item style={{ maxWidth: 600 }}>
            <Toggle
              label={t('SETTINGS.SHOW_APP_SELECTION')}
              checked={settings.show_app_selection}
              onChange={(evt, checked) =>
                setSettings({ ...settings, show_app_selection: checked! })
              }
            />
            <small>{t('SETTINGS.SHOW_APP_SELECTION_DESCRIPTION')}</small>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 300, width: 300 }}>
            <ComboBox
              defaultSelectedKey={settings.chat_jti_app_id ?? 0}
              label={`Chat JTI ${t('MAIN.APPLICATION')}`}
              options={options()}
              onChange={(evt, option) => {
                setSettings({
                  ...settings,
                  chat_jti_app_id: parseInt(option!.key.toString()),
                });
              }}
              styles={{
                root: { maxWidth: 300 },
              }}
            />
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 300 }}>
            <TextField
              value={settings.chat_jti_app_title}
              onChange={event =>
                setSettings({
                  ...settings,
                  chat_jti_app_title: event.currentTarget.value,
                })
              }
              label={`Chat JTI ${t('MAIN.APPLICATION')} - ${t('MAIN.TITLE')}`}
            />
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 600 }}>
            <TextField
              value={settings.chat_jti_app_description}
              onChange={event =>
                setSettings({
                  ...settings,
                  chat_jti_app_description: event.currentTarget.value,
                })
              }
              label={`Chat JTI ${t('MAIN.APPLICATION')} - ${t('MAIN.DESCRIPTION')}`}
              multiline
              rows={4}
            />
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 300, width: 300 }}>
            <ComboBox
              defaultSelectedKey={settings.chat_jti_agent_app_id ?? 0}
              label={`Chat JTI ${t('MAIN.AGENT')} ${t('MAIN.APPLICATION')}`}
              options={options()}
              onChange={(evt, option) => {
                setSettings({
                  ...settings,
                  chat_jti_agent_app_id: parseInt(option!.key.toString()),
                });
              }}
              styles={{
                root: { maxWidth: 300 },
              }}
            />
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 300 }}>
            <TextField
              value={settings.chat_jti_agent_app_title}
              onChange={event =>
                setSettings({
                  ...settings,
                  chat_jti_agent_app_title: event.currentTarget.value,
                })
              }
              label={`Chat JTI ${t('MAIN.AGENT')} ${t('MAIN.APPLICATION')} -  ${t('MAIN.TITLE')}`}
            />
          </Stack.Item>
          <MultipleLanguageSetting
            baseKey='chat_jti_agent_app_description'
            label={`Chat JTI ${t('MAIN.AGENT')} ${t('MAIN.APPLICATION')} -  ${t(
              'MAIN.DESCRIPTION'
            )}`}
            languageOptions={languageOptions}
            settings={Object.fromEntries(
              Object.entries(settings).map(([key, value]) => [key, String(value)])
            )}
            onSettingsChange={handleSettingsChange}
            rows={4}
          />
          <MultipleLanguageSetting
            baseKey='chat_jti_disclaimer'
            label={`Chat JTI ${t('MAIN.DISCLAIMER')}`}
            languageOptions={languageOptions}
            settings={Object.fromEntries(
              Object.entries(settings).map(([key, value]) => [key, String(value)])
            )}
            onSettingsChange={handleSettingsChange}
            rows={10}
          />
          <MultipleLanguageSetting
            label={`Chat JTI ${t('MAIN.FOOTER')}`}
            baseKey='chat_jti_footer'
            languageOptions={languageOptions}
            rows={5}
            settings={Object.fromEntries(
              Object.entries(settings).map(([key, value]) => [key, String(value)])
            )}
            onSettingsChange={handleSettingsChange}
          />
          <MultipleLanguageSetting
            label={`Chat JTI ${t('MAIN.RELEASES')}`}
            baseKey='chat_jti_releases'
            languageOptions={languageOptions}
            rows={15}
            settings={Object.fromEntries(
              Object.entries(settings).map(([key, value]) => [key, String(value)])
            )}
            onSettingsChange={handleSettingsChange}
          />
          <MultipleLanguageSetting
            label={t('SETTINGS.NO_ACCESS_MESSAGE')}
            baseKey='no_access_message'
            languageOptions={languageOptions}
            rows={15}
            settings={Object.fromEntries(
              Object.entries(settings).map(([key, value]) => [key, String(value)])
            )}
            onSettingsChange={handleSettingsChange}
          />
        </Stack>
        <Stack style={{ marginTop: 20 }}>
          <Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                text={saving ? `${t('MAIN.SAVING')}...` : t('MAIN.SAVE')}
                readOnly={saving}
                allowDisabledFocus
                onClick={saveSettings}
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default ChatJtiSettings;
