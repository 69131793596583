import { FC } from 'react';

import { Link, Stack, StackItem, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

const Default: FC = () => {
  const { t } = useTranslation();

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <StackItem>
        <Text variant='superLarge'>{t('MAIN.API_REFERENCE')}</Text>
      </StackItem>
      <StackItem>
        <Text variant='xxLarge'>{t('API_REFERENCE.API')}</Text>
      </StackItem>
      <StackItem>
        <Text variant='large'>{t('API_REFERENCE.VERSION')} 0.1</Text>
      </StackItem>
      <StackItem>
        <Link href={`mailto:bogdan.ciungu@jti.com`}>{t('API_REFERENCE.MAIN_LINK')}</Link>
      </StackItem>
    </Stack>
  );
};

export default Default;
