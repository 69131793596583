import {
  CommandBar,
  DefaultButton,
  DetailsList,
  Dialog,
  DialogFooter,
  DialogType,
  IColumn,
  ICommandBarItemProps,
  Icon,
  IconButton,
  IContextualMenuProps,
  Link,
  PrimaryButton,
  SearchBox,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from "@fluentui/react";
import moment from "moment";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { SpLibraryServiceContext } from "../../../../../../Services/API/SpLibraryService";
import "./SPLibraries.scss";
import ConfigurationService from "../../../../../../Services/ConfigurationService";
import { removeDiacritics } from "../../../../../../Utilities/Strings";
import {
  getFileTypeIconProps,
  FileIconType,
} from "@fluentui/react-file-type-icons";
import IChatApp from "../../../../../../Models/API/IChatApp";
import { chatAppServiceContext } from "../../../../../../Services/API/ChatAppService";
import { trackPageView } from "../../../../../../Services/AppInsights";
import NewSPLibrary from "./New SPLibrary/New SPLibrary";
import ISpLibrary, {
  ISpLibraryKey,
} from "../../../../../../Models/API/ISpLibrary";
import { spoServiceContext } from "../../../../../../Services/API/SpoService";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { LanguageServiceContext } from "../../../../../../Services/LanguageService";

const shimmerColumns = (
  t: TFunction<"translation", undefined, "translation">
) =>
  [
    {
      key: "Icon",
      name: "",
      minWidth: 20,
      maxWidth: 20,
    },
    {
      key: "Title",
      name: t("MAIN.TITLE"),
      minWidth: 200,
    },
    {
      key: "NumDocuments",
      name: t("CATALOG.NUM_OF_DOCUMENTS"),
      minWidth: 200,
    },
    {
      key: "OwnerName",
      name: t("MAIN.OWNER"),
      minWidth: 200,
    },
    {
      key: "Created",
      name: t("MAIN.CREATED"),
      minWidth: 100,
    },
  ] satisfies IColumn[];

const SPLibraries: FC = () => {
  const navigate = useNavigate();
  const chatAppService = useContext(chatAppServiceContext);
  const spLibraryAppService = useContext(SpLibraryServiceContext);
  const spoService = useContext(spoServiceContext);
  const languageService = useContext(LanguageServiceContext);

  const { chatApp } = useOutletContext<{
    chatApp: IChatApp;
  }>();

  const [libraries, setLibraries] = useState<ISpLibrary[] | null>(null);
  const [filteredLibraries, setFilteredLibraries] = useState<
    ISpLibrary[] | null | undefined
  >(undefined);
  const [selectedLibraries, setSelectedLibraries] = useState<ISpLibrary[]>([]);
  const [commandBarButtons, setCommandBarButtons] = useState<
    ICommandBarItemProps[]
  >([]);
  const [hideDeleteDialog, setHideDeleteDialog] = useState<boolean>(true);
  const [hideNewDialog, setHideNewDialog] = useState<boolean>(true);
  const [filterKeyword, setFilterKeyword] = useState<string | undefined>(
    undefined
  );
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);

  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const existingLibrariesKeys: ISpLibraryKey[] | null = useMemo(() => {
    return filteredLibraries
      ? filteredLibraries.map((library) => ({
          LibraryId: library.LibraryId,
          SiteId: library.SiteId,
        }))
      : null;
  }, [filteredLibraries]);

  const refreshLibraries = () => {
    setIsRefresh(true);
    resetState();
    reloadLibraries();
    setTimeout(() => setIsRefresh(false), 0);
  };

  const onRenderItemColumn = (
    item: ISpLibrary,
    index?: number,
    column?: IColumn
  ): any => {
    if (column?.key === "Icon") {
      return (
        <Icon
          {...getFileTypeIconProps({
            type: FileIconType.folder,
            size: 20,
            imageFileType: "svg",
          })}
        />
      );
    } else if (column?.key === "Created") {
      return moment(item.Created).format("DD MMM yyyy");
    } else if (column?.key === "Title") {
      return (
        <Link onClick={() => navigateSomewhere(item.Id)}>{item.Title}</Link>
      );
    } else {
      return item![column?.key as keyof ISpLibrary];
    }
  };

  const navigateSomewhere = (libraryId: number) => {
    navigate(libraryId.toString(), {
      relative: "route",
    });
  };

  const reloadLibraries = async () => {
    setLibraries([]);
    setFilteredLibraries(undefined);
    let libs = await chatAppService!.GetChatAppSpLibraries(chatApp.Id);
    if (libs) {
      libs = libs!.sort((libA, libB) => libA.Title.localeCompare(libB.Title));
      setFilteredLibraries(libs);
      setLibraries(libs);
    }
  };

  const handleLibraryCreated = () => {
    resetState();
    reloadLibraries();
  };

  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${chatApp.DisplayName} SPLibraries`;
    if (isMounted && chatApp) reloadLibraries();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatApp]);

  // Runs when the selected libraries change
  useEffect(() => {
    let barButtons: ICommandBarItemProps[] = [];

    if (chatApp.PermissionType && chatApp.PermissionType !== "Read") {
      barButtons = [
        ...barButtons,
        {
          key: "new",
          text: t("MAIN.NEW"),
          iconProps: { iconName: "Add" },
          subMenuProps: {
            items: [
              {
                key: "library",
                text: t("MAIN.SP_LIBRARY"),
                iconProps: { iconName: "Folder" },
                onClick: (event, item) => {
                  setHideNewDialog(false);
                },
              },
            ],
          },
        },
      ];
    }
    barButtons = [
      ...barButtons,
      {
        key: "delete",
        text: t("MAIN.DELETE"),
        iconProps: { iconName: "Delete" },
        onClick: (event, item) => {
          setHideDeleteDialog(false);
        },
        disabled: selectedLibraries.length === 0,
      },
      {
        key: "refresh",
        text: t("MAIN.REFRESH"),
        iconProps: { iconName: "Refresh" },
        onClick: refreshLibraries,
      },
    ];

    setCommandBarButtons(barButtons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLibraries, languageService?.language]);

  const canSelectLibrary = (item: any, index?: number): boolean => {
    return (item as ISpLibrary).PermissionType === "Owner";
  };

  const selection = new Selection({
    canSelectItem: canSelectLibrary,
    onSelectionChanged: () => {
      setSelectedLibraries(selection.getSelection() as ISpLibrary[]);
    },
  });

  const resetState = (): void => {
    setHideDeleteDialog(true);
    setHideNewDialog(true);
    setLibraries([]);
    setFilteredLibraries(undefined);
    setSelectedLibraries([]);
    setFilterKeyword(undefined);
  };

  const deleteLibraries = async (): Promise<void> => {
    const promises: (Promise<void | null> | undefined)[] = [];
    const selectedLibs = [...selectedLibraries];
    resetState();
    for (let i = 0; i < selectedLibs.length; i++) {
      const lib = selectedLibs[i];
      promises.push(spLibraryAppService!.DeleteSpLibrary(lib.Id));
    }
    await Promise.allSettled(promises);

    setIsRefresh(true);
    reloadLibraries();
    setTimeout(() => setIsRefresh(false), 0);
  };

  // Runs when the filter keyword changes
  useEffect(() => {
    if (libraries && filterKeyword !== undefined) {
      setFilteredLibraries(
        filterKeyword
          ? libraries?.filter(
              (lib) =>
                removeDiacritics(lib.Title.toLowerCase()).indexOf(
                  removeDiacritics(filterKeyword.toLowerCase())
                ) !== -1
            )
          : libraries
      );
    }
  }, [filterKeyword, libraries]);

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0 }}>
        <Stack.Item>
          <CommandBar items={commandBarButtons} ariaLabel="Library actions" />
          <SearchBox
            value={filterKeyword}
            onChange={(evt, newValue) => setFilterKeyword(newValue)}
            placeholder={t("FILTERS.FILTER_BY_TITLE")}
            iconProps={{ iconName: "Filter" }}
            underlined={true}
          />
        </Stack.Item>
        <Stack.Item verticalFill style={{ minHeight: 0, overflowY: "auto" }}>
          <DetailsList
            className="libraries-list"
            setKey="items"
            items={filteredLibraries || []}
            columns={shimmerColumns(t)}
            selection={selection}
            selectionMode={SelectionMode.multiple}
            onRenderItemColumn={onRenderItemColumn}
            ariaLabelForGrid="Item details"
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            styles={{ root: { verticalAlign: "middle" } }}
          />
          {(filteredLibraries === undefined ||
            filteredLibraries?.length === 0) && (
            <>
              {filteredLibraries?.length === 0 && (
                <Text
                  variant="large"
                  block
                  style={{
                    textAlign: "center",
                    opacity: 0.5,
                  }}
                >
                  {t("TABLE.NO_LIBRARIES")}
                </Text>
              )}
              {filteredLibraries === undefined && (
                <Spinner size={SpinnerSize.large} />
              )}
            </>
          )}
        </Stack.Item>
      </Stack>
      <Dialog
        hidden={hideDeleteDialog}
        onDismiss={() => setHideDeleteDialog(true)}
        modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } } }}
        dialogContentProps={{
          type: DialogType.normal,
          title: t("CATALOG.DELETE_SP_LIBRARIES"),
          subText: t("CATALOG.DELETE_SP_LIBRARIES_CONFIRMATION"),
        }}
      >
        {selectedLibraries.map((lib) => {
          return (
            <Text key={lib.Id} block>
              {lib.Title}
            </Text>
          );
        })}
        <DialogFooter>
          <PrimaryButton onClick={deleteLibraries} text={t("MAIN.DELETE")} />
          <DefaultButton
            onClick={() => setHideDeleteDialog(true)}
            text={t("MAIN.CANCEL")}
          />
        </DialogFooter>
      </Dialog>
      {!hideNewDialog && (
        <NewSPLibrary
          exisitingLibrariesKeys={existingLibrariesKeys}
          isRefresh={isRefresh}
          setHideNewDialog={setHideNewDialog}
          onLibraryCreated={handleLibraryCreated}
        />
      )}
    </>
  );
};

export default SPLibraries;
