import { Link, Stack } from "@fluentui/react";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ConfigurationService from "../../../Services/ConfigurationService";
import "./Footer.scss";
import { maxWebsiteWidth } from "../../../Pages/Start/AppStart";
import { useTranslation } from "react-i18next";

const Footer: FC = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const contactSupport = (): void => {
    const mail =
      ConfigurationService.Default.Configuration.Contact.ContactSupportEmail;
    const subject =
      ConfigurationService.Default.Configuration.Contact.ContactSupportSubject;
    window.open(`mailto:${mail}?subject=${subject}`);
  };

  const { t } = useTranslation();

  return (
    <Stack
      style={{
        maxWidth: maxWebsiteWidth,
        width: "100%",
      }}
      horizontal
      tokens={{ padding: 16 }}
    >
      <Stack.Item grow>
        <Link
          target="_blank"
          onClick={contactSupport}
          className="ms-fontColor-themePrimary"
        >
          {t('MAIN.CONTACT_SUPPORT')}
        </Link>
      </Stack.Item>
      <Stack.Item align="end">
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Link
            style={{ textDecoration: "none !important" }}
            onClick={() => navigation("/api-reference", { replace: true })}
            className={`ms-fontColor-themePrimary ${
              location.pathname.startsWith("/api-reference") ? "active" : ""
            }`}
          >
            {t('MAIN.API_REFERENCE')}
          </Link>
          <Link
            style={{ textDecoration: "none !important" }}
            onClick={() => navigation("/versions", { replace: true })}
            className={`ms-fontColor-themePrimary ${
              location.pathname === "/versions" ? "active" : ""
            }`}
          >
            {t('MAIN.LATEST_RELEASE')}:{" "}
            {ConfigurationService.Default.Configuration.AppVersionDate} v
            {ConfigurationService.Default.Configuration.AppVersion}
          </Link>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export { Footer };
