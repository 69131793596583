import React, { FC, createContext, useContext } from "react";
import { ApiHttpServiceContext } from "./ApiHttpService";
import ILibraryPermission from "../../Models/API/ILibraryPermission";
import IUserPermission, {
  PermissionType,
} from "../../Models/API/IUserPermission";
import ISpLibrary from "../../Models/API/ISpLibrary";

export interface ISpLibraryService {
  Get(libraryId: number): Promise<ISpLibrary | null>;
  DeleteSpLibrary(spLibraryId: number): Promise<void | null>;
  GetPermissions(libraryId: number): Promise<ILibraryPermission[] | null>;
  CreatePermission(
    libraryId: number,
    principalId: string,
    type: PermissionType
  ): Promise<ILibraryPermission | null>;
  UpdatePermission(
    libraryId: number,
    principalId: string,
    type: PermissionType
  ): Promise<ILibraryPermission | null>;
  CheckUserPermissions(
    principalId: string,
    libraryId: number
  ): Promise<IUserPermission[] | null>;
  DeletePermission(
    libraryId: number,
    principalId: string
  ): Promise<void | null>;
}

export const SpLibraryServiceContext = createContext<ISpLibraryService | undefined>(
  undefined
);

const SpLibraryService: FC = ({ children }: any) => {
  const apiHttpService = useContext(ApiHttpServiceContext);
  const libraryController: string = "libraries";
  const spLibraryController: string = "splibraries";

  const libraryService: ISpLibraryService = {
    async Get(libraryId: number) {
      return await apiHttpService!.Get<ISpLibrary>(`${spLibraryController}/${libraryId}`);
    },

    async DeleteSpLibrary(spLibraryId: number): Promise<void | null> {
      return await apiHttpService!.Delete<void>(
        `${spLibraryController}/${spLibraryId}`
      );
    },

    // Permissions
    async GetPermissions(libraryId: number) {
      return await apiHttpService!.Get<ILibraryPermission[]>(
        `${libraryController}/${libraryId}/permissions`
      );
    },
    async CreatePermission(
      libraryId: number,
      principalId: string,
      type: PermissionType
    ) {
      return await apiHttpService!.Post<ILibraryPermission>(
        `${libraryController}/${libraryId}/permissions`,
        {
          PrincipalId: principalId,
          PermissionType: type,
        }
      );
    },
    async UpdatePermission(
      libraryId: number,
      principalId: string,
      type: PermissionType
    ) {
      return await apiHttpService!.Put<ILibraryPermission>(
        `${libraryController}/${libraryId}/permissions`,
        {
          PrincipalId: principalId,
          PermissionType: type,
        }
      );
    },
    async DeletePermission(libraryId: number, principalId: string) {
      await apiHttpService!.Delete<ILibraryPermission>(
        `${libraryController}/${libraryId}/permissions/${principalId}`
      );
    },
    async CheckUserPermissions(principalId: string, libraryId: number) {
      return await apiHttpService!.Get<IUserPermission[]>(
        `${libraryController}/${libraryId}/permissions/${principalId}`
      );
    },
  };

  return (
    <SpLibraryServiceContext.Provider value={libraryService}>
      {children}
    </SpLibraryServiceContext.Provider>
  );
};

export default SpLibraryService;
