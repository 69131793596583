import { FC, useContext, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import IAppCatalog from "../../../../Models/API/IAppCatalog";
import { catalogServiceContext } from "../../../../Services/API/CatalogService";
import PermissionManagement from "../../../../Components/PermissionManagement/PermissionManagement";
import IPermission from "../../../../Models/API/IPermission";
import { trackPageView } from "../../../../Services/AppInsights";
import ConfigurationService from "../../../../Services/ConfigurationService";
import { useTranslation } from "react-i18next";

const CatalogPermissions: FC = () => {
  const { catalog } = useOutletContext<{ catalog: IAppCatalog }>();
  const catalogsService = useContext(catalogServiceContext);
  
  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${catalog.DisplayName} Permissions`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _addPermission = async (objectId: string, permissionType: string) => {
    return await catalogsService!.CreatePermission(
      catalog.Id,
      objectId,
      permissionType as any
    );
  };

  const _deletePermission = async (objectId: string) => {
    await catalogsService!.DeletePermission(catalog.Id, objectId);
  };

  const _reloadPermissions = async () => {
    var perms = await catalogsService!.GetPermissions(catalog.Id);
    return perms as IPermission[];
  };

  const _checkPermissions = async (objectId: string) => {
    var perms = await catalogsService!.CheckUserPermissions(
      objectId,
      catalog.Id
    );
    return perms ?? [];
  };

  return (
    <PermissionManagement
      resourceType={"AppCatalog"}
      resourceId={catalog.Id}
      permissions={{
        Read: {
          DisplayName: t("PERMISSIONS.READ"),
          Description: t("PERMISSIONS.READ_DESCRIPTION"),
        },
        Write: {
          DisplayName: t("PERMISSIONS.WRITE"),
          Description: t("PERMISSIONS.WRITE_DESCRIPTION"),
        },
        FullControl: {
          DisplayName: t("PERMISSIONS.FULL_CONTROL"),
          Description: t("PERMISSIONS.FULL_CONTROL_DESCRIPTION"),
        },
        Owner: {
          DisplayName: t("PERMISSIONS.OWNER"),
          Description: t("PERMISSIONS.OWNER_DESCRIPTION"),
        },
      }}
      isOwner={catalog.PermissionType === "Owner"}
      addPermission={_addPermission}
      deletePermission={_deletePermission}
      reloadPermissions={_reloadPermissions}
      checkPermissions={_checkPermissions}
    />
  );
};

export default CatalogPermissions;
