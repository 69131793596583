import {
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { FC, useContext, useEffect, useState } from 'react';
import { SettingsServiceContext } from '../../../Services/API/SettingsService';
import ISettings from '../../../Models/API/ISettings';
import { DialogServiceContext } from '../../../Services/Dialogs/DialogService';
import moment from 'moment';
import { trackPageView } from '../../../Services/AppInsights';
import { useTranslation } from 'react-i18next';

const PromptV2Settings: FC = () => {
  const settingsService = useContext(SettingsServiceContext);
  const dialogService = useContext(DialogServiceContext);

  const [settings, setSettings] = useState<ISettings | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [currentKey, setCurrentKey] = useState<string>('search');

  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const execute = async () => {
      var reply = await settingsService!.Get();
      if (isMounted) setSettings(reply);
    };
    execute();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSettings = async () => {
    setSaving(true);
    await settingsService?.Save(settings!);
    dialogService?.showSuccessDialog(
      t('SETTINGS.SETTINGS_SAVED'),
      t('SETTINGS.SETTINGS_SAVED_DESCRIPTION')
    );
    setSaving(false);
  };

  return (
    (settings && (
      <>
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack.Item>
            <Pivot
              aria-label='Chat App Options'
              selectedKey={currentKey}
              onLinkClick={item => setCurrentKey(item?.props.itemKey!)}
            >
              <PivotItem
                itemKey='toolprompt'
                headerText={t('SETTINGS.TOOLS')}
                itemIcon='LightningBolt'
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.tool_system_prompt}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        tool_system_prompt: event.currentTarget.value,
                      })
                    }
                    label={t('SETTINGS.SYSTEM_PROMPT')}
                    multiline
                    rows={35}
                  />
                  <Text variant={'xSmall'} block>
                    {t('SETTINGS.DETECTING_BEST_TOOL')}
                    <br />
                    <br />
                    <strong>
                      {'{'}personality{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.PERSONALITY_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}attachments{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.ATTACHMENTS_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}current_date{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.CURRENT_DATE_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}name{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.NAME_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}office_location{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.OFFICE_LOCATION_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}job_title{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.JOB_TITLE_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}country{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.COUNTRY_DESCRIPTION')}
                  </Text>
                </Stack.Item>
              </PivotItem>

              <PivotItem
                itemKey='chitchatprompt'
                headerText={t('SETTINGS.CHIT_CHAT')}
                itemIcon='Chat'
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.chitchat_system_prompt}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        chitchat_system_prompt: event.currentTarget.value,
                      })
                    }
                    label={t('SETTINGS.SYSTEM_PROMPT')}
                    multiline
                    rows={35}
                  />
                  <Text variant={'xSmall'} block>
                    {t('SETTINGS.CHIT_CHAT_DESCRIPTION')}
                    <br />
                    <br />
                    <strong>
                      {'{'}personality{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.PERSONALITY_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}num_words{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.NUM_WORDS_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}current_date{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.CURRENT_DATE_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}name{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.NAME_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}office_location{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.OFFICE_LOCATION_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}job_title{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.JOB_TITLE_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}country{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.COUNTRY_DESCRIPTION')}
                  </Text>
                </Stack.Item>
              </PivotItem>

              <PivotItem
                itemKey='mainprompt'
                headerText={t('SETTINGS.GROUNDING')}
                itemIcon='SearchAndApps'
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.main_system_prompt}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        main_system_prompt: event.currentTarget.value,
                      })
                    }
                    label={t('SETTINGS.SYSTEM_PROMPT')}
                    multiline
                    rows={35}
                  />
                  <Text variant={'xSmall'} block>
                    {t('SETTINGS.GROUNDING_DESCRIPTION')}
                    <br />
                    <br />
                    <strong>
                      {'{'}personality{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.PERSONALITY_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}num_words{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.NUM_WORDS_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}current_date{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.CURRENT_DATE_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}name{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.NAME_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}office_location{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.OFFICE_LOCATION_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}job_title{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.JOB_TITLE_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}country{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.COUNTRY_DESCRIPTION')}
                  </Text>
                </Stack.Item>
              </PivotItem>

              <PivotItem
                itemKey='summarizedocument'
                headerText={t('SETTINGS.SUMMARIZE_DOCUMENT')}
                itemIcon='TextDocument'
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.summarizedocument_system_prompt}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        summarizedocument_system_prompt: event.currentTarget.value,
                      })
                    }
                    label={t('SETTINGS.SYSTEM_PROMPT')}
                    multiline
                    rows={35}
                  />
                  <Text variant={'xSmall'} block>
                    {t('SETTINGS.SUMMARIZE_DOCUMENT_DESCRIPTION')}
                    <br />
                    <br />
                    <strong>
                      {'{'}intent{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.INTENT_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}content{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.CONTENT_DESCRIPTION')}
                  </Text>
                </Stack.Item>
              </PivotItem>

              <PivotItem
                itemKey='finalprompt'
                headerText={t('SETTINGS.FINAL_PROMPT')}
                itemIcon='TextDocumentShared'
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.final_system_prompt}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        final_system_prompt: event.currentTarget.value,
                      })
                    }
                    label={t('SETTINGS.SYSTEM_PROMPT')}
                    multiline
                    rows={35}
                  />
                  <Text variant={'xSmall'} block>
                    {t('SETTINGS.FINAL_PROMPT_DESCRIPTION')}
                    <br />
                    <br />
                    <strong>
                      {'{'}personality{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.PERSONALITY_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}num_words{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.NUM_WORDS_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}current_date{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.CURRENT_DATE_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}name{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.NAME_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}office_location{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.OFFICE_LOCATION_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}job_title{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.JOB_TITLE_DESCRIPTION')}
                    <br />
                    <strong>
                      {'{'}country{'}'}
                    </strong>{' '}
                    - {t('SETTINGS.COUNTRY_DESCRIPTION')}
                  </Text>
                </Stack.Item>
              </PivotItem>
            </Pivot>
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                onClick={saveSettings}
                text={saving ? `${t('MAIN.SAVING')}...` : t('MAIN.SAVE')}
                allowDisabledFocus
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default PromptV2Settings;
