import { FC, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styles from "./Method.module.scss";
import ConfigurationService from "../../../Services/ConfigurationService";

import DocsMarkdown from "../DocsMarkdown/DocsMarkdown";

interface MethodProps {
  content?: string;
  host?: string;
}

const substitutePlaceholders = (markdown: string, placeholders: Record<string, string>): string => {
  return markdown.replace(/{{(.*?)}}/g, (_, key) => {
    return placeholders[key.trim()] || `{{${key}}}`;
  });
};

const substituteTypePlaceholders = async (markdown: string): Promise<string> => {
  const typePlaceholderRegex = /\[\[(.*?)\]\]/g;

  const matches = [...markdown.matchAll(typePlaceholderRegex)];

  for (const match of matches) {
    const typeName = match[1].trim();
    const filePath = `/docs/type_definitions/${typeName}.md`;

    try {
      const response = await fetch(filePath);
      if (!response.ok) throw new Error(`Type definition not found: ${typeName}`);
      const typeContent = await response.text();
      markdown = markdown.replace(match[0], typeContent);
    } catch (err) {
      console.warn(`Error fetching type definition for ${typeName}: ${err}`);
      markdown = markdown.replace(
        match[0],
        `<!-- Error: Type definition for "${typeName}" not found -->`
      );
    }
  }

  return markdown;
};

const preprocessMarkdown = async (
  markdown: string,
  host: string | undefined
): Promise<{ processedMarkdown: string; tabs: any[] }> => {
  if (host) placeholders.host = host;

  const tabsRegex = /:::tabs([\s\S]*?):::/g;

  const tabs: { id: string; content: { title: string; content: string }[] }[] = [];
  let index = 0;

  const withGeneralPlaceholders = substitutePlaceholders(markdown, placeholders);

  const withTabs = withGeneralPlaceholders.replace(tabsRegex, (_, tabsContent) => {
    const parsedTabs = tabsContent
      .split("@tab")
      .slice(1)
      .map((section: any) => {
        const [title, ...body] = section.split("\n");
        return { title: title.trim(), content: body.join("\n").trim() };
      });

    const id = `tabs_${index++}`;
    tabs.push({ id, content: parsedTabs });

    return `:::TabsPlaceholder ${id}:::`;
  });

  const withTypePlaceholders = await substituteTypePlaceholders(withTabs);

  return { processedMarkdown: withTypePlaceholders, tabs };
};

const placeholders = {
  host:
    ConfigurationService.Default.Configuration.API?.BaseUri?.replace("/api", "") ||
    "https://default-host.com",
  defaultVersion: "v1",
};

const Method: FC<MethodProps> = ({ content, host }) => {
  const location = useLocation();
  const { "*": path } = useParams();
  const [markdownContent, setMarkdownContent] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [processedMarkdown, setProcessedMarkdown] = useState<string>("");
  const [tabs, setTabs] = useState<any[]>([]);

  // Preprocess the Markdown content
  useEffect(() => {
    const processMarkdown = async () => {
      if (!markdownContent) return;

      const { processedMarkdown, tabs } = await preprocessMarkdown(markdownContent, host);
      setProcessedMarkdown(processedMarkdown);
      setTabs(tabs);
    };

    processMarkdown();
  }, [markdownContent]);

  useEffect(() => {
    const fetchMarkdown = async () => {
      if (!path) return;

      try {
        const markdownPath = `/docs/controllers/${path}.md`;
        const response = await fetch(markdownPath);
        if (!response.ok) throw new Error(`Failed to load: ${markdownPath}`);
        const content = await response.text();
        setMarkdownContent(content);
      } catch (err) {
        setError(`Documentation not found for: ${path}`);
      }
    };

    if (content) {
      setMarkdownContent(content);
    } else {
      fetchMarkdown();
    }
  }, [path, content]);

  useEffect(() => {
    if (location.hash) {
      const target = document.querySelector(location.hash.toLowerCase());
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  if (error) {
    return <div>{error}</div>;
  }

  return processedMarkdown ? (
    <section className={styles.container}>
      <DocsMarkdown content={processedMarkdown} tabs={tabs} />
    </section>
  ) : (
    <div>Loading documentation...</div>
  );
};

export default Method;
