import { Icon } from "@fluentui/react";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import { faRemove, faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, FC, useContext } from "react";
import "./ChatFiles.scss";
import { VALID_UPLOAD_FILE_EXTENSIONS } from "../../Pages/CatalogsEditor/Catalog/Applications/ChatApps/Libraries/Library/Documents/Documents";
import { DialogServiceContext } from "../../Services/Dialogs/DialogService";
import DocumentPicker, {
  IPickerDocument,
} from "../DocumentPicker/DocumentPicker";
import IChatApp from "../../Models/API/IChatApp";
import ConfigurationService from "../../Services/ConfigurationService";
import { useTranslation } from "react-i18next";

export interface IChatFilesProps {
  documents: IPickerDocument[];
  chatApp: IChatApp;
  onChange(documents: IPickerDocument[]): void;
  onRemove(document: IPickerDocument): void;
}

const ChatFiles: FC<IChatFilesProps> = ({
  documents,
  chatApp,
  onChange,
  onRemove,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const dialogService = useContext(DialogServiceContext);
  const [showDocumentPicker, setShowDocumentPicker] =
    React.useState<boolean>(false);

  const { t } = useTranslation();

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      let newFiles = new Array<File>();
      for (var i = 0; i < files.length; i++) {
        newFiles.push(files[i]);
      }
      if (
        newFiles.filter((file) => {
          const extension =
            file.name.split(".")[file.name.split(".").length - 1];
          return (
            VALID_UPLOAD_FILE_EXTENSIONS.indexOf(extension.toLowerCase()) === -1
          );
        }).length > 0
      ) {
        dialogService?.showWarningDialog(
          t('CHAT.DOCUMENT_UPLOAD'),
          t('CHAT.FILE_EXTENSION')
        );
      } else {
      }
      event.target.value = "";
    }
  };

  const _onSelectDocuments = (selectedDocuments: IPickerDocument[]) => {
    const newDocuments = [...documents, ...selectedDocuments].filter(
      (doc, index, self) =>
        self.findIndex((d) => d.id === doc.id && d.type === doc.type) === index
    );
    onChange(newDocuments);
    setShowDocumentPicker(false);
  };

  const fileName = (fileName: string) => {
    const nameLength = 30;
    return fileName.length > nameLength
      ? `${fileName.substring(0, nameLength)}...`
      : fileName;
  };

  const documentUrl = (document: IPickerDocument) => {
    if (document.type === "library") {
      return `${ConfigurationService.Default.Configuration.API?.BaseUri}/documents/${document.id}/open`;
    } else if (document.type === "user") {
      return `${ConfigurationService.Default.Configuration.API?.BaseUri}/users/documents/${document.id}/open`;
    }
  };

  return (
    <>
      <div className="files">
        {documents.map((document) => {
          return (
            <div key={`textbox_${document.type}_${document.id}`} className="file">
              <a
                className="file-container"
                title={document.name}
                href={documentUrl(document)}
                target="_blank"
                rel="noreferrer"
              >
                <div className="icon">
                  <Icon
                    {...getFileTypeIconProps({
                      extension:
                        document.name.split(".")[
                          document.name.split(".").length - 1
                        ],
                      size: 20,
                      imageFileType: "svg",
                    })}
                  />
                </div>
                <div className="file-name">{fileName(document.name)}</div>
              </a>
              <button
                title={t('LIBRARIES.REMOVE_DOCUMENT')}
                className="remove"
                onClick={() => onRemove(document)}
              >
                <FontAwesomeIcon icon={faRemove} size="lg" />
              </button>
            </div>
          );
        })}
        {documents.length < 5 && (
          <button className="more" onClick={() => setShowDocumentPicker(true)}>
            <span>
              <FontAwesomeIcon icon={faSquarePlus} size="lg" />
            </span>
            <span>{t('LIBRARIES.ADD_FILES')}</span>
          </button>
        )}
      </div>
      <input
        ref={inputRef}
        type="file"
        multiple={true}
        accept={VALID_UPLOAD_FILE_EXTENSIONS.map((ext) => `.${ext}`).join(",")}
        hidden={true}
        onChange={onFileChange}
      />
      {showDocumentPicker && (
        <DocumentPicker
          chatApp={chatApp}
          onClose={() => setShowDocumentPicker(false)}
          onSelect={_onSelectDocuments}
        />
      )}
    </>
  );
};

export default ChatFiles;
