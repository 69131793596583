import { createContext, FC, useEffect, useState } from 'react';
import { setMomentLocale } from '../Utilities/MomentLocalization';
import i18n from '../i18n';

interface LanguageContextProps {
  language: string;
  setLanguage: (lang: string) => void;
  optInAnalytics: boolean | null;
  setOptInAnalytics: (optIn: boolean) => void;
}

export const LanguageServiceContext = createContext<LanguageContextProps | undefined>(undefined);

const LanguageService: FC = ({ children }) => {
  const [privateLanguage, setPrivateLanguage] = useState<string>('en');
  const [language, setLanguage] = useState<string>('en');

  const [optInAnalytics, setOptInAnalytics] = useState<boolean | null>(null);
  const [privateOptInAnalytics, setPrivateOptInAnalytics] = useState<boolean | null>(null);

  useEffect(() => {
    const init = async () => {
      setMomentLocale(language);
      await i18n.changeLanguage(language);
      setPrivateLanguage(language);
    };

    init();
  }, [language]);

  useEffect(() => {
    const init = async () => {
      setPrivateOptInAnalytics(optInAnalytics);
    };

    init();
  }, [optInAnalytics]);

  return (
    <LanguageServiceContext.Provider
      value={{
        language: privateLanguage,
        setLanguage,
        optInAnalytics: privateOptInAnalytics,
        setOptInAnalytics,
      }}
    >
      {children}
    </LanguageServiceContext.Provider>
  );
};

export default LanguageService;
