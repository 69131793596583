import { Pivot, PivotItem, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { FC, useState } from 'react';
import { useLocation, Navigate, Outlet, useOutletContext } from 'react-router-dom';
import IChatApp from '../../../../../Models/API/IChatApp';
import IAutomateApp from '../../../../../Models/API/IAutomateApp';
import { useTranslation } from 'react-i18next';

export interface IAutomateAppProps {}

const AutomateApps: FC<IAutomateAppProps> = () => {
  const location = useLocation();
  const { baseApp: automateApp, setBaseApp: setAutomateApp } = useOutletContext<{
    baseApp: IAutomateApp;
    setBaseApp: (value: IChatApp) => void;
  }>();

  const [currentKey, setCurrentKey] = useState<string>(location.pathname.split('/')[6]);
  const { t } = useTranslation();

  return (
    <>
      <Stack tokens={{ childrenGap: 20 }} grow verticalFill style={{ minHeight: 0 }}>
        {automateApp ? (
          <>
            <Stack.Item>
              <Pivot
                aria-label='Automate App Options'
                selectedKey={currentKey}
                onLinkClick={item => setCurrentKey(item?.props.itemKey!)}
              >
                {automateApp.PermissionType !== undefined && (
                  <PivotItem itemKey='test' headerText={t("MAIN.TEST")} itemIcon='PowerApps'>
                    <Navigate to='test' replace />
                  </PivotItem>
                )}
                {(automateApp.PermissionType === 'Write' ||
                  automateApp.PermissionType === 'FullControl' ||
                  automateApp.PermissionType === 'Owner') && (
                  <PivotItem itemKey='integration' headerText={t("MAIN.INTEGRATION")} itemIcon='Settings'>
                    <Navigate to='integration' replace />
                  </PivotItem>
                )}
                {(automateApp.PermissionType === 'Write' ||
                  automateApp.PermissionType === 'FullControl' ||
                  automateApp.PermissionType === 'Owner') && (
                  <PivotItem itemKey='input' headerText={t("MAIN.INPUT_PARAMETERS")} itemIcon='Up'>
                    <Navigate to='input' replace />
                  </PivotItem>
                )}
                {(automateApp.PermissionType === 'Write' ||
                  automateApp.PermissionType === 'FullControl' ||
                  automateApp.PermissionType === 'Owner') && (
                  <PivotItem itemKey='output' headerText={t("MAIN.OUTPUT_PARAMETERS")} itemIcon='Down'>
                    <Navigate to='output' replace />
                  </PivotItem>
                )}
                {(automateApp.PermissionType === 'FullControl' ||
                  automateApp.PermissionType === 'Owner') && (
                  <PivotItem itemKey='permissions' headerText={t("PERMISSIONS.PERMISSIONS")} itemIcon='Permissions'>
                    <Navigate to='permissions' replace />
                  </PivotItem>
                )}
                {(automateApp.PermissionType === 'Write' ||
                  automateApp.PermissionType === 'FullControl' ||
                  automateApp.PermissionType === 'Owner') && (
                  <PivotItem itemKey='settings' headerText={t("MAIN.SETTINGS")} itemIcon='Settings'>
                    <Navigate to='settings' replace />
                  </PivotItem>
                )}
              </Pivot>
            </Stack.Item>
            <Stack.Item grow verticalFill style={{ minHeight: 0 }}>
              <Outlet context={{ automateApp, setAutomateApp }}></Outlet>
            </Stack.Item>
          </>
        ) : (
          <Spinner size={SpinnerSize.large} />
        )}
      </Stack>
    </>
  );
};

export default AutomateApps;
