import { FC, useContext, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import IPermission from '../../../../../../Models/API/IPermission';
import PermissionManagement from '../../../../../../Components/PermissionManagement/PermissionManagement';
import { trackPageView } from '../../../../../../Services/AppInsights';
import ConfigurationService from '../../../../../../Services/ConfigurationService';
import { aiToolAppServiceContext } from '../../../../../../Services/API/AiToolAppService';
import IAIToolApp from '../../../../../../Models/API/IAIToolApp';
import { useTranslation } from 'react-i18next';

const AiToolAppPermissions: FC = () => {
  const { aiToolApp } = useOutletContext<{
    aiToolApp: IAIToolApp;
  }>();
  const aiToolsAppsService = useContext(aiToolAppServiceContext);
  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${aiToolApp.DisplayName} Permissions`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _addPermission = async (objectId: string, permissionType: string) => {
    return await aiToolsAppsService!.CreatePermission(
      aiToolApp.Id,
      objectId,
      permissionType as any
    );
  };

  const _deletePermission = async (objectId: string) => {
    await aiToolsAppsService!.DeletePermission(aiToolApp.Id, objectId);
  };

  const _reloadPermissions = async () => {
    var perms = await aiToolsAppsService!.GetPermissions(aiToolApp.Id);
    return perms as IPermission[];
  };

  const _checkPermissions = async (objectId: string) => {
    var perms = await aiToolsAppsService!.CheckUserPermissions(objectId, aiToolApp.Id);
    return perms ?? [];
  };

  return (
    <PermissionManagement
      resourceType={'AutomateApp'}
      resourceId={aiToolApp.Id}
      permissions={{
        Read: {
          DisplayName: t("PERMISSIONS.READ"),
          Description: t("PERMISSIONS.READ_DESCRIPTION_2"),
        },
        Write: {
          DisplayName: t("PERMISSIONS.WRITE"),
          Description: t("PERMISSIONS.WRITE_DESCRIPTION_4"),
        },
        FullControl: {
          DisplayName: t("PERMISSIONS.FULL_CONTROL"),
          Description: t("PERMISSIONS.FULL_CONTROL_DESCRIPTION_4"),
        },
        Owner: {
          DisplayName: t("PERMISSIONS.OWNER"),
          Description: t("PERMISSIONS.OWNER_DESCRIPTION_4"),
        },
      }}
      isOwner={aiToolApp.PermissionType === 'Owner'}
      addPermission={_addPermission}
      deletePermission={_deletePermission}
      reloadPermissions={_reloadPermissions}
      checkPermissions={_checkPermissions}
    />
  );
};

export default AiToolAppPermissions;
