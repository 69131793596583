import {
  DefaultButton,
  FontWeights,
  IButtonStyles,
  IconButton,
  IDropdownOption,
  IIconProps,
  Label,
  mergeStyleSets,
  Modal,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { FC, useContext, useEffect, useState } from 'react';
import { SettingsServiceContext } from '../../../Services/API/SettingsService';
import ISettings from '../../../Models/API/ISettings';
import { DialogServiceContext } from '../../../Services/Dialogs/DialogService';
import { trackPageView } from '../../../Services/AppInsights';
import { analyticsServiceContext } from '../../../Services/API/AnalyticsService';
import AnalyticsJobs from '../../../Components/AnalyticsJobs/AnalyticsJobs';
import { AppCatalogServiceContext } from '../../../Services/AppCatalogService';
import { useTranslation } from 'react-i18next';
import MultipleLanguageSetting from '../../../Components/MultipleLanguageSetting/MultipleLangugeSetting';
import { UserServiceContext } from '../../../Services/API/UserService';

enum Action {
  None,
  StartAnalytics,
  PurgeAll,
}

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const AnalyticsSettings: FC = () => {
  const settingsService = useContext(SettingsServiceContext);
  const analyticsService = useContext(analyticsServiceContext);
  const dialogService = useContext(DialogServiceContext);
  const userService = useContext(UserServiceContext);
  const [settings, setSettings] = useState<ISettings | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [action, setAction] = useState<Action>(Action.None);
  const [executingJob, setExecutingJob] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [viewJobs, setViewJobs] = useState<boolean>(false);
  const appCatalogService = useContext(AppCatalogServiceContext);
  const theme = appCatalogService!.GetCurrentTheme();

  const [currentKey, setCurrentKey] = useState<string>('settings');
  const [languageOptions, setLanguageOptions] = useState<IDropdownOption[]>([]);

  const { t } = useTranslation();

  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      width: '60%',
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.themePrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
        boxSizing: 'border-box',
      },
    ],
    heading: {
      color: theme.palette.themePrimary,
      fontWeight: FontWeights.semibold,
      fontSize: 'inherit',
      margin: '0',
    },
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'auto',
      boxSizing: 'border-box',
      maxHeight: '80vh',
      width: '60vw',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });

  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const execute = async () => {
      var reply = await settingsService!.Get();
      if (isMounted) setSettings(reply);
      const languages = await userService!.GetAvailableLanguages();
      const langOptions = languages?.map(lang => ({
        key: lang.Code,
        text: lang.DisplayName,
      }));

      setLanguageOptions(langOptions!);
    };
    execute();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSettings = async () => {
    setSaving(true);
    await settingsService?.Save(settings!);
    dialogService?.showSuccessDialog(
      t('SETTINGS.SETTINGS_SAVED'),
      t('SETTINGS.SETTINGS_SAVED_DESCRIPTION')
    );
    setSaving(false);
  };

  const startAnalytics = async () => {
    setExecutingJob(true);
    await analyticsService?.StartAnalytics();
    setExecutingJob(false);
  };

  const purgeAll = async () => {
    setExecutingJob(true);
    await analyticsService?.PurgeAllConversations();
    setExecutingJob(false);
  };

  const handleSettingsChange = (key: string, value: string) => {
    setSettings(prev => (prev ? { ...prev, [key]: value } : prev));
  };

  const executeAction = async (action: Action) => {
    switch (action) {
      case Action.StartAnalytics:
        await startAnalytics();
        break;
      case Action.PurgeAll:
        await purgeAll();
        break;
    }
    setAction(Action.None);
  };

  useEffect(() => {
    if (action !== Action.None) {
      switch (action) {
        case Action.StartAnalytics:
          dialogService?.showPromptDialog(
            t('SETTINGS.START_ANALYTICS'),
            t('SETTINGS.START_ANALYTICS_CONFIRMARION'),
            () => {
              executeAction(action);
            },
            () => {
              setAction(Action.None);
            }
          );
          break;
        case Action.PurgeAll:
          dialogService?.showPromptDialog(
            t('SETTINGS.PURGE_ALL'),
            t('SETTINGS.PURGE_ALL_CONFIRMATION'),
            () => {
              dialogService?.showPromptDialog(
                t('SETTINGS.PURGE_ALL'),
                t('SETTINGS.PURGE_ALL_CONFIRMATION_2'),
                () => {
                  executeAction(action);
                },
                () => {
                  setAction(Action.None);
                }
              );
            },
            () => {
              setAction(Action.None);
            }
          );
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return (
    (settings && (
      <>
        <Pivot
          aria-label='Chat App Options'
          selectedKey={currentKey}
          onLinkClick={item => setCurrentKey(item?.props.itemKey!)}
        >
          <PivotItem itemKey='settings' headerText={t('SETTINGS.SETTINGS')} itemIcon='Info'>
            <MultipleLanguageSetting
              label={t('SETTINGS.OPT_INT_MESSAGE')}
              baseKey='opt_into_dialog_message'
              languageOptions={languageOptions}
              rows={5}
              settings={Object.fromEntries(
                Object.entries(settings).map(([key, value]) => [key, String(value)])
              )}
              onSettingsChange={handleSettingsChange}
              description={t('SETTINGS.OPT_INT_MESSAGE_DESCRIPTION')}
            />

            <Stack tokens={{ childrenGap: 40 }}>
              <Stack.Item style={{ maxWidth: 600, width: 600, marginTop: 20 }}>
                <Label>{t('SETTINGS.MAXIMUM_CONVERSATION_AGE_IN_DAYS')}</Label>
                <TextField
                  styles={{ root: { width: 100 } }}
                  value={settings.analytics_days_until_latest_run?.toString()}
                  onChange={event =>
                    setSettings({
                      ...settings,
                      analytics_days_until_latest_run: parseInt(event.currentTarget.value),
                    })
                  }
                  type='number'
                />
                <Text variant={'xSmall'} block>
                  {t('SETTINGS.MAXIMUM_CONVERSATION_AGE_IN_DAYS_DESCRIPTION')}
                </Text>
              </Stack.Item>

              <Stack.Item>
                <DefaultButton
                  style={{ marginRight: 10 }}
                  onClick={() => setAction(Action.StartAnalytics)}
                  text={executingJob ? `${t('MAIN.EXECUTING')}...` : t('SETTINGS.START_ANALYTICS')}
                  allowDisabledFocus
                />
                <Text variant={'xSmall'}>{t('SETTINGS.START_THE_ANALYTICS_TIMER')}</Text>
              </Stack.Item>

              <Stack.Item>
                <DefaultButton
                  style={{ marginRight: 10 }}
                  onClick={() => setAction(Action.PurgeAll)}
                  text={executingJob ? `${t('MAIN.EXECUTING')}...` : t('SETTINGS.PURGE_ALL')}
                  allowDisabledFocus
                />
                <Text variant={'xSmall'}>{t('SETTINGS.PURGE_ALL_DESCRIPTION')}</Text>
              </Stack.Item>
            </Stack>
          </PivotItem>
          <PivotItem itemKey='prompts' headerText={t('SETTINGS.PROMPTS')} itemIcon='LightningBolt'>
            <Stack.Item style={{ maxWidth: 600, width: 600, marginTop: 20 }}>
              <TextField
                value={settings.analytics_topics_prompt}
                onChange={event =>
                  setSettings({
                    ...settings,
                    analytics_topics_prompt: event.currentTarget.value,
                  })
                }
                label={t('SETTINGS.ANALYTICS_PROMPT')}
                multiline
                rows={15}
              />
              <Text variant={'xSmall'} block>
                {t('SETTINGS.ANALYTICS_PROMPT_DESCRIPTION')}
                <br />
                <strong>
                  {'{'}
                  {'{'}conversation_history{'}'}
                  {'}'}
                </strong>{' '}
                - {t('SETTINGS.ANALYTICS_CONVERSATION_HISTORY_DESCRIPTION')}
                <br />
                <strong>
                  {'{'}
                  {'{'}topics{'}'}
                  {'}'}
                </strong>{' '}
                - {t('SETTINGS.TOPICS_DESCRIPTION')}
              </Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: 600, width: 600, marginTop: 20 }}>
              <TextField
                value={settings.analytics_anonymization_prompt}
                onChange={event =>
                  setSettings({
                    ...settings,
                    analytics_anonymization_prompt: event.currentTarget.value,
                  })
                }
                label={t('SETTINGS.ANONYMIZATION_PROMPT')}
                multiline
                rows={15}
              />
              <Text variant={'xSmall'} block>
                {t('SETTINGS.ANONYMIZATION_PROMPT_DESCRIPTION')}
                <br />
                <strong>
                  {'{'}
                  {'{'}conversation_history{'}'}
                  {'}'}
                </strong>{' '}
                - {t('SETTINGS.ANALYTICS_CONVERSATION_HISTORY_DESCRIPTION')}
              </Text>
            </Stack.Item>
          </PivotItem>
        </Pivot>

        <Stack style={{ marginTop: 20 }}>
          <Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                onClick={saveSettings}
                text={saving ? `${t('MAIN.SAVING')}...` : t('MAIN.SAVE')}
                allowDisabledFocus
              />
              <DefaultButton
                onClick={() => setViewJobs(true)}
                text={t('SETTINGS.VIEW_JOBS')}
                allowDisabledFocus
              />
            </Stack>
          </Stack.Item>
        </Stack>

        <Modal isOpen={viewJobs} onDismiss={() => setViewJobs(false)} isBlocking={false}>
          <div className={contentStyles.header}>
            <h2 className={contentStyles.heading}>{t('SETTINGS.LATEST_ANALYTICS_JOBS')}</h2>
            <IconButton
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel='Close popup modal'
              onClick={() => setViewJobs(false)}
            />
          </div>
          <div className={contentStyles.body}>
            <AnalyticsJobs />
          </div>
        </Modal>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default AnalyticsSettings;
