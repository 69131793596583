import { FC, useContext, useEffect, useState } from "react";
import IAutomateApp, {
  IAutomateAppParameterType,
} from "../../Models/API/IAutomateApp";
import {
  MessageBar,
  MessageBarType,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import {
  AutomateRunReturn,
  automateAppServiceContext,
} from "../../Services/API/AutomateAppService";
import { AxiosError } from "axios";
import InputParams from "./InputParams/InputParams";
import OutputParams from "./OutputParams/OutputParams";
import GroupToggle, { IGroupToggleOption } from "../GroupToggle/GroupToggle";
import { useTranslation } from "react-i18next";

export interface IAutomateRunProps {
  automateApp: IAutomateApp;
  showDescription?: boolean;
}

export interface IFormData {
  internalName: string;
  type: IAutomateAppParameterType;
  value: any;
}

const AutomateRun: FC<IAutomateRunProps> = ({
  automateApp,
  showDescription = false,
}) => {
  var automateAppService = useContext(automateAppServiceContext);

  const [formData, setFormData] = useState<IFormData[]>([]);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const [response, setResponse] = useState<AutomateRunReturn | null>();
  const [canExecute, setCanExecute] = useState<boolean>(false);
  const [executing, setExecuting] = useState<boolean>(false);

  const [currentKey, setCurrentKey] = useState<string>("input");
  const [toggleOptions, setToggleOptions] = useState<IGroupToggleOption[]>([]);
  const { t } = useTranslation();

  const runAutomateApp = async () => {
    setErrorMsg(undefined);
    setResponse(null);
    setExecuting(true);
    const tempFormData = new FormData();
    for (let data of formData) {
      switch (data.type) {
        case "Number":
        case "String":
        case "Boolean":
          tempFormData.set(data.internalName, data.value);
          break;
        case "Array":
          for (let value of data.value) {
            tempFormData.append(data.internalName, value);
          }
          break;
        case "File":
          tempFormData.set(data.internalName, data.value);
          break;
      }
    }

    try {
      const response = await automateAppService?.Run(
        automateApp.Id,
        tempFormData
      );
      setResponse(response);
      setCurrentKey("output");
    } catch (error) {
      let msg = "An error has occurred executing the Automate App";
      if ((error as AxiosError).response?.data) {
        msg = ((error as AxiosError).response?.data as any).title;
      }
      setErrorMsg(msg);
    }
    setExecuting(false);
  };

  useEffect(() => {
    setErrorMsg(undefined);
    if (automateApp && automateApp.InputParameters) {
      const required = automateApp.InputParameters.filter((x) => x.Required);

      const missingValues = required.filter((x) => {
        return !formData.find((y) => x.InternalName === y.internalName);
      });

      setCanExecute(missingValues.length === 0);
    }
  }, [formData, automateApp]);

  useEffect(() => {
    const options = (): IGroupToggleOption[] => {
      return [
        {
          key: "input",
          iconName: "Up",
          title: t("MAIN.INPUT"),
        },
        {
          key: "output",
          iconName: "Down",
          title: t("MAIN.OUTPUT"),
          locked: !response,
        },
      ];
    };
    setToggleOptions(options);
  }, [response]);

  return (
    <>
      <Stack
        style={{ minHeight: 0, overflowY: "auto" }}
        verticalFill
        grow
        tokens={{ childrenGap: 20 }}
      >
        {automateApp.InputParameters ? (
          <>
            {showDescription && (
              <StackItem align="start">
                <Text variant="mediumPlus">{automateApp.Description}</Text>
              </StackItem>
            )}
            <StackItem align="center">
              <GroupToggle
                onChange={(key) => setCurrentKey(key)}
                activeKey={currentKey}
                options={toggleOptions}
              ></GroupToggle>
            </StackItem>
            <Stack
              grow
              tokens={{ childrenGap: 20 }}
              style={{ display: currentKey === "input" ? "block" : "none" }}
            >
              <InputParams
                inputParams={automateApp.InputParameters}
                formData={formData}
                executing={executing}
                canExecute={canExecute}
                setFormData={setFormData}
                runAutomateApp={runAutomateApp}
              />
              {errorMsg ? (
                <Stack grow verticalAlign="center" horizontalAlign="center">
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                  >
                    {errorMsg}
                  </MessageBar>
                </Stack>
              ) : (
                <></>
              )}
            </Stack>

            <Stack
              grow
              style={{ display: currentKey === "output" ? "block" : "none" }}
            >
              <OutputParams
                appName={automateApp.InternalName}
                outputParameters={automateApp.OutputParameters}
                response={response}
              />
            </Stack>
          </>
        ) : (
          <Stack grow verticalAlign="center" horizontalAlign="center">
            <Text variant={"large"}>{t("TABLE.NO_PARAMETERS_DEFINED")}</Text>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default AutomateRun;
