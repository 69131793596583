import { FC, useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  ComboBox,
  DefaultButton,
  IComboBoxStyles,
  PrimaryButton,
  Slider,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
  TextField,
  Toggle,
} from '@fluentui/react';
import IChatApp from '../../../../../../Models/API/IChatApp';
import { chatAppServiceContext } from '../../../../../../Services/API/ChatAppService';
import styles from './Settings.module.scss';
import { modelOptions } from '../NewChatApp';
import Role from '../../../../../../Components/Role/Role';
import { trackPageView } from '../../../../../../Services/AppInsights';
import { DialogServiceContext } from '../../../../../../Services/Dialogs/DialogService';
import ConfigurationService from '../../../../../../Services/ConfigurationService';
import AuthenticationService from '../../../../../../Services/AuthenticationService';
import { analyticsServiceContext } from '../../../../../../Services/API/AnalyticsService';
import { useTranslation } from 'react-i18next';

enum Action {
  None,
  StartAnalytics,
  PurgeAll,
}

const comboBoxStyles: Partial<IComboBoxStyles> = {
  root: { maxWidth: 300 },
  optionsContainer: { width: 300 },
  label: { overflow: 'visible' },
};

const ChatAppSettings: FC = () => {
  const dialogService = useContext(DialogServiceContext);
  const chatAppService = useContext(chatAppServiceContext);
  const analyticsService = useContext(analyticsServiceContext);

  const { chatApp, setChatApp } = useOutletContext<{
    chatApp: IChatApp;
    setChatApp: (value: IChatApp) => void;
  }>();

  const [tempChatApp, setTempChatApp] = useState<IChatApp>(chatApp);
  const [saving, setIsSaving] = useState<boolean>(false);

  const [action, setAction] = useState<Action>(Action.None);
  const [executingJob, setExecutingJob] = useState<boolean>(false);

  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${chatApp.DisplayName} Settings`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateChatApp = async () => {
    if (tempChatApp && !saving) {
      setIsSaving(true);
      var refreshedChatApp = await chatAppService?.Update(tempChatApp!.Id, {
        Model: tempChatApp.Model,
        DisplayName: tempChatApp.DisplayName,
        Description: tempChatApp.Description,
        SystemPrompt: tempChatApp.SystemPrompt,
        NoAnswerMessage: tempChatApp.NoAnswerMessage,
        MaxTokens: tempChatApp.MaxTokens,
        Temperature: tempChatApp.Temperature,
        PresencePenalty: tempChatApp.PresencePenalty,
        TopP: tempChatApp.TopP,
        FrequencyPenalty: tempChatApp.FrequencyPenalty,
        LibraryIds: tempChatApp.LibraryIds,
        AllowModelSources: tempChatApp.AllowModelSources,
        TopResults: tempChatApp.TopResults,
        EnableHybridSearch: tempChatApp.EnableHybridSearch,
        EnableDensitySearch: tempChatApp.EnableDensitySearch,
        EnableModelReRank: tempChatApp.EnableModelReRank,
        EnableLibraryReferences: tempChatApp.EnableLibraryReferences,
        EnableAttachments: tempChatApp.EnableAttachments,
        ConversationTimeout: tempChatApp.ConversationTimeout,
        Disclaimer: tempChatApp.Disclaimer,
        EnableAnalytics: tempChatApp.EnableAnalytics,
        HideSources: tempChatApp.HideSources,
      });
      if (refreshedChatApp !== null) {
        setChatApp(refreshedChatApp!);
        setTempChatApp(refreshedChatApp!);
        dialogService?.showSuccessDialog(
          refreshedChatApp!.DisplayName,
          t('SETTINGS.SETTINGS_SAVED_DESCRIPTION')
        );
      }
      setIsSaving(false);
    }
  };

  const maxTopResultsByModel = (model: string): number => {
    return model === 'Gpt4' ? 5 : model === 'Gpt3516K' ? 10 : model === 'Gpt432K' ? 15 : 20;
  };

  const startAnalytics = async () => {
    setExecutingJob(true);
    await analyticsService?.StartChatAppAnalytics(chatApp.Id);
    setExecutingJob(false);
  };

  const purgeAll = async () => {
    setExecutingJob(true);
    await analyticsService?.PurgeAllChatAppConversations(chatApp.Id);
    setExecutingJob(false);
  };

  const executeAction = async (action: Action) => {
    switch (action) {
      case Action.StartAnalytics:
        await startAnalytics();
        break;
      case Action.PurgeAll:
        await purgeAll();
        break;
    }
    setAction(Action.None);
  };

  useEffect(() => {
    if (action !== Action.None) {
      switch (action) {
        case Action.StartAnalytics:
          dialogService?.showPromptDialog(
            t('SETTINGS.START_ANALYTICS'),
            t('SETTINGS.START_ANALYTICS_CONFIRMARION'),
            () => {
              executeAction(action);
            },
            () => {
              setAction(Action.None);
            }
          );
          break;
        case Action.PurgeAll:
          dialogService?.showPromptDialog(
            t('SETTINGS.PURGE_ALL'),
            t('SETTINGS.PURGE_ALL_CONFIRMATION'),
            () => {
              dialogService?.showPromptDialog(
                t('SETTINGS.PURGE_ALL'),
                t('SETTINGS.PURGE_ALL_CONFIRMATION_2'),
                () => {
                  executeAction(action);
                },
                () => {
                  setAction(Action.None);
                }
              );
            },
            () => {
              setAction(Action.None);
            }
          );
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return (
    (tempChatApp && (
      <>
        <Stack
          verticalFill
          style={{ minHeight: 0, overflowY: 'auto' }}
          tokens={{ childrenGap: 20 }}
        >
          <Stack.Item>
            <TextField
              required={true}
              label={t('MAIN.TITLE')}
              resizable={false}
              value={tempChatApp.DisplayName}
              onChange={(event, newValue) =>
                setTempChatApp({
                  ...tempChatApp,
                  DisplayName: newValue!,
                })
              }
            />
            <Text variant='xSmall'>{t('MAIN.MIN_CHARACTERS', { count: 3 })}</Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              required={true}
              label={t('MAIN.DESCRIPTION')}
              multiline
              rows={3}
              resizable={false}
              value={tempChatApp.Description}
              onChange={(event, newValue) =>
                setTempChatApp({
                  ...tempChatApp,
                  Description: newValue!,
                })
              }
            />
            <Text variant='xSmall'>{t('MAIN.MIN_CHARACTERS', { count: 20 })}</Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              label={t('SETTINGS.SYSTEM_PROMPT')}
              multiline
              rows={4}
              resizable={true}
              value={tempChatApp.SystemPrompt}
              onChange={(event, newValue) =>
                setTempChatApp({
                  ...tempChatApp,
                  SystemPrompt: newValue!,
                })
              }
            />
            <Text variant={'xSmall'}>{t('SETTINGS.SYSTEM_PROMPT_DESCRIPTION')}</Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              label={t('SETTINGS.NO_ANSWER_MESSAGE')}
              multiline
              rows={2}
              resizable={true}
              value={tempChatApp.NoAnswerMessage}
              onChange={(event, newValue) =>
                setTempChatApp({
                  ...tempChatApp,
                  NoAnswerMessage: newValue!,
                })
              }
            />
            <Text variant={'xSmall'}>{t('SETTINGS.NO_ANSWER_MESSAGE_DESCRIPTION_2')}</Text>
          </Stack.Item>
          <Stack wrap horizontal>
            <StackItem>
              <Text variant='large'>{t('CATALOG.CHAT_EXPERIENCE')}</Text>
            </StackItem>
          </Stack>
          <Stack.Item>
            <TextField
              label={t('MAIN.DISCLAIMER')}
              multiline
              rows={2}
              resizable={true}
              value={tempChatApp.Disclaimer ?? ''}
              onChange={(event, newValue) =>
                setTempChatApp({
                  ...tempChatApp,
                  Disclaimer: newValue!,
                })
              }
            />
            <Text variant={'xSmall'}>{t('CATALOG.DISCLAIMER_DESCRIPTION')}</Text>
          </Stack.Item>
          <Stack wrap horizontal style={{ marginLeft: -10, width: '100%' }}>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Slider
                label={t('CATALOG.CONVERSATION_TIMEOUT')}
                max={24}
                step={1}
                min={1}
                value={tempChatApp!.ConversationTimeout}
                showValue
                onChange={val => setTempChatApp({ ...tempChatApp, ConversationTimeout: val })}
              />
              <Text variant='xSmall'>{t('CATALOG.CONVERSATION_TIMEOUT_DESCRIPTION')}</Text>
            </Stack.Item>
          </Stack>
          <Stack wrap horizontal style={{ marginLeft: -10 }}>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Toggle
                label={t('CATALOG.ENABLE_LIBRARY_REFERENCES')}
                checked={tempChatApp!.EnableLibraryReferences}
                onChange={(evt, checked) =>
                  setTempChatApp({
                    ...tempChatApp,
                    EnableLibraryReferences: checked!,
                  })
                }
              />
              <Text variant={'xSmall'} block>
                {t('CATALOG.ENABLE_LIBRARY_REFERENCES_DESCRIPTION')}
              </Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Toggle
                label={t('CATALOG.ENABLE_ATTACHMENTS')}
                checked={tempChatApp!.EnableAttachments}
                onChange={(evt, checked) =>
                  setTempChatApp({
                    ...tempChatApp,
                    EnableAttachments: checked!,
                  })
                }
              />
              <Text variant={'xSmall'} block>
                {t('CATALOG.ENABLE_ATTACHMENTS_DESCRIPTION')}
              </Text>
            </Stack.Item>
          </Stack>
          <Stack wrap horizontal>
            <StackItem>
              <Text variant='large'>{t('CATALOG.GENERATION')}</Text>
            </StackItem>
          </Stack>
          <Stack wrap horizontal style={{ marginLeft: -10 }}>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Slider
                label={t('CATALOG.MAX_TOKENS')}
                max={2000}
                step={100}
                min={0}
                value={tempChatApp!.MaxTokens}
                showValue
                onChange={val => setTempChatApp({ ...tempChatApp, MaxTokens: val })}
              />
              <Text variant='xSmall'>{t('CATALOG.MAX_TOKENS_DESCRIPTION')}</Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Slider
                label={t('CATALOG.TEMPERATURE')}
                max={2}
                step={0.01}
                min={0}
                value={tempChatApp!.Temperature}
                showValue
                onChange={val => setTempChatApp({ ...tempChatApp, Temperature: val })}
              />
              <Text variant='xSmall'>{t('CATALOG.TEMPERATURE_DESCRIPTION')}</Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Slider
                label={t('CATALOG.TOP_P')}
                max={1}
                step={0.01}
                min={0}
                value={tempChatApp!.TopP}
                showValue
                onChange={val => setTempChatApp({ ...tempChatApp, TopP: val })}
              />
              <Text variant='xSmall'>{t('CATALOG.TOP_P_DESCRIPTION')}</Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Slider
                label={t('CATALOG.PRECENCE_PENALTY')}
                max={2}
                step={0.01}
                min={-2}
                value={tempChatApp!.PresencePenalty}
                showValue
                onChange={val => setTempChatApp({ ...tempChatApp, PresencePenalty: val })}
              />
              <Text variant='xSmall'>{t('CATALOG.PRECENCE_PENALTY_DESCRIPTION')}</Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Slider
                label={t('CATALOG.FREQUENCY_PENALTY')}
                max={2}
                step={0.01}
                min={-2}
                value={tempChatApp!.FrequencyPenalty}
                showValue
                onChange={val => setTempChatApp({ ...tempChatApp, FrequencyPenalty: val })}
              />
              <Text variant='xSmall'>{t('CATALOG.FREQUENCY_PENALTY_DESCRIPTION')}</Text>
            </Stack.Item>
          </Stack>
          <Stack wrap horizontal>
            <StackItem>
              <Text variant='large'>{t('SETTINGS.SEARCH')}</Text>
            </StackItem>
          </Stack>
          <Stack wrap horizontal style={{ marginLeft: -10 }}>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Slider
                label={t('CATALOG.TOP_RESULTS')}
                max={maxTopResultsByModel(tempChatApp.Model)}
                step={1}
                min={1}
                value={tempChatApp!.TopResults}
                showValue
                onChange={val => {
                  setTempChatApp({ ...tempChatApp, TopResults: val });
                }}
              />
              <Text variant='xSmall'>{t('CATALOG.TOP_RESULTS_DESCRIPTION')}</Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Toggle
                label={t('CATALOG.ENABLE_HYBRID_SEARCH')}
                checked={tempChatApp!.EnableHybridSearch}
                onChange={(evt, checked) =>
                  setTempChatApp({
                    ...tempChatApp,
                    EnableHybridSearch: checked!,
                  })
                }
              />
              <Text variant={'xSmall'} block>
                {t('CATALOG.ENABLE_HYBRID_SEARCH_DESCRIPTION')}
              </Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Toggle
                label={t('CATALOG.ENABLE_DENSITY_SEARCH')}
                checked={tempChatApp!.EnableDensitySearch}
                onChange={(evt, checked) =>
                  setTempChatApp({
                    ...tempChatApp,
                    EnableDensitySearch: checked!,
                  })
                }
              />
              <Text variant={'xSmall'} block>
                {t('CATALOG.ENABLE_DENSITY_SEARCH_DESCRIPTION')}
              </Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Toggle
                label={t('CATALOG.ENABLE_MODEL_RERANK')}
                checked={tempChatApp!.EnableModelReRank}
                onChange={(evt, checked) =>
                  setTempChatApp({
                    ...tempChatApp,
                    EnableModelReRank: checked!,
                  })
                }
              />
              <Text variant={'xSmall'} block>
                {t('CATALOG.ENABLE_MODEL_RERANK_DESCRIPTION')}
              </Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Toggle
                label={t('CATALOG.ALLOW_MODEL_SOURCES')}
                checked={tempChatApp!.AllowModelSources}
                onChange={(evt, checked) =>
                  setTempChatApp({
                    ...tempChatApp,
                    AllowModelSources: checked!,
                  })
                }
              />
              <Text variant={'xSmall'} block>
                {t('CATALOG.ALLOW_MODEL_SOURCES_DESCRIPTION')}
              </Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Toggle
                label={t('CATALOG.HIDE_SOURCES')}
                checked={tempChatApp!.HideSources}
                onChange={(evt, checked) =>
                  setTempChatApp({
                    ...tempChatApp,
                    HideSources: checked!,
                  })
                }
              />
              <Text variant={'xSmall'} block>
                {t('CATALOG.HIDE_SOURCES_DESCRIPTION')}
              </Text>
            </Stack.Item>
          </Stack>
          <Stack.Item>
            <Role hide allowedRoles={['admin']}>
              <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item grow>
                  <ComboBox
                    style={{ minWidth: 200 }}
                    label={t('MAIN.MODEL')}
                    options={modelOptions}
                    selectedKey={tempChatApp.Model}
                    onChange={(evt, option) => {
                      setTempChatApp({
                        ...tempChatApp,
                        Model: option!.key as any,
                        TopResults: Math.min(
                          maxTopResultsByModel(option!.key as string),
                          tempChatApp.TopResults
                        ),
                      });
                    }}
                    styles={comboBoxStyles}
                  />
                </Stack.Item>
              </Stack>
            </Role>
            {!AuthenticationService.Default.HasRole('admin') && (
              <Stack.Item>
                <Text>
                  <strong>Model:</strong>{' '}
                  {modelOptions.filter(o => o.key === chatApp.Model)[0].text}
                  <Text variant={'xSmall'} block>
                    {t('CATALOG.MODEL_DESCRIPTION')}
                  </Text>
                </Text>
              </Stack.Item>
            )}
            <Stack wrap horizontal style={{ marginTop: '2rem' }}>
              <StackItem>
                <Text variant='large'>{t('CATALOG.ANALYTICS')}</Text>
              </StackItem>
            </Stack>
            <Stack
              verticalAlign='center'
              style={{ marginLeft: -10, marginBottom: 20 }}
              tokens={{ childrenGap: 20 }}
            >
              <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
                <Toggle
                  label={t('CATALOG.ENABLE_ANALYTICS')}
                  checked={tempChatApp!.EnableAnalytics}
                  onChange={(evt, checked) =>
                    setTempChatApp({
                      ...tempChatApp,
                      EnableAnalytics: checked!,
                    })
                  }
                />
                <Text variant={'xSmall'} block>
                  {t('CATALOG.ENABLE_ANALYTICS_DESCRIPTION')}
                </Text>
              </Stack.Item>
              {AuthenticationService.Default.HasRole('admin') && (
                <>
                  {chatApp.EnableAnalytics && (
                    <>
                      <Stack.Item>
                        <DefaultButton
                          style={{ marginLeft: 15, marginRight: 10 }}
                          onClick={() => setAction(Action.StartAnalytics)}
                          text={
                            executingJob
                              ? `${t('MAIN.EXECUTING')}...`
                              : `${t('MAIN.START')} ${chatApp.DisplayName} ${t(
                                  'SETTINGS.ANALYTICS'
                                )}`
                          }
                          allowDisabledFocus
                        />
                        <Text variant={'xSmall'}>
                          {t('SETTINGS.START_ANALYTICS_DESCRIPTION_2')}
                        </Text>
                      </Stack.Item>
                      <Stack.Item>
                        <DefaultButton
                          style={{ marginLeft: 15, marginRight: 10 }}
                          onClick={() => setAction(Action.PurgeAll)}
                          text={
                            executingJob
                              ? `${t('MAIN.EXECUTING')}...`
                              : `${t('MAIN.PURGE')} ${chatApp.DisplayName} ${t(
                                  'SETTINGS.ANALYTICS'
                                )}`
                          }
                          allowDisabledFocus
                        />
                        <Text variant={'xSmall'}>{t('SETTINGS.PURGE_ALL_DESCRIPTION_2')}</Text>
                      </Stack.Item>
                    </>
                  )}
                </>
              )}
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <section className={styles.buttonSection}>
              <PrimaryButton
                style={{ minWidth: 80 }}
                onClick={updateChatApp}
                disabled={
                  !tempChatApp.DisplayName ||
                  tempChatApp.DisplayName.length < 3 ||
                  !tempChatApp.Description ||
                  tempChatApp.Description.length < 20 ||
                  tempChatApp.Description.length > 1000
                }
                text={saving ? '' : t('MAIN.SAVE')}
              >
                {saving && <Spinner hidden={!saving} size={SpinnerSize.small} />}
              </PrimaryButton>
            </section>
          </Stack.Item>
        </Stack>
      </>
    )) || (
      <Stack
        grow
        verticalFill
        style={{ minHeight: 0 }}
        horizontalAlign='center'
        className='main-content'
      >
        <Stack grow verticalAlign='center'>
          <Spinner size={SpinnerSize.large} />
        </Stack>
      </Stack>
    )
  );
};

export default ChatAppSettings;
