import { FC, useContext, useEffect, useRef, useState } from "react";
import styles from "./AIApp.module.scss";
import AuthenticationService from "../../../../Services/AuthenticationService";
import { AppCatalogServiceContext } from "../../../../Services/AppCatalogService";
import { PrimaryButton, Spinner, SpinnerSize, Stack, StackItem, Text } from "@fluentui/react";
import { useNavigate, useParams } from "react-router-dom";
import { aiToolAppServiceContext } from "../../../../Services/API/AiToolAppService";
import IAIToolApp from "../../../../Models/API/IAIToolApp";
import ConfigurationService from "../../../../Services/ConfigurationService";
import { trackPageView } from "../../../../Services/AppInsights";
import { useTranslation } from "react-i18next";
import { LanguageServiceContext } from "../../../../Services/LanguageService";

const AIApp: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const iFrame = useRef<HTMLIFrameElement>(null);
  const appCatalogService = useContext(AppCatalogServiceContext);
  const languageContext = useContext(LanguageServiceContext);
  const [app, setApp] = useState<IAIToolApp>();
  const [originalTheme, setOriginalTheme] = useState<string>();

  var aiToolAppService = useContext(aiToolAppServiceContext);

  const { t } = useTranslation();

  useEffect(() => {
    setOriginalTheme(appCatalogService?.darkMode ? "darkMode" : "lightMode");

    const fetchData = async () => {
      var aiTool = await aiToolAppService?.Get(parseInt(params.appId!));
      if (aiTool) {
        setApp(aiTool);
        document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${aiTool?.DisplayName}`;
      }
    };

    if (params.appId) {
      fetchData();
    }
  }, []);

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (iFrame.current) {
      iFrame.current.contentWindow?.postMessage(
        {
          eventType: appCatalogService?.darkMode ? "darkMode" : "lightMode",
        },
        "*"
      );
    }
  }, [appCatalogService?.darkMode]);

  useEffect(() => {
    if (iFrame.current) {
      iFrame.current.contentWindow?.postMessage(
        {
          eventType: "languageChanged",
          payload: languageContext?.language,
        },
        "*"
      );
    }
  }, [languageContext?.language]);

  return (
    (app && originalTheme && (
      <Stack verticalFill style={{ minHeight: 0, overflowY: "auto" }}>
        <StackItem style={{ padding: 10 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Text block variant="xLargePlus">
              {app.DisplayName}
            </Text>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <PrimaryButton
                style={{ borderRadius: 5 }}
                onClick={(_) =>
                  navigate({
                    pathname: "../",
                  })
                }
              >
                {t("AI_APP_CATALOG.BTN_BACK_TO_CATALOG")}
              </PrimaryButton>
              <PrimaryButton
                style={{ borderRadius: 5 }}
                onClick={() => navigate(`/ai-apps-catalog/apps/${params.appId}/docs`)}
              >
                {t("AI_APP_CATALOG.BTN_OPEN_DOCUMENTATION")}
              </PrimaryButton>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Text block style={{ marginTop: 10 }}>
              {app.Description}
            </Text>
          </div>
        </StackItem>
        <StackItem verticalFill grow style={{ padding: 10 }}>
          <iframe
            allow="clipboard-write"
            title="AI Tool"
            style={{ borderRadius: "18px" }}
            sandbox="allow-scripts allow-top-navigation allow-same-origin allow-top-navigation-by-user-activation allow-modals allow-downloads"
            className={styles.iframe}
            ref={iFrame}
            src={`${app.AppUrl}?accountName=${AuthenticationService.Default.Account?.username}&theme=${originalTheme}`}
          ></iframe>
        </StackItem>
      </Stack>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default AIApp;
