import "./Navigation.scss";
import { Link } from "react-router-dom";
import { Stack, Text } from "@fluentui/react";
import { FC, useContext, useEffect } from "react";
import DarkModeSwitch from "../../DarkModeSwitch/DarkModeSwitch";
import { AppCatalogServiceContext } from "../../../Services/AppCatalogService";
import UserMenu from "../../UserMenu/UserMenu";
import { useTranslation } from "react-i18next";

const Navigation: FC = () => {
  const appCatalogService = useContext(AppCatalogServiceContext);
  const { t } = useTranslation();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
  };

  return (
    <Stack
      horizontal
      className="header"
      tokens={{ padding: 16, childrenGap: 20 }}
      grow
      verticalAlign="center"
    >
      <Link to="/" style={{ fontSize: 1, display: "inline-block" }}>
        <img src={appCatalogService?.logo} alt="" />
      </Link>
      <Stack horizontal className="nav" grow>
        <Text variant="xLarge">{t('MAIN.AI_SERVICE_CATALOG')}</Text>
      </Stack>
      <Stack className="hideInMobile">
        <Stack.Item>
          <DarkModeSwitch />
        </Stack.Item>
      </Stack>
      <Stack className="hideInMobile">
        <Stack.Item>
          <UserMenu />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export { Navigation };
