import { FC, useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PlaygroundStart } from './Playground/PlaygroundStart';
import { CatalogStart } from './Catalog/CatalogStart';
import Role from '../../Components/Role/Role';
import { UserServiceContext } from '../../Services/API/UserService';
import { AIAppsStart } from './AIApps/AIAppsStart';
import { VersionManagerServiceContext } from '../../Services/VersionManagerService';
import Maintenance from '../Maintenance/Maintenance';
import AuthenticationService from '../../Services/AuthenticationService';
import { DefaultButton, Dialog, DialogFooter, PrimaryButton, Text } from '@fluentui/react';
import { SettingsServiceContext } from '../../Services/API/SettingsService';
import { LanguageServiceContext } from '../../Services/LanguageService';
import { useTranslation } from 'react-i18next';

export const maxWebsiteWidth = 1440;

interface IAppStartProps {
  loading: boolean;
}

const AppStart: FC<IAppStartProps> = ({ loading }) => {
  const versionManagerService = useContext(VersionManagerServiceContext);
  const userService = useContext(UserServiceContext);
  const settingsService = useContext(SettingsServiceContext);
  const languageService = useContext(LanguageServiceContext);
  const [localLoading, setLocalLoading] = useState(true);
  const [maintenance, setMaintenance] = useState<boolean>(false);

  const [showAnalyticsDialog, setShowAnalyticsDialog] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>('');

  const { t } = useTranslation();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const init = async () => {
    if (!loading) {
      const maintenance = await versionManagerService?.inMaintenance();
      setMaintenance(maintenance!);
      if (!maintenance || AuthenticationService.Default.HasRole('admin')) {
        var user = await userService?.GetUserInformation();
        if (user === null || user === undefined) {
          setLocalLoading(false);
          return;
        }

        if (user.OptIntoAnalytics === undefined || user.OptIntoAnalytics === null) {
          const settings = await settingsService?.Get();
          setDialogMessage( String(
            languageService?.language === "en"
                ? settings?.opt_into_dialog_message
              : (settings as any)[
                  `opt_into_dialog_message_${languageService?.language}`
                ] ?? settings?.opt_into_dialog_message ?? ""
          ));
          setShowAnalyticsDialog(true);
        }
      }

      setLocalLoading(false);
    }
  };

  const handleOptInSelection = (value: boolean) => {
    setShowAnalyticsDialog(false);
    userService?.OptIntoAnalytics(value);
    languageService?.setOptInAnalytics(value);
  };

  return (
    <>
      {(maintenance && !AuthenticationService.Default.HasRole('admin') && <Maintenance />) || (
        <>
          <Routes>
            <Route
              path='ai-apps-catalog/*'
              element={<Role children={<AIAppsStart loading={loading || localLoading} />} />}
            ></Route>
            <Route
              path='catalogs/:catalogId/*'
              element={<Role children={<CatalogStart loading={loading || localLoading} />} />}
            ></Route>
            <Route
              path='*'
              element={<Role children={<PlaygroundStart loading={loading || localLoading} />} />}
            />
          </Routes>

          <Dialog
            hidden={!showAnalyticsDialog}
            dialogContentProps={{
              title: t('SETTINGS.OPT_INTO_ANALYTICS'),

              showCloseButton: false,
            }}
            minWidth={450}
            modalProps={{
              isBlocking: true,
              isDarkOverlay: true,
              isClickableOutsideFocusTrap: false,
              dragOptions: undefined,
              styles: { main: { maxWidth: 500 } },
            }}
          >
            <Text style={{ whiteSpace: 'pre-line' }}>{dialogMessage}</Text>

            <DialogFooter>
              <PrimaryButton
                style={{ marginTop: '1rem' }}
                onClick={() => handleOptInSelection(true)}
                text={t('MAIN.YES')}
              />
              <DefaultButton
                style={{ marginTop: '1rem' }}
                onClick={() => handleOptInSelection(false)}
                text={t('MAIN.NO')}
              />
            </DialogFooter>
          </Dialog>
        </>
      )}
    </>
  );
};

export { AppStart };
