import React, { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import ILibrary from "../../../../../../../../Models/API/ILibrary";
import { useOutletContext } from "react-router-dom";
import {
  CommandBar,
  ICommandBarItemProps,
  SearchBox,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  Selection,
  SelectionMode,
  IColumn,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  ProgressIndicator,
  DetailsList,
  IconButton,
  IContextualMenuProps,
  Panel,
  TextField,
  StackItem,
  Link,
} from "@fluentui/react";
import IDocument from "../../../../../../../../Models/API/IDocument";
import { DocumentServiceContext } from "../../../../../../../../Services/API/DocumentService";
import moment from "moment";
import axios, {
  AxiosError,
  AxiosProgressEvent,
  CancelTokenSource,
} from "axios";
import { Icon } from "@fluentui/react";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import "./Documents.scss";
import { removeDiacritics } from "../../../../../../../../Utilities/Strings";
import JSZip from "jszip";
import saveAs from "file-saver";
import { DialogServiceContext } from "../../../../../../../../Services/Dialogs/DialogService";
import MetadataField from "../../../../../../../../Components/Metadata/MetadataField/MetadataField";
import { trackPageView } from "../../../../../../../../Services/AppInsights";
import { on } from "events";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { LanguageServiceContext } from "../../../../../../../../Services/LanguageService";
import ConfigurationService from "../../../../../../../../Services/ConfigurationService";

export const VALID_UPLOAD_FILE_EXTENSIONS = [
  "docx",
  "pptx",
  "xlsx",
  "pdf",
  "txt",
];

const shimmerColumns = (
  t: TFunction<"translation", undefined, "translation">
) =>
  [
    {
      key: "Icon",
      name: "",
      minWidth: 20,
      maxWidth: 20,
      isResizable: false,
    },
    {
      key: "FileName",
      name: t("LIBRARIES.FILE_NAME"),
      minWidth: 50,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: "Title",
      name: t("MAIN.TITLE"),
      minWidth: 100,
      isResizable: true,
    },
    {
      key: "FileOptions",
      name: "",
      minWidth: 20,
      maxWidth: 20,
      isResizable: false,
    },
    {
      key: "OwnerName",
      name: t("LIBRARIES.UPLOADED_BY"),
      minWidth: 150,
      maxWidth: 200,
      isResizable: false,
    },
    {
      key: "State",
      name: t("MAIN.STATE"),
      minWidth: 100,
      maxWidth: 100,
      isResizable: false,
    },
    {
      key: "Created",
      name: t("MAIN.CREATED"),
      minWidth: 100,
      maxWidth: 100,
      isResizable: false,
    },
    {
      key: "Updated",
      name: t("MAIN.UPDATED"),
      minWidth: 100,
      maxWidth: 100,
      isResizable: false,
    },
  ] satisfies IColumn[];

const LibraryDocuments: FC = () => {
  const documentsService = useContext(DocumentServiceContext);
  const dialogService = useContext(DialogServiceContext);
  const languageService = useContext(LanguageServiceContext);

  const { library } = useOutletContext<{ library: ILibrary }>();
  const [documents, setDocuments] = useState<IDocument[] | null>(null);
  const [filteredDocuments, setFilteredDocuments] = useState<
    IDocument[] | null | undefined
  >(undefined);
  const [selectedDocuments, setSelectedDocuments] = useState<IDocument[]>([]);
  const [commandBarButtons, setCommandBarButtons] = useState<
    ICommandBarItemProps[]
  >([]);
  const [hideDeleteDialog, setHideDeleteDialog] = useState<boolean>(true);
  const [hideUploadDialog, setHideUploadDialog] = useState<boolean>(true);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [cancelTokenSource, setCancelTokenSource] = useState<
    CancelTokenSource | undefined
  >(undefined);
  const [filterKeyword, setFilterKeyword] = useState<string | undefined>(
    undefined
  );
  const inputRef = React.useRef<HTMLInputElement>(null);
  const inputVirtualRef = React.useRef<HTMLInputElement>(null);

  const [downloading, setDownloading] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [currentDocument, setCurrentDocument] = useState<IDocument>();
  const [savingDocument, setSavingDocument] = useState<boolean>(false);

  const [hideUploadVirtualDialog, setHideUploadVirtualDialog] =
    useState<boolean>(true);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [url, setUrl] = useState("");
  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reloadDocuments();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Runs when the filter keyword changes
  useEffect(() => {
    if (documents && filterKeyword !== undefined) {
      setFilteredDocuments(
        filterKeyword
          ? documents?.filter((doc) => {
              return (
                removeDiacritics(doc.FileName.toLowerCase()).indexOf(
                  removeDiacritics(filterKeyword.toLowerCase())
                ) !== -1 ||
                removeDiacritics(doc.Metadata["title"].toLowerCase()).indexOf(
                  removeDiacritics(filterKeyword.toLowerCase())
                ) !== -1
              );
            })
          : documents
      );
    }
  }, [filterKeyword, documents]);

  const reloadDocuments = async () => {
    if (isMounted) setDocuments([]);
    if (isMounted) setFilteredDocuments(undefined);
    let docs = await documentsService!.GetAll(library.Id);
    docs = docs!.sort((docA, docB) =>
      docA.FileName.localeCompare(docB.FileName)
    );
    if (isMounted) setFilteredDocuments(docs);
    if (isMounted) setDocuments(docs);
  };

  const downloadDocuments = async () => {
    if (downloading) return;
    setDownloading(true);
    if (selectedDocuments.length > 1) {
      const zip = new JSZip();
      for (let i = 0; i < selectedDocuments.length; i++) {
        const doc = selectedDocuments[i];

        if (doc.Url) continue;

        const downloadedDoc = await documentsService!.Download(doc.Id);
        zip.file(
          doc.FileName,
          new Blob([downloadedDoc!], {
            type: "application/octet-stream",
          })
        );
      }
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `${library.Title}.zip`);
    } else {
      const downloadedDoc = await documentsService!.Download(
        selectedDocuments[0].Id
      );
      saveAs(
        new Blob([downloadedDoc!], {
          type: "application/octet-stream",
        }),
        selectedDocuments[0].FileName
      );
    }
    setDownloading(false);
  };

  // Runs when the selected documents change
  useEffect(() => {
    let barButtons: ICommandBarItemProps[] = [];
    const showVirtual = false;
    if (library.PermissionType !== "Read")
      barButtons = [
        ...barButtons,
        showVirtual
          ? {
              key: "upload",
              text: t("MAIN.UPLOAD"),
              iconProps: { iconName: "Upload" },
              subMenuProps: {
                items: [
                  {
                    key: "documents",
                    text: t("LIBRARIES.DOCUMENTS"),
                    iconProps: { iconName: "Upload" },
                    onClick: (event, item) => {
                      inputRef.current?.click();
                    },
                  },
                  {
                    key: "virtual",
                    text: t("LIBRARIES.VIRTUAL_DOCUMENT"),
                    iconProps: { iconName: "Upload" },
                    onClick: (event, item) => {
                      setHideUploadVirtualDialog(false);
                    },
                  },
                ],
              },
            }
          : {
              key: "upload",
              text: t("MAIN.UPLOAD"),
              iconProps: { iconName: "Upload" },
              onClick: (event, item) => {
                inputRef.current?.click();
              },
            },
      ];

    var showVisit = selectedDocuments.length === 1 && selectedDocuments[0].Url;

    barButtons = [
      ...barButtons,
      {
        key: "download",
        text: !showVisit ? t("MAIN.DOWNLOAD") : t("MAIN.VISIT"),
        iconProps: {
          iconName: downloading
            ? "CloudDownload"
            : !showVisit
            ? "Download"
            : "Globe",
        },
        onClick: (event, item) => {
          if (!showVisit) {
            downloadDocuments();
          } else {
            if (selectedDocuments.length === 1 && selectedDocuments[0].Url) {
              window.open(selectedDocuments[0].Url, "_blank");
            }
          }
        },
        disabled: selectedDocuments.length === 0,
      },
    ];

    if (library.PermissionType !== "Read")
      barButtons = [
        ...barButtons,
        {
          key: "delete",
          text: t("MAIN.DELETE"),
          iconProps: { iconName: "Delete" },
          onClick: (event, item) => {
            setHideDeleteDialog(false);
          },
          disabled: selectedDocuments.length === 0,
        },
      ];

    barButtons = [
      ...barButtons,
      {
        key: "refresh",
        text: t("MAIN.REFRESH"),
        iconProps: { iconName: "Refresh" },
        onClick: (event, item) => {
          resetState();
          reloadDocuments();
        },
      },
    ];

    setCommandBarButtons(barButtons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocuments, downloading, languageService?.language]);

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedDocuments(selection.getSelection() as IDocument[]);
    },
  });

  const menuProps = (document: IDocument): IContextualMenuProps => {
    var items = [
      {
        key: "editMetadata",
        text: t("METADATA.EDIT_METADATA"),
        iconProps: { iconName: "Edit" },
        ariaDescription: "Edit document metadata",
        onClick: (event: any, item: any) => {
          setCurrentDocument(document);
        },
      },
    ];

    if (document.Url) {
      items.push({
        key: "visit",
        text: `${t("MAIN.VISIT")} ${"MAIN.URL"}`,
        iconProps: { iconName: "Globe" },
        ariaDescription: "Visit document URL",
        onClick: (event: any, item: any) => {
          window.open(document.Url, "_blank");
        },
      });
    }

    return {
      items,
    };
  };

  const documentUrl = (document: IDocument) => {
    return `${ConfigurationService.Default.Configuration.API?.BaseUri}/documents/${document.Id}/open`;
  };

  const openDocument = (document: IDocument) => {
    window.open(documentUrl(document), "_blank");
  };

  const onRenderItemColumn = (
    item: IDocument,
    index?: number,
    column?: IColumn
  ): any => {
    if (column?.key === "Icon") {
      return (
        <Icon
          {...getFileTypeIconProps({
            extension:
              item.FileName.split(".")[item.FileName.split(".").length - 1],
            size: 20,
            imageFileType: "svg",
          })}
        />
      );
    } else if (column?.key === "FileOptions" && item.State === "Completed") {
      return (
        <>
          <IconButton
            title="Options"
            style={{ height: 20 }}
            iconProps={{ iconName: "More" }}
            menuProps={menuProps(item)}
            menuIconProps={{ style: { display: "none" } }}
          ></IconButton>
        </>
      );
    } else if (column?.key === "Title") {
      return <span title={item.Metadata.title}>{item.Metadata.title}</span>;
    } else if (column?.key === "FileName") {
      return (
        <Link title={item.FileName} onClick={() => openDocument(item)}>
          {item.FileName}
        </Link>
      );
    } else if (column?.key === "State") {
      return (
        <>
          {/* @ts-ignore */}
          <span>{t(`STATUS.${(item.State as string).toUpperCase()}`)}</span>
          {item.State === "Error" && (
            <IconButton
              title={item.ErrorMessage}
              style={{ height: 12 }}
              iconProps={{ iconName: "Warning" }}
              menuProps={menuProps(item)}
              menuIconProps={{ style: { display: "none" } }}
            ></IconButton>
          )}
        </>
      );
    } else if (column?.key === "Updated") {
      return moment(item.Metadata.updated).format("DD MMM yyyy");
    } else if (column?.key === "Created") {
      return moment(item.Metadata.created).format("DD MMM yyyy");
    } else {
      return item![column?.key as keyof IDocument];
    }
  };

  const deleteDocuments = async (): Promise<void> => {
    const promises: (Promise<void | null> | undefined)[] = [];
    const selectedDocs = [...selectedDocuments];
    resetState();
    for (let i = 0; i < selectedDocs.length; i++) {
      const doc = selectedDocs[i];
      promises.push(documentsService?.Delete(doc.Id));
    }
    await Promise.allSettled(promises);
    reloadDocuments();
  };

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      let newFiles = new Array<File>();
      for (var i = 0; i < files.length; i++) {
        newFiles.push(files[i]);
      }
      if (
        newFiles.filter((file) => {
          const extension =
            file.name.split(".")[file.name.split(".").length - 1];
          return (
            VALID_UPLOAD_FILE_EXTENSIONS.indexOf(extension.toLowerCase()) === -1
          );
        }).length > 0
      ) {
        dialogService?.showWarningDialog(
          t("CHAT.DOCUMENT_UPLOAD"),
          t("CHAT.FILE_EXTENSION")
        );
      } else {
        const CancelToken = axios.CancelToken;
        const cancelTokenSource = CancelToken.source();
        setCancelTokenSource(cancelTokenSource);
        setHideUploadDialog(false);
        setUploadPercentage(0);
        try {
          await documentsService!.Create(
            library.Id,
            files,
            cancelTokenSource.token,
            (progressEvent: AxiosProgressEvent) => {
              setUploadPercentage(progressEvent.loaded / progressEvent.total!);
            }
          );
          resetState();
          reloadDocuments();
        } catch (error) {
          const axiosError = error as AxiosError;
          if (
            axiosError.response?.status === 409 ||
            axiosError.response?.status === 422
          ) {
            dialogService?.showWarningDialog(
              t("CHAT.DOCUMENT_UPLOAD"),
              (axiosError.response.data as any).title
            );
          } else if (axiosError.response?.status === 400) {
            const keys = Object.keys((axiosError.response.data as any).errors);
            if (keys.indexOf("") !== -1) {
              const errorMsg = (axiosError.response.data as any).errors[""][0];
              if (
                errorMsg.toLowerCase().indexOf("request body too large") !== -1
              ) {
                dialogService?.showWarningDialog(
                  t("CHAT.DOCUMENT_UPLOAD"),
                  t("CHAT.FILE_SIZE")
                );
              } else {
                dialogService?.showWarningDialog(
                  t("CHAT.DOCUMENT_UPLOAD"),
                  errorMsg
                );
              }
            } else {
              const errorMsg = (axiosError.response.data as any).errors[
                "Files"
              ][0];
              dialogService?.showWarningDialog(
                t("CHAT.DOCUMENT_UPLOAD"),
                errorMsg
              );
            }
          }
        }
      }
      event.target.value = "";
      setHideUploadDialog(true);
    }
  };

  const handleVirtualFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]; // Get the first selected file
    if (file) {
      setSelectedFile(file);
    }
  };

  const onUploadVirtualFile = async () => {
    const file = selectedFile;

    if (!file) return;

    // Check file extension
    const extension = file.name.split(".").pop()?.toLowerCase();
    if (!extension || VALID_UPLOAD_FILE_EXTENSIONS.indexOf(extension) === -1) {
      dialogService?.showWarningDialog(
        t("CHAT.DOCUMENT_UPLOAD"),
        t("CHAT.FILE_EXTENSION")
      );
      return;
    }

    // Prepare for upload
    const CancelToken = axios.CancelToken;
    const cancelTokenSource = CancelToken.source();
    setCancelTokenSource(cancelTokenSource);
    setHideUploadDialog(false);
    setUploadPercentage(0);

    try {
      await documentsService!.CreateVirtual(
        library.Id,
        file, // Send only the selected file
        url,
        cancelTokenSource.token,
        (progressEvent: AxiosProgressEvent) => {
          setUploadPercentage(
            (progressEvent.loaded / progressEvent.total!) * 100
          );
        }
      );
    } catch (error) {
      const axiosError = error as AxiosError;
      if (
        axiosError.response?.status === 409 ||
        axiosError.response?.status === 422
      ) {
        dialogService?.showWarningDialog(
          t("CHAT.DOCUMENT_UPLOAD"),
          (axiosError.response.data as any).title
        );
      } else if (axiosError.response?.status === 400) {
        const keys = Object.keys((axiosError.response.data as any).errors);
        if (keys.indexOf("") !== -1) {
          const errorMsg = (axiosError.response.data as any).errors[""][0];
          if (errorMsg.toLowerCase().includes("request body too large")) {
            dialogService?.showWarningDialog(
              t("CHAT.DOCUMENT_UPLOAD"),
              t("CHAT.FILE_SIZE")
            );
          } else {
            dialogService?.showWarningDialog(
              t("CHAT.DOCUMENT_UPLOAD"),
              errorMsg
            );
          }
        } else {
          const errorMsg = (axiosError.response.data as any).errors["Files"][0];
          dialogService?.showWarningDialog(t("CHAT.DOCUMENT_UPLOAD"), errorMsg);
        }
      }
    }
    resetState();
    reloadDocuments();
  };

  const resetState = (): void => {
    setHideDeleteDialog(true);
    setDocuments([]);
    setFilteredDocuments(undefined);
    setSelectedDocuments([]);
    setFilterKeyword(undefined);
    setSavingDocument(false);
    setCurrentDocument(undefined);
    setSelectedFile(null);
    setUrl("");
    setHideUploadDialog(true);
    setHideUploadVirtualDialog(true);
  };

  const cancelUpload = async () => {
    cancelTokenSource!.cancel("Operation canceled");
  };

  const saveDocument = async () => {
    if (!savingDocument) {
      setSavingDocument(true);
      await documentsService!.Update(
        currentDocument!.Id,
        currentDocument!.Metadata
      );
      resetState();
      reloadDocuments();
    }
  };

  const onRenderFooterContent = React.useCallback(
    () => (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <PrimaryButton
          style={{ minWidth: 80 }}
          onClick={saveDocument}
          text={savingDocument ? "" : t("MAIN.SAVE")}
          disabled={
            library.Metadata &&
            library.Metadata.Fields.filter((field) => {
              return (
                field.Required &&
                (currentDocument?.Metadata[field.InternalName!] === null ||
                  currentDocument?.Metadata[field.InternalName!] ===
                    undefined ||
                  currentDocument?.Metadata[field.InternalName!] === "")
              );
            }).length > 0
          }
        >
          {savingDocument && <Spinner size={SpinnerSize.small} />}
        </PrimaryButton>
        <DefaultButton onClick={() => setCurrentDocument(undefined)}>
          {t("MAIN.CANCEL")}
        </DefaultButton>
      </Stack>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentDocument, savingDocument]
  );

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0 }}>
        <Stack.Item>
          <CommandBar items={commandBarButtons} ariaLabel="Library actions" />
          <SearchBox
            value={filterKeyword}
            onChange={(evt, newValue) => setFilterKeyword(newValue)}
            placeholder={t("FILTERS.FILTER_BY_FILE_NAME_OR_TITLE")}
            iconProps={{ iconName: "Filter" }}
            underlined={true}
          />
        </Stack.Item>
        <Stack.Item verticalFill style={{ minHeight: 0, overflowY: "auto" }}>
          <DetailsList
            setKey="documents"
            items={filteredDocuments || []}
            columns={shimmerColumns(t)}
            selection={selection}
            selectionMode={SelectionMode.multiple}
            onRenderItemColumn={onRenderItemColumn}
            ariaLabelForGrid="Documents"
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            className="documents-list"
          />
          <input
            ref={inputRef}
            type="file"
            multiple={true}
            accept={VALID_UPLOAD_FILE_EXTENSIONS.map((ext) => `.${ext}`).join(
              ","
            )}
            hidden={true}
            onChange={onFileChange}
          />
          {(filteredDocuments === undefined ||
            filteredDocuments?.length === 0) && (
            <>
              {filteredDocuments?.length === 0 && (
                <Text
                  variant="large"
                  block
                  style={{
                    textAlign: "center",
                    opacity: 0.5,
                  }}
                >
                  {t("TABLE.NO_DOCUMENTS")}
                </Text>
              )}
              {filteredDocuments === undefined && (
                <Spinner size={SpinnerSize.large} />
              )}
            </>
          )}
        </Stack.Item>
      </Stack>
      <Dialog
        hidden={hideDeleteDialog}
        onDismiss={() => setHideDeleteDialog(true)}
        modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } } }}
        dialogContentProps={{
          type: DialogType.normal,
          title: t("LIBRARIES.DELETE_DOCUMENTS"),
          subText: t("LIBRARIES.DELETE_DOCUMENTS_CONFIRMATION"),
        }}
      >
        <Stack tokens={{ childrenGap: 5 }}>
          {selectedDocuments.map((doc) => {
            return (
              <Text style={{ wordBreak: "break-word" }} key={doc.Id} block>
                {doc.FileName}
              </Text>
            );
          })}
        </Stack>
        <DialogFooter>
          <PrimaryButton onClick={deleteDocuments} text={t("MAIN.DELETE")} />
          <DefaultButton
            onClick={() => setHideDeleteDialog(true)}
            text={t("MAIN.CANCEL")}
          />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={hideUploadDialog}
        onDismiss={() => setHideDeleteDialog(true)}
        modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } } }}
        dialogContentProps={{
          showCloseButton: false,
          type: DialogType.normal,
          title: t("CHAT.DOCUMENT_UPLOAD"),
        }}
      >
        {(uploadPercentage < 1 && (
          <ProgressIndicator
            description={`${t("MAIN.UPLOADING")}...`}
            percentComplete={uploadPercentage}
          />
        )) || <ProgressIndicator description={`${t("MAIN.PROCESSING")}...`} />}
        {uploadPercentage < 1 && (
          <DialogFooter>
            <PrimaryButton
              disabled={uploadPercentage === 1}
              onClick={cancelUpload}
              text={t("MAIN.CANCEL")}
            />
          </DialogFooter>
        )}
      </Dialog>
      <Dialog
        hidden={hideUploadVirtualDialog}
        onDismiss={() => resetState()}
        dialogContentProps={{
          title: t("LIBRARIES.VIRTUAL_DOCUMENT"),
          subText: t("LIBRARIES.NEW_VIRTUAL_DOCUMENT_SUBTITLE"),
        }}
        modalProps={{
          isBlocking: true,
        }}
      >
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack.Item>
            <input
              ref={inputVirtualRef}
              type="file"
              multiple={false}
              accept={VALID_UPLOAD_FILE_EXTENSIONS.map((ext) => `.${ext}`).join(
                ","
              )}
              hidden={true}
              onChange={handleVirtualFileChange} // Capture file selection
            />
            <DefaultButton onClick={() => inputVirtualRef.current?.click()}>
              Upload document
            </DefaultButton>
            {selectedFile && (
              <p style={{ marginTop: 10, fontWeight: "bold" }}>
                {t("LIBRARIES.SELECTED_FILE")}: {selectedFile.name}
              </p>
            )}
          </Stack.Item>
          <Stack.Item>
            <TextField
              label={t("MAIN.URL")}
              resizable={false}
              value={url}
              onChange={(e, newValue) => setUrl(newValue || "")}
            />
          </Stack.Item>
        </Stack>
        <DialogFooter>
          <PrimaryButton
            style={{ minWidth: 80 }}
            onClick={() => onUploadVirtualFile()}
            text={t("MAIN.UPLOAD")}
            disabled={!selectedFile || !url.trim()}
          ></PrimaryButton>
          <DefaultButton onClick={() => resetState()} text={t("MAIN.CANCEL")} />
        </DialogFooter>
      </Dialog>
      <Panel
        isOpen={currentDocument !== undefined}
        onDismiss={() => setCurrentDocument(undefined)}
        headerText={t("LIBRARIES.UPDATE_DOCUMENT")}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
      >
        {currentDocument && (
          <Stack style={{ marginTop: 20 }} tokens={{ childrenGap: 20 }}>
            {library.Metadata &&
              library.Metadata.Fields.map((field, index) => {
                return (
                  <Stack.Item key={`field_${index}`}>
                    <MetadataField
                      field={field}
                      value={currentDocument!.Metadata[field.InternalName!]}
                      setValue={(value) => {
                        setCurrentDocument({
                          ...currentDocument!,
                          Metadata: {
                            ...currentDocument!.Metadata,
                            [field.InternalName!]: value,
                          },
                        });
                      }}
                    ></MetadataField>
                  </Stack.Item>
                );
              })}
          </Stack>
        )}
      </Panel>
    </>
  );
};

export default LibraryDocuments;
