import {
  ICommandBarItemProps,
  IColumn,
  Stack,
  CommandBar,
  DetailsList,
  SelectionMode,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  DefaultButton,
  Label,
  Text,
  TextField,
  Selection,
  Checkbox,
  Dropdown,
  IDropdownOption,
} from '@fluentui/react';
import { FC, useContext, useEffect, useState } from 'react';

import { IAutomateAppInputParameter, IAutomateAppParameter } from '../../Models/API/IAutomateApp';
import { useId } from '@fluentui/react-hooks';
import { useTranslation } from 'react-i18next';
import { LanguageServiceContext } from '../../Services/LanguageService';

const defaultNewParameter: IAutomateAppInputParameter = {
  DisplayName: '',
  Description: '',
  Required: true,
  Type: 'String',
  ArrayType: 'String',
};

export const FieldTypes: IDropdownOption[] = [
  { key: 'String', text: 'String' },
  { key: 'Number', text: 'Number' },
  { key: 'Array', text: 'Array' },
  { key: 'Boolean', text: 'Boolean' },
  { key: 'File', text: 'File' },
];

export const ArrayFieldTypes: IDropdownOption[] = [
  { key: 'String', text: 'String' },
  { key: 'Number', text: 'Number' },
  { key: 'Boolean', text: 'Boolean' },
  { key: 'File', text: 'File' },
];

export interface IAutomateAppProps {
  parameters: IAutomateAppParameter[];
  setParameters: (parameters: IAutomateAppParameter[]) => Promise<void>;
  columns: IColumn[];
  showRequired?: boolean;
}

const AutomateAppParameters: FC<IAutomateAppProps> = ({
  parameters,
  setParameters,
  columns,
  showRequired = true,
}) => {
  const languageService = useContext(LanguageServiceContext);

  const [commandBarButtons, setCommandBarButtons] = useState<ICommandBarItemProps[]>([]);

  const [selectedParameters, setSelectedParameters] = useState<IAutomateAppInputParameter[]>([]);

  const [currentParameter, setCurrentParameter] = useState<IAutomateAppInputParameter | undefined>(
    undefined
  );
  const [savingParameter, setSavingParameter] = useState<boolean>(false);

  const [hideAddParameterDialog, setHideAddParametersDialog] = useState<boolean>(true);
  const [hideRemoveParameterDialog, setHideRemoveParameterDialog] = useState<boolean>(true);

  const name = useId('name');
  const type = useId('type');
  const arrayType = useId('arrayType');
  const required = useId('required');

  const { t } = useTranslation();

  // Runs when the selected parameters change
  useEffect(() => {
    setCommandBarButtons([
      {
        key: 'add',
        text: t('MAIN.ADD'),
        iconProps: { iconName: 'Add' },
        subMenuProps: {
          items: [
            {
              key: 'parameter',
              text: t('SETTINGS.PARAMETER'),
              iconProps: { iconName: 'TextField' },
              onClick: (event, item) => {
                setCurrentParameter(defaultNewParameter);
                setHideAddParametersDialog(false);
              },
            },
          ],
        },
      },
      {
        key: 'edit',
        text: t('MAIN.EDIT'),
        iconProps: { iconName: 'Edit' },
        onClick: (event, item) => {
          setCurrentParameter(selectedParameters[0]);
          setHideAddParametersDialog(false);
        },
        disabled: selectedParameters.length === 0,
      },
      {
        key: 'delete',
        text: t('MAIN.DELETE'),
        iconProps: { iconName: 'Delete' },
        onClick: async (event, item) => {
          setHideRemoveParameterDialog(false);
        },
        disabled: selectedParameters.length === 0,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParameters, languageService?.language]);

  const onRenderItemColumn = (
    item: IAutomateAppInputParameter,
    index?: number,
    column?: IColumn
  ): any => {
    if (column?.key === 'Required') {
      return item.Required ? t('MAIN.YES') : t('MAIN.NO');
    } else if (column?.key === 'Type') {
      const value = item![column?.key as keyof IAutomateAppInputParameter];
      if (value === 'Array' && item.ArrayType) {
        return `${value}<${item.ArrayType}>`;
      }
      return value;
    } else {
      return item![column?.key as keyof IAutomateAppInputParameter];
    }
  };

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedParameters(selection.getSelection() as IAutomateAppInputParameter[]);
    },
  });

  const removePermissions = async (): Promise<void> => {
    if (!savingParameter) {
      setSavingParameter(true);
      const removedParams = parameters.filter(x => {
        return !selectedParameters.some(y => y.DisplayName === x.DisplayName);
      });

      await setParameters(removedParams);
      setSavingParameter(false);
      setHideRemoveParameterDialog(true);
    }
  };

  const upsertParameter = async () => {
    if (!savingParameter) {
      setSavingParameter(true);
      let params: IAutomateAppInputParameter[] = [];

      if (currentParameter?.InternalName) {
        const pos = parameters.map(e => e.InternalName).indexOf(currentParameter.InternalName);
        parameters.splice(pos, 1, currentParameter);

        params = [...parameters];
      } else {
        params = [...parameters, currentParameter!];
      }

      await setParameters(params);

      setCurrentParameter(undefined);
      setSavingParameter(false);
      setHideAddParametersDialog(true);
    }
  };

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0 }}>
        <Stack.Item>
          <CommandBar items={commandBarButtons} ariaLabel='Parameters actions' />
        </Stack.Item>
        <Stack.Item
          grow
          verticalFill
          style={{ position: 'relative', minHeight: 0, overflowY: 'auto' }}
        >
          <DetailsList
            className='parameters-list'
            setKey='items'
            items={parameters}
            columns={columns}
            selection={selection}
            selectionMode={SelectionMode.multiple}
            onRenderItemColumn={onRenderItemColumn}
            ariaLabelForGrid='Item details'
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            styles={{ root: { verticalAlign: 'middle' } }}
          />
        </Stack.Item>
        {(parameters === undefined || parameters === null || parameters.length === 0) && (
          <Stack.Item grow verticalFill>
            <Text
              variant='large'
              block
              style={{
                textAlign: 'center',
                opacity: 0.5,
              }}
            >
              {t('TABLE.NO_PARAMETERS')}
            </Text>
          </Stack.Item>
        )}
      </Stack>
      <Dialog
        hidden={hideRemoveParameterDialog}
        onDismiss={() => setHideRemoveParameterDialog(true)}
        modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } } }}
        dialogContentProps={{
          type: DialogType.normal,
          title: t('CATALOG.REMOVE_PARAMETERS'),
          subText: t('CATALOG.REMOVE_PARAMETERS_CONFIRMATION'),
        }}
      >
        <Stack tokens={{ childrenGap: 5 }}>
          {selectedParameters.map(perm => {
            return (
              <Text key={perm.DisplayName} block>
                {perm.DisplayName}
              </Text>
            );
          })}
        </Stack>
        <DialogFooter>
          <PrimaryButton onClick={removePermissions} text={savingParameter ? '' : t('MAIN.REMOVE')}>
            {savingParameter && <Spinner size={SpinnerSize.small} />}
          </PrimaryButton>
          <DefaultButton onClick={() => setHideRemoveParameterDialog(true)} text='Cancel' />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={hideAddParameterDialog}
        onDismiss={() => setHideAddParametersDialog(true)}
        modalProps={{
          isBlocking: true,
          styles: {
            main: { minWidth: '450px !important', maxWidth: 450 },
          },
        }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title:
            currentParameter && !currentParameter.InternalName
              ? t('CATALOG.ADD_PARAMETER')
              : t('CATALOG.EDIT_PARAMETER'),
        }}
      >
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack.Item>
            <Label htmlFor={name}>{t('MAIN.DISPLAY_NAME')}</Label>
            <TextField
              value={currentParameter?.DisplayName}
              onChange={(evt, newValue) => {
                setCurrentParameter({
                  ...currentParameter!,
                  DisplayName: newValue!,
                });
              }}
            ></TextField>
          </Stack.Item>
          <Stack.Item>
            <Label htmlFor={name}>{t('MAIN.DESCRIPTION')}</Label>
            <TextField
              value={currentParameter?.Description}
              onChange={(evt, newValue) => {
                setCurrentParameter({
                  ...currentParameter!,
                  Description: newValue!,
                });
              }}
            ></TextField>
          </Stack.Item>
          {showRequired ? (
            <Stack.Item>
              <Checkbox
                label={t('MAIN.REQUIRED')}
                id={required}
                checked={currentParameter?.Required}
                onChange={(evnt, checked) => {
                  setCurrentParameter({
                    ...currentParameter!,
                    Required: checked!,
                  });
                }}
              />
            </Stack.Item>
          ) : (
            <></>
          )}
          <Stack.Item>
            <Label htmlFor={type}>{t('MAIN.TYPE')}</Label>
            <Dropdown
              id={type}
              selectedKey={currentParameter?.Type}
              onChange={(evt, option, index) => {
                setCurrentParameter({
                  ...currentParameter!,
                  Type: option!.key as any,
                });
              }}
              placeholder={t('CATALOG.SELECT_TYPE')}
              options={FieldTypes}
            />
          </Stack.Item>
          <Stack.Item>
            {currentParameter && currentParameter.Type === 'Array' ? (
              <>
                <Label htmlFor={arrayType}>{t('CATALOG.TYPE_OF_ARRAY')}</Label>
                <Dropdown
                  id={arrayType}
                  selectedKey={currentParameter?.ArrayType}
                  onChange={(evt, option, index) => {
                    setCurrentParameter({
                      ...currentParameter!,
                      ArrayType: option!.key as any,
                    });
                  }}
                  placeholder={t('CATALOG.SELECT_TYPE')}
                  options={ArrayFieldTypes}
                />
              </>
            ) : (
              <></>
            )}
          </Stack.Item>
        </Stack>
        <DialogFooter>
          <PrimaryButton
            style={{ minWidth: 80 }}
            onClick={upsertParameter}
            text={savingParameter ? '' : t('MAIN.SAVE')}
            disabled={!currentParameter?.DisplayName}
          >
            {savingParameter && <Spinner size={SpinnerSize.small} />}
          </PrimaryButton>
          <DefaultButton
            onClick={() => {
              setHideAddParametersDialog(true);
            }}
          >
            {t('MAIN.CANCEL')}
          </DefaultButton>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default AutomateAppParameters;
