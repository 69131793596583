import { FC, createContext, useContext } from 'react';
import { ApiHttpServiceContext } from './ApiHttpService';
import IDocument from '../../Models/API/IDocument';
import { AxiosProgressEvent, CancelToken } from 'axios';

export interface IDocumentService {
  GetAll(libraryId: number): Promise<IDocument[] | null>;
  Delete(documentId: number): Promise<void | null>;
  Create(
    libraryId: number,
    files: FileList,
    cancelToken?: CancelToken | undefined,
    onUploadProgress?: ((progressEvent: AxiosProgressEvent) => void) | undefined
  ): Promise<IDocument | null>;
  CreateVirtual(
    libraryId: number,
    file: File,
    url: string,
    cancelToken?: CancelToken | undefined,
    onUploadProgress?: ((progressEvent: AxiosProgressEvent) => void) | undefined
  ): Promise<IDocument | null>;
  Download(documentId: number): Promise<any | null>;
  Update(documentId: number, metadata: any): Promise<IDocument | null>;
}

export const DocumentServiceContext = createContext<IDocumentService | undefined>(undefined);

const DocumentService: FC = ({ children }: any) => {
  const apiHttpService = useContext(ApiHttpServiceContext);

  const documentService: IDocumentService = {
    async GetAll(libraryId: number) {
      return await apiHttpService!.Get<IDocument[]>(`libraries/${libraryId}/documents`);
    },
    async Delete(documentId: number) {
      return await apiHttpService!.Delete<void>(`documents/${documentId}`);
    },
    async Create(
      libraryId: number,
      files: FileList,
      cancelToken: CancelToken | undefined = undefined,
      onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined = undefined
    ) {
      var formData = new FormData();
      for (var i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      return await apiHttpService!.Post<IDocument>(
        `libraries/${libraryId}/documents`,
        formData,
        {},
        cancelToken,
        onUploadProgress,
        true
      );
    },
    async CreateVirtual(
      libraryId: number,
      file: File,
      url: string,
      cancelToken: CancelToken | undefined = undefined,
      onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined = undefined
    ) {
      var formData = new FormData();

      formData.append('file', file);

      formData.append('url', url);

      return await apiHttpService!.Post<IDocument>(
        `libraries/${libraryId}/virtual-document`,
        formData,
        {},
        cancelToken,
        onUploadProgress,
        true
      );
    },
    async Download(documentId: number) {
      return await apiHttpService!.Download<any>(`documents/${documentId}/download`);
    },
    async Update(documentId: number, metadata: any) {
      return await apiHttpService!.Put<IDocument>(`documents/${documentId}`, metadata);
    },
  };

  return (
    <DocumentServiceContext.Provider value={documentService}>
      {children}
    </DocumentServiceContext.Provider>
  );
};

export default DocumentService;
