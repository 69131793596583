import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import IBaseApp from "../../../Models/API/IBaseApp";
import { chatAppServiceContext } from "../../../Services/API/ChatAppService";
import IAppCatalog from "../../../Models/API/IAppCatalog";
import {
  FontWeights,
  IButtonStyles,
  IIconProps,
  IconButton,
  Link,
  Modal,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
  mergeStyleSets,
} from "@fluentui/react";
import { AppCatalogServiceContext } from "../../../Services/AppCatalogService";
import IChatApp from "../../../Models/API/IChatApp";
import ApplicationChat from "../../../Components/ApplicationChat/ApplicationChat";
import { automateAppServiceContext } from "../../../Services/API/AutomateAppService";
import IAutomateApp from "../../../Models/API/IAutomateApp";
import AutomateRun from "../../../Components/AutomateRun/AutomateRun";
import ReactMarkdown from "react-markdown";
import { SettingsServiceContext } from "../../../Services/API/SettingsService";
import ISettings from "../../../Models/API/ISettings";
import { trackPageView } from "../../../Services/AppInsights";
import { useTranslation } from "react-i18next";
import { LanguageServiceContext } from "../../../Services/LanguageService";

export interface IBaseAppProps {}

const BaseApp: FC<IBaseAppProps> = () => {
  const navigate = useNavigate();

  const { appCatalog } = useOutletContext<{
    appCatalog: IAppCatalog;
  }>();

  const appCatalogService = useContext(AppCatalogServiceContext);
  const chatAppService = useContext(chatAppServiceContext);
  const automateAppService = useContext(automateAppServiceContext);
  const settingsService = useContext(SettingsServiceContext);
  const languageService = useContext(LanguageServiceContext);

  let { type, appId } = useParams();

  const [selectedApp, setSelectedApp] = useState<IBaseApp | undefined>();
  const [loadingBaseApp, setLoadingBaseApp] = useState<boolean>();
  const [settings, setSettings] = useState<ISettings | null>();
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);

  const { t } = useTranslation();

  const renderApp = () => {
    if (type === selectedApp?.Type.toLowerCase()) {
      switch (type?.toLowerCase()) {
        case "chatapp":
          const chatApp = selectedApp as IChatApp;
          trackPageView();
          return (
            <ApplicationChat
              maxTokens={settings?.max_prompt_tokens!}
              chatApp={chatApp}
              showDescription
            />
          );
        case "automateapp":
          const automateApp = selectedApp as IAutomateApp;
          trackPageView();
          return <AutomateRun automateApp={automateApp} showDescription />;
      }
    }
  };

  useEffect(() => {
    const execute = async () => {
      const settings = await settingsService!.Get();
      setSettings(settings);
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const execute = async () => {
      if (appCatalog) {
        setLoadingBaseApp(true);
        let baseApp: IBaseApp | null | undefined = undefined;

        if (appId) {
          switch (type?.toLowerCase()) {
            case "chatapp":
              baseApp = await chatAppService?.Get(Number(appId));
              break;
            case "automateapp":
              baseApp = await automateAppService?.Get(Number(appId));
              break;
            default:
              navigate("/catalogs");
              break;
          }
        }

        if (baseApp) {
          setSelectedApp(baseApp);
          setLoadingBaseApp(false);
          appCatalogService?.ChangeNavTitle({
            title: appCatalog!.DisplayName,
            subtitle: baseApp.DisplayName,
          });
        }
      }
    };
    execute();
    setSelectedApp(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appCatalog, type, appId]);

  const theme = appCatalogService!.GetCurrentTheme();
  const contentStyles = mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      width: "60%",
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.themePrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "12px 12px 14px 24px",
      },
    ],
    heading: {
      color: theme.palette.themePrimary,
      fontWeight: FontWeights.semibold,
      fontSize: "inherit",
      margin: "0",
    },
    body: {
      flex: "4 4 auto",
      padding: "0 24px 24px 24px",
      overflowY: "hidden",
      selectors: {
        p: { margin: "14px 0" },
        "p:first-child": { marginTop: 0 },
        "p:last-child": { marginBottom: 0 },
      },
    },
  });

  const footerDisclaimer = () => {
    const regex = /.*(\{(.*)\}).*/gm;
    let m;
    let replaceKeyword;
    let originalText;
    let str = String(
      languageService?.language === "en"
        ? settings?.chat_jti_footer
        : settings?.[
            `chat_jti_footer_${languageService?.language}` as keyof ISettings
          ] ??
            settings?.chat_jti_footer ??
            ""
    );

    while ((m = regex.exec(str)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      replaceKeyword = m[1];
      originalText = m[2];
    }

    if (replaceKeyword && originalText) {
      var leftPart = str.substring(0, str.indexOf(replaceKeyword));
      var rightPart = str.substring(
        str.indexOf(replaceKeyword) + replaceKeyword.length
      );
      return (
        <>
          <Text>{leftPart}</Text>
          <Link onClick={() => setShowDisclaimer(true)}>{originalText}</Link>
          <Text>{rightPart}</Text>
        </>
      );
    } else {
      return (
        <>
          <Text>
            {String(
              languageService?.language === "en"
                ? settings?.chat_jti_footer
                : settings?.[
                    `chat_jti_footer_${languageService?.language}` as keyof ISettings
                  ] ??
                    settings?.chat_jti_footer ??
                    ""
            )}
          </Text>
        </>
      );
    }
  };

  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: "auto",
      marginTop: "4px",
      marginRight: "2px",
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0 }}>
        <StackItem grow verticalFill style={{ minHeight: 0 }}>
          {!loadingBaseApp ? (
            <>
              {selectedApp ? (
                <>{renderApp()}</>
              ) : (
                <>{t("CATALOG.NO_BASE_APP_SELECTED")}</>
              )}
            </>
          ) : (
            <Spinner size={SpinnerSize.large} />
          )}
        </StackItem>
        {settings && <StackItem align="center">{footerDisclaimer()}</StackItem>}
      </Stack>
      {settings && (
        <Modal
          isOpen={showDisclaimer}
          onDismiss={() => setShowDisclaimer(false)}
          isBlocking={true}
          containerClassName={contentStyles.container}
        >
          <div className={contentStyles.header}>
            <h2 className={contentStyles.heading}>
              Chat JTI {t("MAIN.DISCLAIMER")}
            </h2>
            <IconButton
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={() => setShowDisclaimer(false)}
            />
          </div>
          <div className={contentStyles.body}>
            <ReactMarkdown linkTarget={"_blank"}>
              {String(
                languageService?.language === "en"
                  ? settings?.chat_jti_disclaimer
                  : (settings as any)[
                      `chat_jti_disclaimer_${languageService?.language}`
                    ] ??
                      settings?.chat_jti_disclaimer ??
                      ""
              )}
            </ReactMarkdown>
          </div>
        </Modal>
      )}
    </>
  );
};

const cancelIcon: IIconProps = { iconName: "Cancel" };

export default BaseApp;
