import { FC, useState } from 'react';
import IMetadataField, { IMetadataFieldValueType } from '../../../Models/API/IMetadataField';
import {
  DatePicker,
  DayOfWeek,
  Dropdown,
  IconButton,
  Label,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';

type MetadataFieldProps = {
  label?: string;
  field: IMetadataField;
  value: IMetadataFieldValueType;
  setValue: (value: IMetadataFieldValueType) => void;
};

const MetadataField: FC<MetadataFieldProps> = ({
  label,
  field,
  value,
  setValue,
}: MetadataFieldProps) => {
  const [temp, setTemp] = useState<string>('');

  const { t } = useTranslation();

  return (
    <>
      <Label>
        {label ?? field.DisplayName} {!label && field.Required && '*'}
      </Label>
      {field.Type === 'String' && (
        <TextField
          value={value as string}
          onChange={(event, newValue) => setValue(newValue && newValue !== '' ? newValue : null)}
        ></TextField>
      )}
      {field.Type === 'DateTime' && (
        <Stack horizontal>
          <StackItem grow>
            <DatePicker
              firstDayOfWeek={DayOfWeek.Monday}
              onSelectDate={date => {
                setValue(date);
              }}
              value={value ? new Date(value as string) : undefined}
              placeholder={t('MAIN.SELECT_DATE')}
              ariaLabel='Select a date'
            />
          </StackItem>
          <IconButton
            title='Clear field'
            onClick={() => {
              setValue(null);
            }}
            iconProps={{ iconName: 'Delete' }}
          ></IconButton>
        </Stack>
      )}
      {field.Type === 'Boolean' && (
        <Dropdown
          options={[
            { key: 'True', text: t('MAIN.YES') },
            { key: 'False', text: t('MAIN.NO') },
          ]}
          selectedKey={value ? 'True' : 'False'}
          onChange={(evt, option, index) => {
            setValue(option!.key === 'True' ? true : false);
          }}
        />
      )}
      {field.Type === 'Integer' && (
        <TextField
          value={value as string}
          onChange={(evt, newValue) =>
            setValue(isNaN(parseInt(newValue!)) ? null : parseInt(newValue!))
          }
        />
      )}
      {field.Type === 'Double' && (
        <TextField
          value={value as string}
          onChange={(evt, newValue) => {
            setValue(isNaN(parseFloat(newValue!)) ? null : parseFloat(newValue!));
          }}
        />
      )}
      <>
        {field.Type === 'StringList' &&
          (value ? (value as string[]) : []).map((val, index) => {
            return (
              <Stack style={{ marginBottom: 5 }} key={`stringlist_${index}`} horizontal grow>
                <Stack.Item grow>
                  <TextField value={val} readOnly></TextField>
                </Stack.Item>
                <IconButton
                  title='Remove'
                  onClick={() => {
                    setValue((value as string[]).filter((val, subIndex) => subIndex !== index));
                  }}
                  iconProps={{ iconName: 'Delete' }}
                ></IconButton>
              </Stack>
            );
          })}
        {field.Type === 'StringList' && (
          <Stack horizontal grow>
            <Stack.Item grow>
              <TextField
                placeholder={t('MAIN.TYPE_VALUE_AND_ADD')}
                value={temp}
                onChange={(event, newValue) => setTemp(newValue!)}
              ></TextField>
            </Stack.Item>
            <IconButton
              title='Add'
              onClick={() => {
                if (temp) {
                  setValue([...(value ? (value as string[]) : []), temp!]);
                  setTemp('');
                }
              }}
              iconProps={{ iconName: 'CircleAddition' }}
            ></IconButton>
          </Stack>
        )}
      </>
    </>
  );
};

export default MetadataField;
