import "./AIAppsStart.scss";
import { Layer, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import Toast from "../../../Components/Toast/Toast";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import Role from "../../../Components/Role/Role";
import AIAppsCatalog from "../../AIAppsCatalog/AIAppsCatalog";
import { Navigation } from "../../../Components/AIApps/Navigation/Navigation";
import { maxWebsiteWidth } from "../AppStart";
import { Footer } from "../../../Components/AIApps/Footer/Footer";
import AIApps from "../../AIAppsCatalog/Apps/AIApps";
import AIApp from "../../AIAppsCatalog/Apps/App/AIApp";
import Default from "../../AIAppsCatalog/Apps/ApiReference/Default";

export interface IAppStartProps {
  loading: boolean;
}

export const AIAppsStart: FC<IAppStartProps> = ({ loading }: any) => {
  return (
    <>
      {(!loading && (
        <>
          <Layer>
            <Stack horizontalAlign="end">
              <Stack.Item>
                <Toast />
              </Stack.Item>
            </Stack>
          </Layer>
          <Stack
            horizontalAlign="center"
            grow
            verticalFill
            style={{ height: "100%", minHeight: 0 }}
          >
            <Stack style={{ maxWidth: maxWebsiteWidth, width: "100%" }}>
              <Navigation />
            </Stack>
            <Stack
              grow
              verticalFill
              style={{
                minHeight: 0,
                maxWidth: maxWebsiteWidth,
                width: "100%",
              }}
              tokens={{ padding: "0 2rem" }}
            >
              <Routes>
                <Route path="">
                  <Route index element={<AIAppsCatalog />}></Route>
                  <Route path="apps">
                    <Route index element={<Role children={<AIApps />} />} />
                    <Route path=":appId" element={<Role children={<AIApp />} />}></Route>
                    <Route path=":appId/docs" element={<Default></Default>}></Route>
                  </Route>
                </Route>
              </Routes>
            </Stack>
            <Stack className="footer-row" horizontalAlign="center" style={{ width: "100%" }}>
              <Footer />
            </Stack>
          </Stack>
        </>
      )) || (
        <Stack style={{ height: "100%" }} verticalAlign="center">
          <Spinner size={SpinnerSize.large} />
        </Stack>
      )}
    </>
  );
};
