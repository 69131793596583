import {
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { FC, useContext, useEffect, useState } from 'react';
import { SettingsServiceContext } from '../../../Services/API/SettingsService';
import ISettings from '../../../Models/API/ISettings';
import { DialogServiceContext } from '../../../Services/Dialogs/DialogService';
import moment from 'moment';
import { trackPageView } from '../../../Services/AppInsights';
import { useTranslation } from 'react-i18next';

const ToolsSettings: FC = () => {
  const settingsService = useContext(SettingsServiceContext);
  const dialogService = useContext(DialogServiceContext);

  const [settings, setSettings] = useState<ISettings | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [currentKey, setCurrentKey] = useState<string>('search');

  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const execute = async () => {
      var reply = await settingsService!.Get();
      if (isMounted) setSettings(reply);
    };
    execute();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSettings = async () => {
    setSaving(true);
    await settingsService?.Save(settings!);
    dialogService?.showSuccessDialog(
      t('SETTINGS.SETTINGS_SAVED'),
      t('SETTINGS.SETTINGS_SAVED_DESCRIPTION')
    );
    setSaving(false);
  };

  return (
    (settings && (
      <>
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack.Item>
            <Pivot
              aria-label='Chat App Options'
              selectedKey={currentKey}
              onLinkClick={item => setCurrentKey(item?.props.itemKey!)}
            >
              <PivotItem itemKey='chitchat' headerText={t('SETTINGS.CHIT_CHAT')} itemIcon='Chat'>
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.chitchat_tool_description}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        chitchat_tool_description: event.currentTarget.value,
                      })
                    }
                    label={t('MAIN.DESCRIPTION')}
                    multiline
                    rows={4}
                  />
                </Stack.Item>
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.chitchat_tool_parameters}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        chitchat_tool_parameters: event.currentTarget.value,
                      })
                    }
                    label={t('SETTINGS.PARAMETERS')}
                    multiline
                    rows={20}
                  />
                </Stack.Item>
              </PivotItem>

              <PivotItem itemKey='searchweb' headerText={t('SETTINGS.SEARCH_WEB')} itemIcon='World'>
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.searchweb_tool_description}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        searchweb_tool_description: event.currentTarget.value,
                      })
                    }
                    label={t('SETTINGS.DESCRIPTION')}
                    multiline
                    rows={8}
                  />
                </Stack.Item>
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.searchweb_tool_parameters}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        searchweb_tool_parameters: event.currentTarget.value,
                      })
                    }
                    label={t('SETTINGS.PARAMETERS')}
                    multiline
                    rows={20}
                  />
                </Stack.Item>
              </PivotItem>

              <PivotItem
                itemKey='searchdatabase'
                headerText={t('SETTINGS.SEARCH_DATABASE')}
                itemIcon='DocumentSearch'
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.searchdatabase_tool_description}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        searchdatabase_tool_description: event.currentTarget.value,
                      })
                    }
                    label={t('MAIN.DESCRIPTION')}
                    multiline
                    rows={4}
                  />
                </Stack.Item>
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.searchdatabase_tool_parameters}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        searchdatabase_tool_parameters: event.currentTarget.value,
                      })
                    }
                    label={t('SETTINGS.PARAMETERS')}
                    multiline
                    rows={20}
                  />
                </Stack.Item>
              </PivotItem>

              <PivotItem
                itemKey='informationmissing'
                headerText={t('SETTINGS.INFORMATION_MISSING')}
                itemIcon='Feedback'
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.informationmissing_tool_description}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        informationmissing_tool_description: event.currentTarget.value,
                      })
                    }
                    label={t('MAIN.DESCRIPTION')}
                    multiline
                    rows={4}
                  />
                </Stack.Item>
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.informationmissing_tool_parameters}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        informationmissing_tool_parameters: event.currentTarget.value,
                      })
                    }
                    label={t('SETTINGS.PARAMETERS')}
                    multiline
                    rows={20}
                  />
                </Stack.Item>
              </PivotItem>

              <PivotItem
                itemKey='summarizedocument'
                headerText={t('SETTINGS.SUMMARIZE_DOCUMENT')}
                itemIcon='TextDocument'
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.summarizedocument_tool_description}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        summarizedocument_tool_description: event.currentTarget.value,
                      })
                    }
                    label={t('MAIN.DESCRIPTION')}
                    multiline
                    rows={4}
                  />
                </Stack.Item>
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.summarizedocument_tool_parameters}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        summarizedocument_tool_parameters: event.currentTarget.value,
                      })
                    }
                    label={t('SETTINGS.PARAMETERS')}
                    multiline
                    rows={20}
                  />
                </Stack.Item>
              </PivotItem>

              <PivotItem
                itemKey='userintent'
                headerText={t('SETTINGS.USER_INTENT')}
                itemIcon='UserOptional'
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.userintent_tool_description}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        userintent_tool_description: event.currentTarget.value,
                      })
                    }
                    label={t('MAIN.DESCRIPTION')}
                    multiline
                    rows={4}
                  />
                </Stack.Item>
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.userintent_tool_parameters}
                    onChange={event =>
                      setSettings({
                        ...settings,
                        userintent_tool_parameters: event.currentTarget.value,
                      })
                    }
                    label={t('SETTINGS.PARAMETERS')}
                    multiline
                    rows={20}
                  />
                </Stack.Item>
              </PivotItem>
            </Pivot>
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                onClick={saveSettings}
                text={saving ? `${t('MAIN.SAVING')}...` : t('MAIN.SAVE')}
                allowDisabledFocus
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default ToolsSettings;
