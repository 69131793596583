import React, { FC, createContext, useContext } from "react";
import { SpoHttpServiceContext } from "./SpoHttpService";

export interface ISpoService {
  GetAllSpoSites(): Promise<ISharePointSite[] | null>;
  SearchSpoSites(searchTerm: string): Promise<ISharePointSite[] | null>;
  GetSpoSitesPaginated(
    skip: number,
    top: number
  ): Promise<ISharePointSite[] | null>;
  GetAllSpoLibraries(siteId: string): Promise<ISharePointLibrary[] | null>;
  GetLibraryWebUrl(siteId: string, libraryId: string): Promise<string | null>;
  GetSpoLibrariesPaginated(
    siteId: string,
    skip: number,
    top: number
  ): Promise<ISharePointLibrary[] | null>;
}

export const spoServiceContext = createContext<ISpoService | undefined>(
  undefined
);

const SpoService: FC = ({ children }: any) => {
  const spoHttpService = useContext(SpoHttpServiceContext);

  const spoService: ISpoService = {
    async GetAllSpoSites(): Promise<ISharePointSite[] | null> {
      let sites: ISharePointSite[] = [];
      let nextLink:
        | string
        | null = `https://graph.microsoft.com/v1.0/sites?search=*`;

      try {
        while (nextLink) {
          const response: any = await spoHttpService!.Get<{
            value: any[];
            "@odata.nextLink"?: string;
          }>(nextLink);

          if (!response || !response.value) {
            break;
          }

          // Map the response to ISharePointSite objects
          sites = [
            ...sites,
            ...response.value.map((site: any) => ({
              id: site.id,
              name: site.name,
              displayName: site.displayName,
              webUrl: site.webUrl,
              createdDateTime: site.createdDateTime,
              lastModifiedDateTime: site.lastModifiedDateTime,
              description: site.description || null,
            })),
          ];

          // Update nextLink to fetch the next page, if available
          nextLink = response["@odata.nextLink"] || null;
        }

        return sites;
      } catch (error) {
        console.error("Error fetching SharePoint sites:", error);
        return null;
      }
    },
    async SearchSpoSites(
      searchTerm: string
    ): Promise<ISharePointSite[] | null> {
      let sites: ISharePointSite[] = [];
      let link = `https://graph.microsoft.com/v1.0/sites?search=${searchTerm}`;

      try {
        const response: any = await spoHttpService!.Get<{
          value: any[];
          "@odata.nextLink"?: string;
        }>(link);

        if (!response || !response.value) {
          return [];
        }

        // Map the response to ISharePointSite objects
        sites = [
          ...sites,
          ...response.value.map((site: any) => ({
            id: site.id,
            name: site.name,
            displayName: site.displayName,
            webUrl: site.webUrl,
            createdDateTime: site.createdDateTime,
            lastModifiedDateTime: site.lastModifiedDateTime,
            description: site.description || null,
          })),
        ];

        return sites;
      } catch (error) {
        console.error("Error fetching SharePoint sites:", error);
        return null;
      }
    },
    async GetLibraryWebUrl(
      siteId: string,
      libraryId: string
    ): Promise<string | null> {
      let nextLink:
        | string
        | null = `https://graph.microsoft.com/v1.0/sites/${siteId}/lists/${libraryId}?$select=webUrl`;
      let webUrl: string | null = null;

      try {
        while (nextLink) {
          const response: any = await spoHttpService!.Get<{
            value: any[];
            "@odata.nextLink"?: string;
          }>(nextLink);

          if (!response || !response.webUrl) {
            break;
          }

          webUrl = response.webUrl;

          // Update nextLink to fetch the next page, if available
          nextLink = response["@odata.nextLink"] || null;
        }

        return webUrl;
      } catch (error) {
        console.error("Error fetching SharePoint library web url:", error);
        return null;
      }
    },

    async GetSpoSitesPaginated(
      skip: number = 0,
      top: number = 10
    ): Promise<ISharePointSite[] | null> {
      try {
        const response = await spoHttpService!.Get<{ value: any[] }>(
          `https://graph.microsoft.com/v1.0/sites?search=*&$top=${top}&$skip=${skip}`
        );

        if (!response || !response.value) {
          return null;
        }

        return response.value.map((site) => ({
          id: site.id,
          name: site.name,
          displayName: site.displayName,
          webUrl: site.webUrl,
          createdDateTime: site.createdDateTime,
          lastModifiedDateTime: site.lastModifiedDateTime,
          description: site.description || null,
        })) as ISharePointSite[];
      } catch (error) {
        console.error("Error fetching paginated SharePoint sites:", error);
        return null;
      }
    },

    async GetAllSpoLibraries(
      siteId: string
    ): Promise<ISharePointLibrary[] | null> {
      let libraries: ISharePointLibrary[] = [];
      let nextLink:
        | string
        | null = `https://graph.microsoft.com/v1.0/sites/${siteId}/drives?$expand=list($select=id)`;

      try {
        while (nextLink) {
          const response: any = await spoHttpService!.Get<{
            value: any[];
            "@odata.nextLink"?: string;
          }>(nextLink);

          if (!response || !response.value) {
            break;
          }

          // Map the response to ISharePointLibrary objects
          libraries = [
            ...libraries,
            ...response.value.map((library: any) => {
              return {
                id: library.list.id,
                name: library.name,
                driveType: library.driveType,
                webUrl: library.webUrl,
                createdDateTime: library.createdDateTime,
                lastModifiedDateTime: library.lastModifiedDateTime,
                description: library.description || null,
              };
            }),
          ];

          // Update nextLink to fetch the next page, if available
          nextLink = response["@odata.nextLink"] || null;
        }

        return libraries;
      } catch (error) {
        console.error("Error fetching SharePoint libraries:", error);
        return null;
      }
    },

    async GetSpoLibrariesPaginated(
      siteId: string,
      skip: number = 0,
      top: number = 10
    ): Promise<ISharePointLibrary[] | null> {
      try {
        const response = await spoHttpService!.Get<{ value: any[] }>(
          `https://graph.microsoft.com/v1.0/sites/${siteId}/drives?$top=${top}&$skip=${skip}`
        );

        if (!response || !response.value) {
          return null;
        }

        return response.value.map((library) => ({
          id: library.id,
          name: library.name,
          driveType: library.driveType,
          webUrl: library.webUrl,
          createdDateTime: library.createdDateTime,
          lastModifiedDateTime: library.lastModifiedDateTime,
          description: library.description || null,
        })) as ISharePointLibrary[];
      } catch (error) {
        console.error("Error fetching paginated SharePoint libraries:", error);
        return null;
      }
    },
  };

  return (
    <spoServiceContext.Provider value={spoService}>
      {children}
    </spoServiceContext.Provider>
  );
};

export default SpoService;

export interface ISharePointSite {
  id: string;
  name: string;
  displayName: string;
  webUrl: string;
  createdDateTime?: string;
  lastModifiedDateTime?: string;
  description?: string;
}

export interface ISharePointLibrary {
  id: string;
  name: string;
  driveType: string;
  webUrl: string;
  createdDateTime?: string;
  lastModifiedDateTime?: string;
  description?: string;
}
