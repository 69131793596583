import React, { FC, useContext, useEffect, useState } from "react";
import "./Settings.scss";
import {
  INavLink,
  INavLinkGroup,
  INavStyles,
  Nav,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SettingsServiceContext } from "../../Services/API/SettingsService";
import ISettings from "../../Models/API/ISettings";
import ConfigurationService from "../../Services/ConfigurationService";
import { t, TFunction } from "i18next";
import { useTranslation } from "react-i18next";

const navStyles: Partial<INavStyles> = {
  root: {
    width: 208,
    boxSizing: "border-box",
    overflowY: "auto",
  },
};

const navLinkGroups = ( t: TFunction<"translation", undefined, "translation"> ) => [
  {
    links: [
      {
        name: t("SETTINGS.CHAT"),
        url: "chat",
        key: "chat",
      },
      {
        name: "Chat JTI",
        url: "chatjti",
        key: "chatjti",
      },
      {
        name: `${t("SETTINGS.PROMPTS")} V2`,
        url: "prompts-v2",
        key: "prompts-v2",
      },
      {
        name: `${t("SETTINGS.PROMPTS")} V1`,
        url: "prompts-v1",
        key: "prompts-v1",
      },
      {
        name: t("SETTINGS.TOOLS"),
        url: "tools",
        key: "tools",
      },
      {
        name: t("SETTINGS.SEARCH"),
        url: "search",
        key: "search",
      },
      {
        name: t("SETTINGS.TRAINING"),
        url: "training",
        key: "training",
      },
      {
        name: t("SETTINGS.ANALYTICS"),
        url: "analytics",
        key: "analytics",
      },
      {
        name: t("SETTINGS.ARTIFACTS"),
        url: "artifacts",
        key: "artifacts",
      },
    ],
  },
] satisfies INavLinkGroup[];

const Settings: FC = () => {
  const settingsService = useContext(SettingsServiceContext);
  const [settings, setSettings] = useState<ISettings | null>(null);
  const [isMounted, setIsMounted] = useState<boolean>(true);

  const { t } = useTranslation();

  useEffect(() => {
    const execute = async () => {
      const reply = await settingsService!.Get();
      if (isMounted) setSettings(reply);
    };
    execute();
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - Settings`;
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const route =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  const _onLinkClick = (
    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ) => {
    ev?.preventDefault();
    navigate(item?.url!, {
      relative: "route",
    });
  };

  return (
    <Stack grow verticalFill style={{ minHeight: 0 }} horizontalAlign="center">
      <Stack
        verticalFill
        grow
        style={{ minHeight: 0, width: "100%" }}
        tokens={{
          childrenGap: 20,
        }}
      >
        <Stack horizontal verticalFill>
          <Stack.Item shrink>
            <Nav
              onLinkClick={_onLinkClick}
              selectedKey={route}
              ariaLabel="Nav basic example"
              styles={navStyles}
              groups={navLinkGroups(t)}
            />
          </Stack.Item>
          <Stack.Item grow shrink style={{ padding: 10, overflowY: "scroll" }}>
            {(settings && <Outlet />) || <Spinner size={SpinnerSize.large} />}
          </Stack.Item>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Settings;
