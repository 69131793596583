import { FC, useContext, useEffect, useState } from "react";
import ILibrary from "../../../../../../../../Models/API/ILibrary";
import { useOutletContext } from "react-router-dom";
import {
  CommandBar,
  ICommandBarItemProps,
  SearchBox,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  Selection,
  SelectionMode,
  IColumn,
  DetailsList,
  IconButton,
  Link,
} from "@fluentui/react";
import IDocument from "../../../../../../../../Models/API/IDocument";
import { DocumentServiceContext } from "../../../../../../../../Services/API/DocumentService";
import moment from "moment";
import { Icon } from "@fluentui/react";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import "./Documents.scss";
import { removeDiacritics } from "../../../../../../../../Utilities/Strings";
import { trackPageView } from "../../../../../../../../Services/AppInsights";
import ConfigurationService from "../../../../../../../../Services/ConfigurationService";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { LanguageServiceContext } from "../../../../../../../../Services/LanguageService";
import { spoServiceContext } from "../../../../../../../../Services/API/SpoService";
import ISpLibrary from "../../../../../../../../Models/API/ISpLibrary";

const shimmerColumns = (
  t: TFunction<"translation", undefined, "translation">
) =>
  [
    {
      key: "Icon",
      name: "",
      minWidth: 20,
      maxWidth: 20,
      isResizable: false,
    },
    {
      key: "FileName",
      name: t("LIBRARIES.FILE_NAME"),
      minWidth: 50,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: "Title",
      name: t("MAIN.TITLE"),
      minWidth: 100,
      isResizable: true,
    },
    {
      key: "State",
      name: t("MAIN.STATE"),
      minWidth: 100,
      maxWidth: 100,
      isResizable: false,
    },
    {
      key: "Updated", // what the field exactly should be
      name: t("MAIN.LAST_SYNC"),
      minWidth: 100,
      maxWidth: 100,
      isResizable: false,
    },
  ] satisfies IColumn[];

const SPLibraryDocuments: FC = () => {
  const documentsService = useContext(DocumentServiceContext);
  const languageService = useContext(LanguageServiceContext);
  const spoService = useContext(spoServiceContext);
  const { library } = useOutletContext<{ library: ISpLibrary }>();
  const [documents, setDocuments] = useState<IDocument[] | null>(null);
  const [filteredDocuments, setFilteredDocuments] = useState<
    IDocument[] | null | undefined
  >(undefined);
  // eslint-disable-next-line
  const [selectedDocuments, setSelectedDocuments] = useState<IDocument[]>([]);
  const [commandBarButtons, setCommandBarButtons] = useState<
    ICommandBarItemProps[]
  >([]);
  const [filterKeyword, setFilterKeyword] = useState<string | undefined>(
    undefined
  );
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reloadDocuments();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Runs when the filter keyword changes
  useEffect(() => {
    if (documents && filterKeyword !== undefined) {
      setFilteredDocuments(
        filterKeyword
          ? documents?.filter((doc) => {
              return (
                removeDiacritics(doc.FileName.toLowerCase()).indexOf(
                  removeDiacritics(filterKeyword.toLowerCase())
                ) !== -1 ||
                removeDiacritics(doc.Metadata["title"].toLowerCase()).indexOf(
                  removeDiacritics(filterKeyword.toLowerCase())
                ) !== -1
              );
            })
          : documents
      );
    }
  }, [filterKeyword, documents]);

  const reloadDocuments = async () => {
    if (isMounted) setDocuments([]);
    if (isMounted) setFilteredDocuments(undefined);
    let docs = await documentsService!.GetAll(library.Id);
    docs = sortDocuments(docs!);
    if (isMounted) setFilteredDocuments(docs);
    if (isMounted) setDocuments(docs);
  };

  const sortDocuments = (docs: IDocument[]): IDocument[] => {
    return docs.sort((docA, docB) => {
      const dateComparison =
        new Date(docB.Updated).getTime() - new Date(docA.Updated).getTime();
      if (dateComparison !== 0) {
        // If dates are different, use date comparison
        return dateComparison;
      }
      // If dates are the same, sort by FileName (ascending)
      return docA.FileName.localeCompare(docB.FileName);
    });
  };

  const openLibrary = async () => {
    try {
      const link = await spoService?.GetLibraryWebUrl(
        library.SiteId,
        library.LibraryId
      );
      if (link) {
        window.open(link, "_blank");
      } else {
        console.error("Failed to retrieve the library link.");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching the library link:",
        error
      );
    }
  };

  // Runs when the selected documents change
  useEffect(() => {
    let barButtons: ICommandBarItemProps[] = [];

    barButtons = [
      ...barButtons,
      {
        key: "open_in_sharepoint",
        text: t("CATALOG.SHOW_IN_SHAREPOINT"),
        iconProps: { iconName: "SharepointLogo" },
        onClick: (_) => {
          openLibrary();
        },
      },
      {
        key: "refresh",
        text: t("MAIN.REFRESH"),
        iconProps: { iconName: "Refresh" },
        onClick: (_) => {
          resetState();
          reloadDocuments();
        },
      },
    ];

    setCommandBarButtons(barButtons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageService?.language]);

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedDocuments(selection.getSelection() as IDocument[]);
    },
  });

  const documentUrl = (document: IDocument) => {
    return `${ConfigurationService.Default.Configuration.API?.BaseUri}/documents/${document.Id}/open`;
  };

  const openDocument = (document: IDocument) => {
    window.open(documentUrl(document), "_blank");
  };

  const onRenderItemColumn = (
    item: IDocument,
    index?: number,
    column?: IColumn
  ): any => {
    if (column?.key === "Icon") {
      return (
        <Icon
          {...getFileTypeIconProps({
            extension:
              item.FileName.split(".")[item.FileName.split(".").length - 1],
            size: 20,
            imageFileType: "svg",
          })}
        />
      );
    } else if (column?.key === "Title") {
      return <span title={item.Metadata.title}>{item.Metadata.title}</span>;
    } else if (column?.key === "FileName") {
      return (
        <Link title={item.FileName} onClick={() => openDocument(item)}>
          {item.FileName}
        </Link>
      );
    } else if (column?.key === "State") {
      return (
        <>
          {/* @ts-ignore */}
          <span>{t(`STATUS.${(item.State as string).toUpperCase()}`)}</span>
          {item.State === "Error" && (
            <IconButton
              title={item.ErrorMessage}
              style={{ height: 12 }}
              iconProps={{ iconName: "Warning" }}
              menuIconProps={{ style: { display: "none" } }}
            ></IconButton>
          )}
        </>
      );
    } else if (column?.key === "Updated") {
      // return moment().format('DD MMM yyyy');
      return moment(item.Updated + "Z").fromNow();
    } else {
      return item![column?.key as keyof IDocument];
    }
  };

  const resetState = (): void => {
    setDocuments([]);
    setFilteredDocuments(undefined);
    setSelectedDocuments([]);
    setFilterKeyword(undefined);
  };

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0 }}>
        <Stack.Item>
          <CommandBar items={commandBarButtons} ariaLabel="Library actions" />
          <SearchBox
            value={filterKeyword}
            onChange={(evt, newValue) => setFilterKeyword(newValue)}
            placeholder={t("FILTERS.FILTER_BY_FILE_NAME_OR_TITLE")}
            iconProps={{ iconName: "Filter" }}
            underlined={true}
          />
        </Stack.Item>
        <Stack.Item verticalFill style={{ minHeight: 0, overflowY: "auto" }}>
          <DetailsList
            setKey="documents"
            items={filteredDocuments || []}
            columns={shimmerColumns(t)}
            selectionMode={SelectionMode.multiple}
            onRenderItemColumn={onRenderItemColumn}
            ariaLabelForGrid="Documents"
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            className="documents-list"
          />
          {(filteredDocuments === undefined ||
            filteredDocuments?.length === 0) && (
            <>
              {filteredDocuments?.length === 0 && (
                <Text
                  variant="large"
                  block
                  style={{
                    textAlign: "center",
                    opacity: 0.5,
                  }}
                >
                  {t("LIBRARIES.NO_DOCUMENTS")}
                </Text>
              )}
              {filteredDocuments === undefined && (
                <Spinner size={SpinnerSize.large} />
              )}
            </>
          )}
        </Stack.Item>
      </Stack>
    </>
  );
};

export default SPLibraryDocuments;
