import { Image, PrimaryButton, Stack, Text } from '@fluentui/react';
import { FC, useEffect } from 'react';
import './AIAppsCatalog.scss';
import jti_fade from '../../Assets/Images/aiappscatalog/jti_fade.png';
import mesh from '../../Assets/Images/aiappscatalog/mesh.png';
import { useNavigate } from 'react-router-dom';
import { trackPageView } from '../../Services/AppInsights';
import ConfigurationService from '../../Services/ConfigurationService';
import { useTranslation } from 'react-i18next';

const AIAppsCatalog: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - AI Service Catalog`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      className='ai-apps-home'
      verticalAlign='center'
      grow
      horizontal
      style={{ minHeight: 0, overflowY: 'auto' }}
    >
      <Stack style={{ maxWidth: '50%' }}>
        <Image className='image' src={jti_fade}></Image>
        <Text block variant='large' className='title'>
          {t('AI_APP_CATALOG.GET_STARTED')} <strong>{t('AI_APP_CATALOG.TITLE')}</strong>
        </Text>
        <Text block className='description'>
          {t('AI_APP_CATALOG.DESCRIPTION_BLOCK_1')}
        </Text>
        <Text block className='description'>
          {t('AI_APP_CATALOG.DESCRIPTION_BLOCK_2')}
        </Text>
        <Text block className='description'>
          {t('AI_APP_CATALOG.DESCRIPTION_BLOCK_3')}
        </Text>
        <PrimaryButton onClick={_ => navigate({ pathname: 'apps' })} style={{ maxWidth: 200 }}>
          {t('AI_APP_CATALOG.DISCOVER')}
        </PrimaryButton>
      </Stack>
      <Stack style={{ width: '50%' }} verticalFill verticalAlign='end'>
        <Image className='mesh' src={mesh}></Image>
      </Stack>
    </Stack>
  );
};

export default AIAppsCatalog;
