import { FC, useEffect, useState } from 'react';
import {
  Text,
  Stack,
  StackItem,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
} from '@fluentui/react';
import {
  DocumentLogEntry,
  MigrationLogEntry,
  ProcessImportArtifactsResponse,
} from '../../Models/API/IArtifactsJobResponse';
import { useTranslation } from 'react-i18next';

export interface IImportArtifactReportProps {
  reportResults: string | null;
}

const ImportArtifactReport: FC<IImportArtifactReportProps> = ({ reportResults }) => {
  const [report, setReport] = useState<ProcessImportArtifactsResponse | null>(null);
  const [selectedLogEntry, setSelectedLogEntry] = useState<MigrationLogEntry | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (reportResults) {
      const parsedReport = JSON.parse(reportResults);
      setReport(parsedReport);
      setSelectedLogEntry(parsedReport.MigrationLogEntries[0]);
    }
  }, [reportResults]);

  // Columns for the document log entries table
  const documentLogColumns: IColumn[] = [
    {
      key: 'documentName',
      name: t('ARTIFACTS.DOCUMENT_NAME'),
      fieldName: 'DocumentName',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'success',
      name: t('MAIN.SUCCESS'),
      fieldName: 'Success',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: DocumentLogEntry) => (item.Success ? t('MAIN.YES') : t('MAIN.NO')),
    },
    {
      key: 'errorMessage',
      name: t('MAIN.ERROR_MESSAGE'),
      fieldName: 'ErrorMessage',
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: DocumentLogEntry) => item.ErrorMessage || t('MAIN.NA'),
    },
  ];

  return (
    <Stack horizontal tokens={{ childrenGap: 20 }} styles={{ root: { padding: 20 } }}>
      <StackItem styles={{ root: { width: '300px' } }}>
        {report && (
          <Stack>
            <Text variant='large' block>
              <strong>{t('ARTIFACTS.IMPORT_SUMMARY')}</strong>
            </Text>
            <Text style={{ marginBottom: '5px' }}>
              {t('ARTIFACTS.TOTAL_LIBRARIES_CREATED')}: {report.Summary.TotalLibrariesCreated}
            </Text>
            <Text style={{ marginBottom: '5px' }}>
              {t('ARTIFACTS.TOTAL_LIBRARIES_RECREATED')}: {report.Summary.TotalLibrariesRecreated}
            </Text>
            <Text style={{ marginBottom: '5px' }}>
              {t('ARTIFACTS.TOTAL_LIBRARIES_UPDATED')}: {report.Summary.TotalLibrariesUpdated}
            </Text>
            <Text style={{ marginBottom: '5px' }}>
              {t('ARTIFACTS.TOTAL_LIBRARIES_NOT_IN_SOURCE')}: {report.Summary.TotalLibrariesNotInSource}
            </Text>
            <Text style={{ marginBottom: '5px' }}>
              {t('ARTIFACTS.TOTAL_DOCUMENTS_PROCESSED')}: {report.Summary.TotalDocumentsProcessed}
            </Text>
            <Text style={{ marginBottom: '5px' }}>
              {t('ARTIFACTS.TOTAL_DOCUMENTS_FAILED')}: {report.Summary.TotalDocumentsFailed}
            </Text>

            <Stack tokens={{ childrenGap: 10 }} styles={{ root: { marginTop: 20 } }}>
              <Text variant='large' block>
                <strong>{t('ARTIFACTS.MIGRATION_LOG_ENTRIES')}</strong>
              </Text>
              {report.MigrationLogEntries.filter(logEntry => logEntry.Action !== 'NotInSource').map(
                (logEntry, index) => (
                  <Text
                    key={index}
                    variant='medium'
                    onClick={() => setSelectedLogEntry(logEntry)}
                    styles={{
                      root: {
                        cursor: 'pointer',
                        textDecoration: selectedLogEntry === logEntry ? 'underline' : 'none',
                      },
                    }}
                  >
                    {logEntry.LibraryTitle} ({logEntry.Action} -{' '}
                    {logEntry.Success ? t('MAIN.SUCCESS') : t('MAIN.FAILED')})
                  </Text>
                )
              )}
            </Stack>
          </Stack>
        )}
      </StackItem>

      <StackItem grow={3} styles={{ root: { width: 'calc(100% - 320px)' } }}>
        {selectedLogEntry ? (
          <Stack>
            <Text variant='large' block>
              <strong>{t('ARTIFACTS.MIGRATION_LOG_ENTRY_DETAILS')} {selectedLogEntry.LibraryTitle}</strong>
            </Text>
            <Stack>
              <Text style={{ marginBottom: '5px' }}>{t('MAIN.ACTION')}: {selectedLogEntry.Action}</Text>
              <Text style={{ marginBottom: '5px' }}>
                {t('MAIN.SUCCESS')}: {selectedLogEntry.Success ? t('MAIN.YES') : t('MAIN.NO')}
              </Text>
              {selectedLogEntry.ErrorMessage && (
                <Text>{t('MAIN.ERROR_MESSAGE')}: {selectedLogEntry.ErrorMessage}</Text>
              )}
              <Text style={{ marginBottom: '5px' }}>
                {t('ARTIFACTS.DOCUMETNS_PROCESSED')}: {selectedLogEntry.DocumentProcessedCount}
              </Text>
              <Text style={{ marginBottom: '5px' }}>
                {t('ARTIFACTS.DOCUMENTS_FAILED')}: {selectedLogEntry.DocumentFailedCount}
              </Text>
            </Stack>

            <Text variant='large' block style={{ marginTop: '15px' }}>
              <strong>{t('ARTIFACTS.DOCUMENTS_LOG')} {selectedLogEntry.LibraryTitle}</strong>
            </Text>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <DetailsList
                items={selectedLogEntry.DocumentLogEntries}
                columns={documentLogColumns}
                setKey='set'
                layoutMode={DetailsListLayoutMode.fixedColumns}
                selectionMode={SelectionMode.none}
              />
            </div>
          </Stack>
        ) : (
          <Text variant='large' block>
            {t('ARTIFACTS.SELECT_MIGRATION_LOG')}
          </Text>
        )}
      </StackItem>
    </Stack>
  );
};

export default ImportArtifactReport;
