import {
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { FC, useContext, useEffect, useState } from "react";
import { SettingsServiceContext } from "../../../Services/API/SettingsService";
import ISettings from "../../../Models/API/ISettings";
import { DialogServiceContext } from "../../../Services/Dialogs/DialogService";
import moment from "moment";
import { trackPageView } from "../../../Services/AppInsights";
import { useTranslation } from "react-i18next";

const PromptV1Settings: FC = () => {
  const settingsService = useContext(SettingsServiceContext);
  const dialogService = useContext(DialogServiceContext);

  const [settings, setSettings] = useState<ISettings | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [currentKey, setCurrentKey] = useState<string>("search");

  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const execute = async () => {
      var reply = await settingsService!.Get();
      if (isMounted) setSettings(reply);
    };
    execute();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSettings = async () => {
    setSaving(true);
    await settingsService?.Save(settings!);
    dialogService?.showSuccessDialog(
      t("SETTINGS.SETTINGS_SAVED"),
      t("SETTINGS.SETTINGS_SAVED_DESCRIPTION")
    );
    setSaving(false);
  };

  return (
    (settings && (
      <>
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack.Item>
            <Pivot
              aria-label="Chat App Options"
              selectedKey={currentKey}
              onLinkClick={(item) => setCurrentKey(item?.props.itemKey!)}
            >
              <PivotItem itemKey="search" headerText="User Intent" itemIcon="Search">
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.first_prompt}
                    onChange={(event) =>
                      setSettings({
                        ...settings,
                        first_prompt: event.currentTarget.value,
                      })
                    }
                    label={t("SETTINGS.SEARCH_KEYWORDS_PROMPT")}
                    multiline
                    rows={35}
                  />
                  <Text variant={"xSmall"} block>
                    {t("SETTINGS.SEARCH_KEYWORDS_PROMPT_DESCRIPTION")}
                    <br />
                    <strong>
                      {"{"}conversation_history{"}"}
                    </strong>{" "}
                    - {t("SETTINGS.CONVERSATION_HISTORY_DESCRIPTION")}
                    <br />
                    <strong>
                      {"{"}name{"}"}
                    </strong>{" "}
                    - {t("SETTINGS.NAME_DESCRIPTION")}
                    <br />
                    <strong>
                      {"{"}office_location{"}"}
                    </strong>{" "}
                    - {t("SETTINGS.OFFICE_LOCATION_DESCRIPTION")}
                    <br />
                    <strong>
                      {"{"}job_title{"}"}
                    </strong>{" "}
                    - {t("SETTINGS.JOB_TITLE_DESCRIPTION")}
                    <br />
                    <strong>
                      {"{"}country{"}"}
                    </strong>{" "}
                    - {t("SETTINGS.COUNTRY_DESCRIPTION")}
                  </Text>
                </Stack.Item>
              </PivotItem>

              <PivotItem
                itemKey="noSources"
                headerText={t("SETTINGS.NO_SOURCES")}
                itemIcon="Cancel"
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.no_model_sources_prompt}
                    onChange={(event) =>
                      setSettings({
                        ...settings,
                        no_model_sources_prompt: event.currentTarget.value,
                      })
                    }
                    label={t("SETTINGS.NO_SOURCES_MODEL_PROMPT")}
                    multiline
                    rows={35}
                  />
                  <Text variant={"xSmall"} block>
                    {t("SETTINGS.NO_SOURCES_DESCRIPTION")}
                    <br />
                    <strong>
                      {"{"}sources{"}"}
                    </strong>{" "}
                    - {t("SETTINGS.SOURCES_DESCRIPTION")}
                    <br />
                    <strong>
                      {"{"}no_answer_message{"}"}
                    </strong>{" "}
                    - {t("SETTINGS.NO_ANSWER_MESSAGE_DESCRIPTION")}
                  </Text>
                </Stack.Item>
              </PivotItem>

              <PivotItem
                itemKey="allowModels"
                headerText={t("SETTINGS.ALLOW_SOURCES")}
                itemIcon="World"
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.allow_model_sources_prompt}
                    onChange={(event) =>
                      setSettings({
                        ...settings,
                        allow_model_sources_prompt: event.currentTarget.value,
                      })
                    }
                    label={t("SETTINGS.ALLOW_MODEL_SOURCES_PROMPT")}
                    multiline
                    rows={35}
                  />
                  <Text variant={"xSmall"} block>
                    {t("SETTINGS.ALLOW_SOURCES_DESCRIPTION")}
                    <br />
                    <strong>
                      {"{"}sources{"}"}
                    </strong>{" "}
                    - {t("SETTINGS.SOURCES_DESCRIPTION")}
                    <br />
                    <strong>
                      {"{"}no_answer_message{"}"}
                    </strong>{" "}
                    - {t("SETTINGS.NO_ANSWER_MESSAGE_DESCRIPTION")}
                  </Text>
                </Stack.Item>
              </PivotItem>

              <PivotItem
                itemKey="function"
                headerText={t("SETTINGS.FUNCTIONS")}
                itemIcon="Code"
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.function_call_prompt}
                    onChange={(event) =>
                      setSettings({
                        ...settings,
                        function_call_prompt: event.currentTarget.value,
                      })
                    }
                    label={t("SETTINGS.FUNCTIONS_PROMPT")}
                    multiline
                    rows={10}
                  />
                  <Text variant={"xSmall"} block>
                    {t("SETTINGS.FUNCTIONS_DESCRIPTION")}
                  </Text>
                </Stack.Item>
              </PivotItem>

              <PivotItem
                itemKey="information"
                headerText={t("SETTINGS.INFORMATION")}
                itemIcon="Info"
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.user_information_prompt}
                    onChange={(event) =>
                      setSettings({
                        ...settings,
                        user_information_prompt: event.currentTarget.value,
                      })
                    }
                    label={t('SETTINGS.USER_INFORMATION_PROMPT')}
                    multiline
                    rows={5}
                  />
                  <Text variant={"xSmall"} block>
                    {t("SETTINGS.USER_INFORMATION_DESCRIPTION")}
                    <br />
                    <strong>
                      {"{"}name{"}"}
                    </strong>{" "}
                    - {t("SETTINGS.NAME_DESCRIPTION")}
                    <br />
                    <strong>
                      {"{"}office_location{"}"}
                    </strong>{" "}
                    - {t("SETTINGS.OFFICE_LOCATION_DESCRIPTION")}
                    <br />
                    <strong>
                      {"{"}job_title{"}"}
                    </strong>{" "}
                    - {t("SETTINGS.JOB_TITLE_DESCRIPTION")}
                    <br />
                    <strong>
                      {"{"}country{"}"}
                    </strong>{" "}
                    - 
                  </Text>
                </Stack.Item>

                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.generic_prompt}
                    onChange={(event) =>
                      setSettings({
                        ...settings,
                        generic_prompt: event.currentTarget.value,
                      })
                    }
                    label={t('SETTINGS.GENERIC_PROMPT')}
                    multiline
                    rows={5}
                  />
                  <Text variant={"xSmall"} block>
                    {t("SETTINGS.GENERIC_PROMPT_DESCRIPTION")}
                    <br />
                    <strong>
                      {"{"}date{"}"}
                    </strong>{" "}
                    - {t('SETTINGS.DATE_DESCRIPTION')} ({moment().format("DD/MM/yyyy")})
                    <br />
                    <strong>
                      {"{"}day{"}"}
                    </strong>{" "}
                    - {t("SETTINGS.DAY_DESCRIPTION")} ({moment().date()})
                    <br />
                    <strong>
                      {"{"}weekday{"}"}
                    </strong>{" "}
                    - {t("SETTINGS.WEEKDAY_DESCRIPTION")} (
                    {moment.weekdays(moment().weekday())})
                    <br />
                    <strong>
                      {"{"}month{"}"}
                    </strong>{" "}
                    - {t("SETTINGS.MONTH_DESCRIPTION")} ({moment.months(moment().month())})
                    <br />
                    <strong>
                      {"{"}year{"}"}
                    </strong>{" "}
                    - {t("SETTINGS.YEAR_DESCRIPTION")} ({moment().year()})
                  </Text>
                </Stack.Item>
              </PivotItem>
            </Pivot>
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                onClick={saveSettings}
                text={saving ? `${t('MAIN.SAVING')}...` : t('MAIN.SAVE')}
                allowDisabledFocus
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default PromptV1Settings;
