import { FC } from "react";
import ApiHttpService from "../../Services/API/ApiHttpService";
import CacheService from "../../Services/API/CacheService";
import ChatService from "../../Services/API/ChatService";
import ConversationSettingsService from "../../Services/API/ConversationSettingsService";
import DocumentService from "../../Services/API/DocumentService";
import HttpService from "../../Services/API/HttpService";
import LibraryService from "../../Services/API/LibraryService";
import SettingsService from "../../Services/API/SettingsService";
import ToastService from "../../Services/ToastService";
import GraphHttpService from "../../Services/MSGraph/GraphHttpService";
import PrincipalService from "../../Services/MSGraph/PrincipalService";
import CatalogService from "../../Services/API/CatalogService";
import ChatAppService from "../../Services/API/ChatAppService";
import AppCatalogService from "../../Services/AppCatalogService";
import AgentAppService from "../../Services/API/AgentAppService";
import AutomateAppService from "../../Services/API/AutomateAppService";
import UserService from "../../Services/API/UserService";
import AnalyticsService from "../../Services/API/AnalyticsService";
import ArtifactsService from "../../Services/API/ArtifactsService";
import AiToolAppService from "../../Services/API/AiToolAppService";
import SpoHttpService from "../../Services/API/SpoHttpService";
import SpoService from "../../Services/API/SpoService";
import SpLibraryService from "../../Services/API/SpLibraryService";
import AiIntegratedAppService from "../../Services/API/AiIntegratedAppService";
import AiServiceCatalogApiHttpService from "../../Services/API/AiServiceCatalogApiHttpService";
import LanguageService from "../../Services/LanguageService";

const Services: FC = ({ children }) => {
  return (
    <>
      <LanguageService>
        <ToastService>
          <CacheService>
            <HttpService>
              <ApiHttpService>
                <AiServiceCatalogApiHttpService>
                  <SpoHttpService>
                    <SpoService>
                      <GraphHttpService>
                        <SettingsService>
                          <ChatService>
                            <LibraryService>
                              <SpLibraryService>
                                <CatalogService>
                                  <AnalyticsService>
                                    <ArtifactsService>
                                      <AiIntegratedAppService>
                                        <ChatAppService>
                                          <AgentAppService>
                                            <AutomateAppService>
                                              <AiToolAppService>
                                                <DocumentService>
                                                  <PrincipalService>
                                                    <ConversationSettingsService>
                                                      <AppCatalogService>
                                                        <UserService>{children}</UserService>
                                                      </AppCatalogService>
                                                    </ConversationSettingsService>
                                                  </PrincipalService>
                                                </DocumentService>
                                              </AiToolAppService>
                                            </AutomateAppService>
                                          </AgentAppService>
                                        </ChatAppService>
                                      </AiIntegratedAppService>
                                    </ArtifactsService>
                                  </AnalyticsService>
                                </CatalogService>
                              </SpLibraryService>
                            </LibraryService>
                          </ChatService>
                        </SettingsService>
                      </GraphHttpService>
                    </SpoService>
                  </SpoHttpService>
                </AiServiceCatalogApiHttpService>
              </ApiHttpService>
            </HttpService>
          </CacheService>
        </ToastService>
      </LanguageService>
    </>
  );
};

export default Services;
