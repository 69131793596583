import { FC, useContext, useEffect, useState } from 'react';
import { Image, Link, Spinner, SpinnerSize, StackItem, Text } from '@fluentui/react';
import styles from './AITool.module.scss';
import { useNavigate } from 'react-router-dom';
import tool_logo from '../../../Assets/Images/aiappscatalog/tool_logo.png';
import IAIToolApp from '../../../Models/API/IAIToolApp';
import { aiToolAppServiceContext } from '../../../Services/API/AiToolAppService';
import { useTranslation } from 'react-i18next';

export interface AIToolProps {
  tool: IAIToolApp;
}

const AITool: FC<AIToolProps> = ({ tool }) => {
  const navigate = useNavigate();
  var aiToolAppService = useContext(aiToolAppServiceContext);
  const { t } = useTranslation();

  const [loadingLogo, setLoadingLogo] = useState<boolean>(false);
  const [imageData, setImageData] = useState<string | null>(null);

  const getAiToolLogo = async () => {
    setLoadingLogo(true);

    try {
      const binaryData = await aiToolAppService?.GetLogo(tool.Id);
      if (binaryData) {
        const data = `data:image/png;base64, ${binaryData}`;
        setImageData(data);
      }
    } catch (error) {
      // ignore error (no logo)
    }
    setLoadingLogo(false);
  };

  useEffect(() => {
    const execute = async () => {
      await getAiToolLogo();
    };

    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StackItem className={styles.tool}>
      <div className={styles.image}>
        {loadingLogo ? (
          <Spinner size={SpinnerSize.large} />
        ) : (
          <img src={imageData ? imageData : tool_logo} alt={tool.DisplayName} />
        )}
      </div>
      <div className={styles.content}>
        <Text block variant='mediumPlus' style={{ marginBottom: 20 }}>
          <strong>{tool.DisplayName}</strong>
        </Text>
        <Text variant='small' block style={{ marginBottom: 30 }}>
          {tool.Description}
        </Text>
        {(tool.Published && tool.AppUrl && (
          <Link
            onClick={_ =>
              navigate({
                pathname: tool.Id.toString(),
              })
            }
            style={{ fontSize: 13 }}
          >
            {t('MAIN.TRY_IT')} →
          </Link>
        )) || <Text variant='xSmall'>{t('MAIN.COMING_SOON')}</Text>}
      </div>
    </StackItem>
  );
};

export default AITool;
