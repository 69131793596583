import { Icon } from '@fluentui/react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CodeProps } from 'react-markdown/lib/ast-to-react';

const CodeComponent: FC<CodeProps> = props => {
  const { children, className, node, inline } = props;
  const [wrap, setWrap] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    (!inline && (
      <>
        <div className='code-header'>
          <div className='left'>{className?.replace('language-', '')}</div>
          <div className='right'>
            <button
              type='button'
              title={t('DEBUG.TOGGLE_WRAP')} 
              onClick={() => {
                setWrap(!wrap);
              }}
            >
              <Icon iconName='TextBox' />
            </button>
            <button
              type='button'
              title={t('DEBUG.COPY_CODE')}
              onClick={() => navigator.clipboard.writeText((node.children[0] as any)['value'])}
            >
              <Icon iconName='Copy' />
            </button>
          </div>
        </div>
        <div className='code-container'>
          <code className={wrap ? 'wrap' : ''}>{children}</code>
        </div>
      </>
    )) || <>{children}</>
  );
};

export default CodeComponent;
