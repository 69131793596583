import Services from './Components/Services/Services';
import VersionManagerService from './Services/VersionManagerService';
import DialogService from './Services/Dialogs/DialogService';
import { BrowserRouter } from 'react-router-dom';
import { AppStart } from './Pages/Start/AppStart';
import { useEffect } from 'react';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { initializeIcons } from '@fluentui/react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './Services/AppInsights';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import './i18n';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';

export const App = ({ loading }: any) => {
  useEffect(() => {
    initializeFileTypeIcons();
    initializeIcons();
    TimeAgo.addDefaultLocale(en);
  }, []);

  return (
    <Services>
      <VersionManagerService>
        <DialogService>
          <BrowserRouter>
            <AppInsightsContext.Provider value={reactPlugin}>
              <I18nextProvider i18n={i18n}>
                <AppStart loading={loading} />
              </I18nextProvider>
            </AppInsightsContext.Provider>
          </BrowserRouter>
        </DialogService>
      </VersionManagerService>
    </Services>
  );
};
