import { FC } from "react";
import {
  IAutomateAppInputParameter,
  IAutomateAppParameter,
} from "../../../Models/API/IAutomateApp";
import { PrimaryButton, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import ArrayType from "./FormTypes/ArrayType";
import BooleanType from "./FormTypes/BooleanType";
import FileType from "./FormTypes/FileType";
import NumberType from "./FormTypes/NumberType";
import StringType from "./FormTypes/StringType";
import { IFormData } from "../AutomateRun";
import { useTranslation } from "react-i18next";

export interface IInputParamsProps {
  inputParams: IAutomateAppInputParameter[];
  formData: IFormData[];
  executing: boolean;
  canExecute: boolean;
  setFormData(formData: IFormData[]): void;
  runAutomateApp(): Promise<void>;
}

const InputParams: FC<IInputParamsProps> = ({
  inputParams,
  formData,
  executing,
  canExecute,
  setFormData,
  runAutomateApp,
}) => {
  const setData = (param: IAutomateAppParameter, value: any) => {
    const filtered = formData.filter(
      (x) => x.internalName !== param.InternalName
    );
    
    if (
      (param.Type === "Array" && value && value.length > 0) ||
      (param.Type !== "Array" && value !== undefined) // To accout for false boolean values
    ) {
      const formData = [
        ...filtered,
        {
          internalName: param.InternalName!,
          type: param.Type,
          value: value!,
        },
      ];
      setFormData(formData);
    } else {
      const filteredTwo = formData.filter(
        (x) => x.internalName !== param.InternalName
      );
      setFormData([...filteredTwo]);
    }
  };

  const { t } = useTranslation();

  const getFormField = (param: IAutomateAppParameter) => {
    switch (param.Type) {
      case "Boolean":
        return <BooleanType param={param} setData={setData} />;
      case "String":
        return <StringType param={param} setData={setData} />;
      case "Number":
        return <NumberType param={param} setData={setData} />;
      case "Array":
        return <ArrayType param={param} setData={setData} />;
      case "File":
        return <FileType param={param} setData={setData} />;
    }
  };

  const buildForm = (params: IAutomateAppParameter[]) => {
    return (
      <>
        {params.map((x, index) => (
          <Stack.Item key={index}>{getFormField(x)}</Stack.Item>
        ))}
      </>
    );
  };

  return (
    <>
      {buildForm(inputParams)}
      <Stack.Item align="end">
        <PrimaryButton
          style={{ width: 200 }}
          onClick={!executing ? runAutomateApp : undefined}
          disabled={!canExecute}
          text={executing ? "" : t("MAIN.RUN")}
        >
          {executing && (
            <Spinner hidden={!executing} size={SpinnerSize.small} />
          )}
        </PrimaryButton>
      </Stack.Item>
    </>
  );
};

export default InputParams;
