import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import IAppCatalog from '../../../../../Models/API/IAppCatalog';
import {
  Stack,
  TextField,
  DefaultButton,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Slider,
  IComboBoxOption,
  Text,
  Toggle,
  StackItem,
} from '@fluentui/react';
import { INewChatApp, chatAppServiceContext } from '../../../../../Services/API/ChatAppService';
import { trackPageView } from '../../../../../Services/AppInsights';
import { useTranslation } from 'react-i18next';

const defaultNewChatApp: INewChatApp = {
  DisplayName: '',
  Description: '',
  LibraryIds: [],
  SystemPrompt: '',
  NoAnswerMessage: '',
  MaxTokens: 500,
  Temperature: 1,
  PresencePenalty: 0,
  TopP: 1,
  FrequencyPenalty: 0,
  TopResults: 5,
  EnableHybridSearch: true,
  EnableDensitySearch: false,
  EnableModelReRank: false,
  AllowModelSources: false,
  EnableLibraryReferences: false,
  EnableAttachments: false,
  ConversationTimeout: 12,
  Disclaimer: '',
  EnableAnalytics: false,
  HideSources: false,
};

export interface INewChatAppProps {}

export const modelOptions: IComboBoxOption[] = [
  {
    key: 'Gpt3516K',
    text: 'GPT-3.5 Turbo (16K)',
  },
  {
    key: 'Gpt4O',
    text: 'GPT-4o (128K)',
  },
];

const NewChatApp: FC<INewChatAppProps> = () => {
  const navigate = useNavigate();
  const chatAppsService = useContext(chatAppServiceContext);
  const { catalog } = useOutletContext<{ catalog: IAppCatalog }>();

  const { t } = useTranslation();

  const [newChatApp, setNewChatApp] = useState<INewChatApp>(defaultNewChatApp);
  const [creatingChatApp, setCreatingChatApp] = useState<boolean>(false);

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createChatApp = async (): Promise<void> => {
    if (!creatingChatApp) {
      newChatApp.AppCatalogId = catalog.Id;
      setCreatingChatApp(true);
      const newApp = await chatAppsService?.Create(newChatApp);
      if (newApp != null) {
        navigate(`../${newApp!.Id.toString()}`);
      } else {
        setCreatingChatApp(false);
      }
    }
  };

  return (
    <Stack tokens={{ childrenGap: 20 }} verticalFill style={{ minHeight: 0, overflowY: 'auto' }}>
      <Stack.Item>
        <TextField
          required={true}
          label={t('MAIN.DISPLAY_NAME')}
          value={newChatApp.DisplayName}
          onChange={(event, newValue) =>
            setNewChatApp({
              ...newChatApp,
              DisplayName: newValue,
            })
          }
        />
        <Text variant='xSmall'>{t('MAIN.MIN_CHARACTERS', { count: 3 })}</Text>
      </Stack.Item>
      <Stack.Item>
        <TextField
          required={true}
          label={t('MAIN.DESCRIPTION')}
          multiline
          rows={3}
          resizable={false}
          value={newChatApp.Description}
          onChange={(event, newValue) =>
            setNewChatApp({
              ...newChatApp,
              Description: newValue,
            })
          }
        />
        <Text variant='xSmall'>{t('MAIN.MIN_AND_MAX_CHARACTERS', { min: 20, max: 1000 })}</Text>
      </Stack.Item>
      <Stack.Item>
        <TextField
          label={t('SETTINGS.SYSTEM_PROMPT')}
          multiline
          rows={4}
          resizable={false}
          value={newChatApp.SystemPrompt}
          onChange={(event, newValue) =>
            setNewChatApp({
              ...newChatApp,
              SystemPrompt: newValue,
            })
          }
        />
        <Text variant={'xSmall'}>{t('SETTINGS.SYSTEM_PROMPT_DESCRIPTION')}</Text>
      </Stack.Item>
      <Stack.Item>
        <TextField
          label={t('SETTINGS.NO_ANSWER_MESSAGE')}
          multiline
          rows={2}
          resizable={true}
          value={newChatApp.NoAnswerMessage}
          onChange={(event, newValue) =>
            setNewChatApp({
              ...newChatApp,
              NoAnswerMessage: newValue!,
            })
          }
        />
        <Text variant={'xSmall'}>{t('SETTINGS.NO_ANSWER_MESSAGE_DESCRIPTION_2')}</Text>
      </Stack.Item>
      <Stack wrap horizontal>
        <StackItem>
          <Text variant='large'>{t('CATALOG.CHAT_EXPERIENCE')}</Text>
        </StackItem>
      </Stack>
      <Stack.Item>
        <TextField
          label={t('MAIN.DISCLAIMER')}
          multiline
          rows={2}
          resizable={true}
          value={newChatApp.Disclaimer ?? ''}
          onChange={(event, newValue) =>
            setNewChatApp({
              ...newChatApp,
              Disclaimer: newValue!,
            })
          }
        />
        <Text variant={'xSmall'}>{t('CATALOG.DISCLAIMER_DESCRIPTION')}</Text>
      </Stack.Item>
      <Stack wrap horizontal style={{ marginLeft: -10, width: '100%' }}>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Slider
            label={t('CATALOG.CONVERSATION_TIMEOUT')}
            max={24}
            step={1}
            min={1}
            value={newChatApp!.ConversationTimeout}
            showValue
            onChange={val => setNewChatApp({ ...newChatApp, ConversationTimeout: val })}
          />
          <Text variant='xSmall'>{t('CATALOG.CONVERSATION_TIMEOUT_DESCRIPTION')}</Text>
        </Stack.Item>
      </Stack>
      <Stack wrap horizontal style={{ marginLeft: -10 }}>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Toggle
            label={t('CATALOG.ENABLE_LIBRARY_REFERENCES')}
            checked={newChatApp!.EnableLibraryReferences}
            onChange={(evt, checked) =>
              setNewChatApp({
                ...newChatApp,
                EnableLibraryReferences: checked!,
              })
            }
          />
          <Text variant={'xSmall'} block>
            {t('CATALOG.ENABLE_LIBRARY_REFERENCES_DESCRIPTION')}
          </Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Toggle
            label={t('CATALOG.ENABLE_ATTACHMENTS')}
            checked={newChatApp!.EnableAttachments}
            onChange={(evt, checked) =>
              setNewChatApp({
                ...newChatApp,
                EnableAttachments: checked!,
              })
            }
          />
          <Text variant={'xSmall'} block>
            {t('CATALOG.ENABLE_ATTACHMENTS_DESCRIPTION')}
          </Text>
        </Stack.Item>
      </Stack>
      <Stack wrap horizontal>
        <StackItem>
          <Text variant='large'>{t('CATALOG.GENERATION')}</Text>
        </StackItem>
      </Stack>
      <Stack horizontal wrap style={{ marginLeft: -10 }}>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Slider
            label={t('CATALOG.MAX_TOKENS')}
            max={2000}
            step={100}
            min={0}
            value={newChatApp!.MaxTokens}
            showValue
            onChange={val => setNewChatApp({ ...newChatApp, MaxTokens: val })}
          />
          <Text variant='xSmall'>{t('CATALOG.MAX_TOKENS_DESCRIPTION')}</Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Slider
            label={t('CATALOG.TEMPERATURE')}
            max={2}
            step={0.01}
            min={0}
            value={newChatApp!.Temperature}
            showValue
            onChange={val => setNewChatApp({ ...newChatApp, Temperature: val })}
          />
          <Text variant='xSmall'>{t('CATALOG.TEMPERATURE_DESCRIPTION')}</Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Slider
            label={t('CATALOG.TOP_P')}
            max={1}
            step={0.01}
            min={0}
            value={newChatApp!.TopP}
            showValue
            onChange={val => setNewChatApp({ ...newChatApp, TopP: val })}
          />
          <Text variant='xSmall'>{t('CATALOG.TOP_P_DESCRIPTION')}</Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Slider
            label={t('CATALOG.PRECENCE_PENALTY')}
            max={2}
            step={0.01}
            min={-2}
            value={newChatApp!.PresencePenalty}
            showValue
            onChange={val => setNewChatApp({ ...newChatApp, PresencePenalty: val })}
          />
          <Text variant='xSmall'>{t('CATALOG.PRECENCE_PENALTY_DESCRIPTION')}</Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Slider
            label={t('CATALOG.FREQUENCY_PENALTY')}
            max={2}
            step={0.01}
            min={-2}
            value={newChatApp!.FrequencyPenalty}
            showValue
            onChange={val => setNewChatApp({ ...newChatApp, FrequencyPenalty: val })}
          />
          <Text variant='xSmall'>{t('CATALOG.FREQUENCY_PENALTY_DESCRIPTION')}</Text>
        </Stack.Item>
      </Stack>
      <Stack wrap horizontal>
        <StackItem>
          <Text variant='large'>{t('SETTINGS.SEARCH')}</Text>
        </StackItem>
      </Stack>
      <Stack grow wrap horizontal style={{ marginLeft: -10 }}>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Slider
            label={t('CATALOG.TOP_RESULTS')}
            max={10}
            step={1}
            min={1}
            value={newChatApp!.TopResults}
            showValue
            onChange={val => setNewChatApp({ ...newChatApp, TopResults: val })}
          />
          <Text variant='xSmall'>{t('CATALOG.TOP_RESULTS_DESCRIPTION')}</Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Toggle
            label={t('CATALOG.ENABLE_HYBRID_SEARCH')}
            checked={newChatApp!.EnableHybridSearch}
            onChange={(evt, checked) =>
              setNewChatApp({
                ...newChatApp,
                EnableHybridSearch: checked!,
              })
            }
          />
          <Text variant={'xSmall'} block>
            {t('CATALOG.ENABLE_HYBRID_SEARCH_DESCRIPTION')}
          </Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Toggle
            label={t('CATALOG.ENABLE_DENSITY_SEARCH')}
            checked={newChatApp!.EnableDensitySearch}
            onChange={(evt, checked) =>
              setNewChatApp({
                ...newChatApp,
                EnableDensitySearch: checked!,
              })
            }
          />
          <Text variant={'xSmall'} block>
            {t('CATALOG.ENABLE_DENSITY_SEARCH_DESCRIPTION')}
          </Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Toggle
            label={t('CATALOG.ENABLE_MODEL_RERANK')}
            checked={newChatApp!.EnableModelReRank}
            onChange={(evt, checked) =>
              setNewChatApp({
                ...newChatApp,
                EnableModelReRank: checked!,
              })
            }
          />
          <Text variant={'xSmall'} block>
            {t('CATALOG.ENABLE_MODEL_RERANK_DESCRIPTION')}
          </Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Toggle
            label={t('CATALOG.ALLOW_MODEL_SOURCES')}
            checked={newChatApp!.AllowModelSources}
            onChange={(evt, checked) =>
              setNewChatApp({
                ...newChatApp,
                AllowModelSources: checked!,
              })
            }
          />
          <Text variant={'xSmall'} block>
            {t('CATALOG.ALLOW_MODEL_SOURCES_DESCRIPTION')}
          </Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Toggle
            label={t('CATALOG.HIDE_SOURCES')}
            checked={newChatApp!.HideSources}
            onChange={(evt, checked) =>
              setNewChatApp({
                ...newChatApp,
                HideSources: checked!,
              })
            }
          />
          <Text variant={'xSmall'} block>
            {t('CATALOG.HIDE_SOURCES_DESCRIPTION')}
          </Text>
        </Stack.Item>
      </Stack>
      <Stack.Item>
        <Stack wrap horizontal style={{ marginTop: '1rem' }}>
          <StackItem>
            <Text variant='large'>{t('SETTINGS.ANALYTICS')}</Text>
          </StackItem>
        </Stack>
        <Stack wrap horizontal style={{ marginLeft: -10 }}>
          <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
            <Toggle
              label={t('CATALOG.ENABLE_ANALYTICS')}
              checked={newChatApp!.EnableAnalytics}
              onChange={(evt, checked) =>
                setNewChatApp({
                  ...newChatApp,
                  EnableAnalytics: checked!,
                })
              }
            />
            <Text variant={'xSmall'} block>
              {t('CATALOG.ENABLE_ANALYTICS_DESCRIPTION')}
            </Text>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <DefaultButton
            onClick={() => navigate(`/catalogseditor/${catalog.Id}`)}
            text={t('MAIN.CANCEL')}
          />
          <PrimaryButton
            style={{ minWidth: 80 }}
            onClick={createChatApp}
            disabled={
              !newChatApp.DisplayName ||
              newChatApp.DisplayName.length < 3 ||
              !newChatApp.Description ||
              newChatApp.Description.length < 20 ||
              newChatApp.Description.length > 1000
            }
            text={creatingChatApp ? '' : t('MAIN.CREATE')}
          >
            {creatingChatApp && <Spinner size={SpinnerSize.small} />}
          </PrimaryButton>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default NewChatApp;
