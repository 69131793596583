import { FC, useContext, useEffect } from "react";
import { LibraryServiceContext } from "../../../../../../../../Services/API/LibraryService";
import { useOutletContext } from "react-router-dom";
import ILibrary from "../../../../../../../../Models/API/ILibrary";
import IPermission from "../../../../../../../../Models/API/IPermission";
import PermissionManagement from "../../../../../../../../Components/PermissionManagement/PermissionManagement";
import { trackPageView } from "../../../../../../../../Services/AppInsights";
import { useTranslation } from "react-i18next";

const LibraryPermissions: FC = () => {
  const { library } = useOutletContext<{ library: ILibrary }>();
  const librariesService = useContext(LibraryServiceContext);
  const { t } = useTranslation();

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _addPermission = async (objectId: string, permissionType: string) => {
    return await librariesService!.CreatePermission(
      library.Id,
      objectId,
      permissionType as any
    );
  };

  const _deletePermission = async (objectId: string) => {
    await librariesService!.DeletePermission(library.Id, objectId);
  };

  const _reloadPermissions = async () => {
    var perms = await librariesService!.GetPermissions(library.Id);
    return perms as IPermission[];
  };

  const _checkPermissions = async (objectId: string) => {
    var perms = await librariesService!.CheckUserPermissions(
      objectId,
      library.Id
    );
    return perms ?? [];
  };

  return (
    <PermissionManagement
      resourceType={"Library"}
      resourceId={library.Id}
      permissions={{
        Read: {
          DisplayName: t("PERMISSIONS.READ"),
          Description:
            t("PERMISSIONS.READ_DESCRIPTION_3"),
        },
        Write: {
          DisplayName: t("PERMISSIONS.WRITE"),
          Description: t("PERMISSIONS.WRITE_DESCRIPTION_3"),
        },
        FullControl: {
          DisplayName: t("PERMISSIONS.FULL_CONTROL"),
          Description: t("PERMISSIONS.FULL_CONTROL_DESCRIPTION_3"),
        },
        Owner: {
          DisplayName: t("PERMISSIONS.OWNER"),
          Description: t("PERMISSIONS.OWNER_DESCRIPTION_3"),
        },
      }}
      isOwner={library.PermissionType === "Owner"}
      addPermission={_addPermission}
      deletePermission={_deletePermission}
      reloadPermissions={_reloadPermissions}
      checkPermissions={_checkPermissions}
    />
  );
};

export default LibraryPermissions;
