import { FC, useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import IChatApp from '../../../../../../Models/API/IChatApp';
import { agentAppServiceContext } from '../../../../../../Services/API/AgentAppService';
import { chatAppServiceContext } from '../../../../../../Services/API/ChatAppService';
import {
  Stack,
  CommandBar,
  SearchBox,
  DetailsList,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  ICommandBarItemProps,
  IColumn,
  Text,
  Selection,
  Icon,
  ComboBox,
  IComboBox,
  IComboBoxOption,
} from '@fluentui/react';
import IAgentApp from '../../../../../../Models/API/IAgentApp';
import { removeDiacritics } from '../../../../../../Utilities/Strings';
import { trackPageView } from '../../../../../../Services/AppInsights';
import ConfigurationService from '../../../../../../Services/ConfigurationService';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { LanguageServiceContext } from '../../../../../../Services/LanguageService';

export interface IChatAppsAgentsProps {}

const ChatAppsAgents: FC<IChatAppsAgentsProps> = () => {
  const chatAppsService = useContext(chatAppServiceContext);
  const agentAppsService = useContext(agentAppServiceContext);
  const languageService = useContext(LanguageServiceContext);

  const { chatApp } = useOutletContext<{ chatApp: IChatApp }>();

  const [agentsForCombo, setAgentsForCombo] = useState<IComboBoxOption[]>([]);

  const [allAgents, setAllAgents] = useState<IAgentApp[] | null>(null);
  const [chatAppAgents, setChatAppAgents] = useState<IAgentApp[] | null>(null);
  const [filteredAgents, setFilteredAgents] = useState<IAgentApp[] | null | undefined>(undefined);

  const [filterKeyword, setFilterKeyword] = useState<string | undefined>(undefined);
  const [selectedAgents, setSelectedAgents] = useState<IAgentApp[]>([]);
  const [commandBarButtons, setCommandBarButtons] = useState<ICommandBarItemProps[]>([]);
  const [hideRemoveAgentsDialog, setHideRemoveAgentsDialog] = useState<boolean>(true);
  const [hideAddAgentsDialog, setHideAddAgentsDialog] = useState<boolean>(true);
  const [selectedComboAgent, setSelectedComboAgent] = useState<string | undefined>(undefined);
  const [addingAgents, setAddingAgents] = useState<boolean>(false);

  const { t } = useTranslation();

  const shimmerColumns = (t: TFunction<'translation', undefined, 'translation'>) =>
    [
      {
        key: 'Icon',
        name: '',
        minWidth: 20,
        maxWidth: 20,
        isResizable: true,
      },
      {
        key: 'DisplayName',
        name: t('MAIN.DISPLAY_NAME'),
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
      },
      {
        key: 'Description',
        name: t('MAIN.DESCRIPTION'),
        minWidth: 400,
        isResizable: true,
      },
    ] satisfies IColumn[];

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadAgentApps = async () => {
    setChatAppAgents([]);
    setFilteredAgents(undefined);
    let chatAppAgents = await chatAppsService!.GetChatAgents(chatApp.Id);
    chatAppAgents = chatAppAgents!.sort((permA, permB) =>
      permA.DisplayName.localeCompare(permB.DisplayName)
    );
    setFilteredAgents(chatAppAgents);
    setChatAppAgents(chatAppAgents);

    const allAgents = await agentAppsService?.GetAll();
    if (allAgents) {
      setAllAgents(allAgents);
      const toDisplay = allAgents.filter(x => !chatAppAgents?.some(y => y.Id === x.Id));
      setAgentsForCombo(
        toDisplay.map(x => {
          return {
            key: x.InternalName,
            text: x.DisplayName,
          };
        })
      );
    }
    setSelectedComboAgent(undefined);
  };

  const onRenderItemColumn = (item: IAgentApp, index?: number, column?: IColumn): any => {
    if (column?.key === 'Icon') {
      return <Icon style={{ fontSize: '1rem' }} iconName='ChatBot' />;
    } else if (column?.key === 'DisplayName') {
      return item.DisplayName;
    } else if (column?.key === 'Description') {
      return item.Description;
    } else {
      return item![column?.key as keyof IAgentApp];
    }
  };

  const selection = new Selection({
    canSelectItem(item, index) {
      return true;
    },
    onSelectionChanged: () => {
      setSelectedAgents(selection.getSelection() as IAgentApp[]);
    },
  });

  const resetState = (): void => {
    setHideRemoveAgentsDialog(true);
    setChatAppAgents([]);
    setFilteredAgents(undefined);
    setSelectedAgents([]);
    setFilterKeyword(undefined);
  };

  const removeAgents = async (): Promise<void> => {
    const promises: (Promise<void | null> | undefined)[] = [];
    const selectedAgts = [...selectedAgents];
    resetState();
    for (let i = 0; i < selectedAgts.length; i++) {
      const perm = selectedAgts[i];
      promises.push(chatAppsService!.DeleteChatAgent(chatApp.Id, perm.Id));
    }
    await Promise.allSettled(promises);
    reloadAgentApps();
  };

  const onAddAgentsComboChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ): void => {
    if (option) {
      setSelectedComboAgent(option.key as string);
    }
  };

  const addAgentsToChatApp = async () => {
    if (selectedComboAgent && !addingAgents) {
      setAddingAgents(true);
      const agent = allAgents?.find(x => x.InternalName === selectedComboAgent);
      if (agent) {
        await chatAppsService!.AddChatAgent(chatApp.Id, agent.Id);
      }
      reloadAgentApps();
      setAddingAgents(false);
      setHideAddAgentsDialog(true);
    }
  };

  useEffect(() => {
    setCommandBarButtons([
      {
        key: 'add',
        text: t('MAIN.ADD'),
        iconProps: { iconName: 'Add' },
        onClick: (event, item) => {
          setHideAddAgentsDialog(false);
        },
      },
      {
        key: 'remove',
        text: t('MAIN.REMOVE'),
        iconProps: { iconName: 'Delete' },
        onClick: (event, item) => {
          setHideRemoveAgentsDialog(false);
        },
        disabled: selectedAgents.length === 0,
      },
      {
        key: 'refresh',
        text: t('MAIN.REFRESH'),
        iconProps: { iconName: 'Refresh' },
        onClick: (event, item) => {
          reloadAgentApps();
        },
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAgents, chatApp, languageService?.language]);

  // Runs when the filter keyword changes
  useEffect(() => {
    if (chatAppAgents && filterKeyword !== undefined) {
      setFilteredAgents(
        filterKeyword
          ? chatAppAgents?.filter(perm => {
              return (
                removeDiacritics(perm.DisplayName.toLowerCase()).indexOf(
                  removeDiacritics(filterKeyword.toLowerCase())
                ) !== -1
              );
            })
          : chatAppAgents
      );
    }
  }, [filterKeyword, chatAppAgents]);

  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${chatApp.DisplayName} Agents`;
    const execute = async () => {
      reloadAgentApps();
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0 }}>
        <Stack.Item>
          <CommandBar items={commandBarButtons} ariaLabel='Agents actions' />
          <SearchBox
            value={filterKeyword}
            onChange={(evt, newValue) => {
              setFilterKeyword(newValue);
            }}
            placeholder={t('FILTERS.FILTER_BY_AGENT_NAME')}
            iconProps={{ iconName: 'Filter' }}
            underlined={true}
          />
        </Stack.Item>
        <Stack.Item verticalFill style={{ overflowY: 'auto', minHeight: 0, position: 'relative' }}>
          <DetailsList
            setKey='items'
            items={filteredAgents || []}
            columns={shimmerColumns(t)}
            selection={selection}
            selectionMode={SelectionMode.multiple}
            onRenderItemColumn={onRenderItemColumn}
            // enableShimmer={!filteredAgents}
            //ariaLabelForShimmer="Content is being fetched"
            ariaLabelForGrid='Item details'
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            className='documents-list'
          />
          {(filteredAgents === undefined || filteredAgents?.length === 0) && (
            <>
              {filteredAgents?.length === 0 && (
                <Text
                  variant='large'
                  block
                  style={{
                    textAlign: 'center',
                    opacity: 0.5,
                  }}
                >
                  {t('TABLE.NO_AGENTS')}
                </Text>
              )}
              {filteredAgents === undefined && <Spinner size={SpinnerSize.large} />}
            </>
          )}
        </Stack.Item>
      </Stack>
      <Dialog
        hidden={hideRemoveAgentsDialog}
        onDismiss={() => setHideRemoveAgentsDialog(true)}
        modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } } }}
        dialogContentProps={{
          type: DialogType.normal,
          title: t('CATALOG.REMOVE_AGENTS'),
          subText: t('CATALOG.REMOVE_AGENTS_CONFIRMATION'),
        }}
      >
        <Stack tokens={{ childrenGap: 5 }}>
          {selectedAgents.map(perm => {
            return (
              <Text key={perm.InternalName} block>
                {perm.DisplayName}
              </Text>
            );
          })}
        </Stack>
        <DialogFooter>
          <PrimaryButton onClick={removeAgents} text={t('MAIN.REMOVE')} />
          <DefaultButton onClick={() => setHideRemoveAgentsDialog(true)} text={t('MAIN.CANCEL')} />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={hideAddAgentsDialog}
        onDismiss={() => setHideAddAgentsDialog(true)}
        modalProps={{
          isBlocking: true,
          styles: {
            main: { minWidth: '450px !important', maxWidth: 450 },
          },
        }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: t('CATALOG.ADD_AGENT'),
        }}
      >
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack.Item>
            <ComboBox
              label='Agents'
              allowFreeform={false}
              autoComplete={'on'}
              options={agentsForCombo}
              onChange={onAddAgentsComboChange}
            />
          </Stack.Item>
        </Stack>
        <DialogFooter>
          <PrimaryButton
            style={{ minWidth: 150 }}
            disabled={!selectedComboAgent}
            onClick={addAgentsToChatApp}
            text={addingAgents ? '' : t('CATALOG.ADD_AGENT')}
          >
            {addingAgents && <Spinner size={SpinnerSize.small} />}
          </PrimaryButton>
          <DefaultButton onClick={() => setHideAddAgentsDialog(true)} text={t('MAIN.CANCEL')} />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ChatAppsAgents;
