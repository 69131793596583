import { FC, useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { PrimaryButton, Spinner, SpinnerSize, Stack, Text, TextField } from '@fluentui/react';
import IAgentApp from '../../../../../../Models/API/IAgentApp';
import styles from './Settings.module.scss';
import { agentAppServiceContext } from '../../../../../../Services/API/AgentAppService';
import { trackPageView } from '../../../../../../Services/AppInsights';
import { DialogServiceContext } from '../../../../../../Services/Dialogs/DialogService';
import ConfigurationService from '../../../../../../Services/ConfigurationService';
import { useTranslation } from 'react-i18next';

const AgentAppSettings: FC = () => {
  const dialogService = useContext(DialogServiceContext);
  var agentAppService = useContext(agentAppServiceContext);

  const { t } = useTranslation();

  const { agentApp, setAgentApp } = useOutletContext<{
    agentApp: IAgentApp;
    setAgentApp: (value: IAgentApp) => void;
  }>();

  const [tempAgentApp, setTempAgentApp] = useState<IAgentApp>(agentApp);
  const [saving, setIsSaving] = useState<boolean>(false);

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${agentApp.DisplayName} Settings`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAgentApp = async () => {
    if (tempAgentApp && !saving) {
      setIsSaving(true);
      var refreshedChatApp = await agentAppService?.Update(tempAgentApp!.Id, {
        DisplayName: tempAgentApp.DisplayName,
        Description: tempAgentApp.Description,
        SystemPrompt: tempAgentApp.SystemPrompt,
      });
      if (refreshedChatApp !== null) {
        setAgentApp(refreshedChatApp!);
        setTempAgentApp(refreshedChatApp!);
        dialogService?.showSuccessDialog(
          refreshedChatApp!.DisplayName,
          'All settings have been saved.'
        );
      }
      setIsSaving(false);
    }
  };

  return (
    (tempAgentApp && (
      <>
        <Stack
          verticalFill
          style={{ minHeight: 0, overflowY: 'auto' }}
          tokens={{ childrenGap: 20 }}
        >
          <Stack.Item>
            <TextField
              required={true}
              label={t('MAIN.DISPLAY_NAME')}
              resizable={false}
              value={tempAgentApp.DisplayName}
              onChange={(event, newValue) =>
                setTempAgentApp({
                  ...tempAgentApp,
                  DisplayName: newValue!,
                })
              }
            />
            <Text variant='xSmall'>Minimum 3 characters</Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              required={true}
              label={t('MAIN.DESCRIPTION')}
              multiline
              rows={3}
              resizable={false}
              value={tempAgentApp.Description}
              onChange={(event, newValue) =>
                setTempAgentApp({
                  ...tempAgentApp,
                  Description: newValue!,
                })
              }
            />
            <Text variant='xSmall'>Minimum 20 characters and minimum 1000 characters</Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              label={t('SETTINGS.SYSTEM_PROMPT')}
              multiline
              rows={4}
              resizable={false}
              value={tempAgentApp.SystemPrompt}
              onChange={(event, newValue) =>
                setTempAgentApp({
                  ...tempAgentApp,
                  SystemPrompt: newValue!,
                })
              }
            />
          </Stack.Item>

          <Stack.Item>
            <section className={styles.buttonSection}>
              <PrimaryButton
                style={{ minWidth: 80 }}
                onClick={updateAgentApp}
                disabled={
                  !tempAgentApp.DisplayName ||
                  tempAgentApp.DisplayName.length < 3 ||
                  !tempAgentApp.Description ||
                  tempAgentApp.Description.length < 20 ||
                  tempAgentApp.Description.length > 1000
                }
                text={saving ? '' : 'Save'}
              >
                {saving && <Spinner hidden={!saving} size={SpinnerSize.small} />}
              </PrimaryButton>
            </section>
          </Stack.Item>
        </Stack>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default AgentAppSettings;
