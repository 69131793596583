import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { FC, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import IAppCatalog from '../../Models/API/IAppCatalog';
import IBaseApp from '../../Models/API/IBaseApp';
import { pathMap } from '../../Models/API/AppType';
import ILibrary from '../../Models/API/ILibrary';
import ISpLibrary from '../../Models/API/ISpLibrary';
import { useTranslation } from 'react-i18next';
import { LanguageServiceContext } from '../../Services/LanguageService';

enum BreadcrumbIndexer {
  catalogList = 0,
  catalogId = 1,
  catalogPivot = 2,
  appId = 3,
  appType = 4,
  appPivot = 5,
  newApp = 6,
}

export interface ICatalogEditorBreadcrumbProps {
  catalog: IAppCatalog;
  baseApp: IBaseApp | null;
  library: ILibrary | null;
  spLibrary: ISpLibrary | null;
}

const CatalogEditorBreadcrumb: FC<ICatalogEditorBreadcrumbProps> = ({
  catalog,
  baseApp,
  library,
  spLibrary,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const languageService = useContext(LanguageServiceContext);

  const [breadcrumbItems, setBreadcrumbItems] = useState<IBreadcrumbItem[] | null>(null);

  const _onBreadcrumbItemClicked = (ev: any, item: IBreadcrumbItem | undefined): void => {
    if (item) {
      navigate(`/${item.key}`);
    }
  };

  const buildCatalogBreadCrumb = (): IBreadcrumbItem[] => {
    var breadCrumbs = location.pathname.split('/').map((x, index): IBreadcrumbItem => {
      var length = location.pathname.split('/').length;

      switch (index) {
        case BreadcrumbIndexer.catalogList:
          return {
            text: t('MAIN.CATALOGS'),
            key: 'catalogseditor',
            onClick: _onBreadcrumbItemClicked,
          };
        case BreadcrumbIndexer.catalogId:
          return {
            text: catalog!.DisplayName,
            key: `catalogseditor/${catalog!.Id.toString()}`,
            onClick: length > 4 ? _onBreadcrumbItemClicked : undefined,
          };
        case BreadcrumbIndexer.appId:
          return {
            text: baseApp ? baseApp!.DisplayName : '',
            key: baseApp
              ? `catalogseditor/${catalog!.Id.toString()}/apps/${baseApp.Type.toLowerCase().replace(
                  'app',
                  ''
                )}/${baseApp!.Id.toString()}`
              : '',
            onClick: length > 7 ? _onBreadcrumbItemClicked : undefined,
          };
        case 4:
          const paths = location.pathname.toLowerCase().split('/');
          if (paths.includes('new') && paths.length === 6) {
            const app = location.pathname.split('/')[BreadcrumbIndexer.appType];
            const appName = pathMap
              .get(app.toLowerCase())
              ?.replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
              .replace(/([a-z])([A-Z])/g, '$1 $2')
              .split(' ');
            return {
              text: `${t('MAIN.NEW')} ${appName?.join(' ')}`,
              key: '',
            };
          }
          return {
            text: '',
            key: '',
          };
        case 6: {
          const paths = location.pathname.toLowerCase().split('/');
          //if ((paths.includes('libraries') && !paths.includes('splibraries')) && length > 8) {
          if (paths.includes('splibraries') && length > 8) {
            return {
              text: t('MAIN.SP_LIBRARIES'),
              key: baseApp
                ? `catalogseditor/${catalog!.Id.toString()}/apps/${baseApp.Type.toLowerCase().replace(
                    'app',
                    ''
                  )}/${baseApp!.Id.toString()}/splibraries`
                : '',
              onClick: length > 8 ? _onBreadcrumbItemClicked : undefined,
            };
          }

          if (paths.includes('libraries') && length > 8) {
            return {
              text: t('MAIN.LIBRARIES'),
              key: baseApp
                ? `catalogseditor/${catalog!.Id.toString()}/apps/${baseApp.Type.toLowerCase().replace(
                    'app',
                    ''
                  )}/${baseApp!.Id.toString()}/libraries`
                : '',
              onClick: length > 8 ? _onBreadcrumbItemClicked : undefined,
            };
          }
          return {
            text: '',
            key: '',
          };
        }
        case 7: {
          const paths = location.pathname.toLowerCase().split('/');
          if (paths.includes('new') && paths.length === 8) {
            return {
              text: t('LIBRARIES.NEW_LIBRARY'),
              key: '',
            };
          }
          return {
            text: '',
            key: '',
          };
        }
        case 8: {
          return {
            text: library ? library.Title : '',
            key: '',
          };
        }
        default:
          return {
            text: '',
            key: '',
          };
      }
    });

    return breadCrumbs.filter(x => x.text !== '');
  };

  useEffect(() => {
    setBreadcrumbItems(buildCatalogBreadCrumb());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalog, baseApp, library, spLibrary, location, languageService?.language]);

  return (
    <>
      {breadcrumbItems && (
        <Breadcrumb
          items={breadcrumbItems}
          maxDisplayedItems={5}
          ariaLabel='Breadcrumb'
          overflowAriaLabel='More links'
        />
      )}
    </>
  );
};

export default CatalogEditorBreadcrumb;
