import {
  CommandBar,
  DefaultButton,
  DetailsList,
  Dialog,
  DialogFooter,
  DialogType,
  IColumn,
  ICommandBarItemProps,
  Link,
  PrimaryButton,
  SearchBox,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from '@fluentui/react';
import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Catalogs.scss';
import ConfigurationService from '../../Services/ConfigurationService';
import { removeDiacritics } from '../../Utilities/Strings';
import { catalogServiceContext } from '../../Services/API/CatalogService';
import IAppCatalog from '../../Models/API/IAppCatalog';
import CatalogLogo from '../../Components/CatalogLogo/CatalogLogo';
import { trackPageView } from '../../Services/AppInsights';
import AuthenticationService from '../../Services/AuthenticationService';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { LanguageServiceContext } from '../../Services/LanguageService';

const shimmerColumns = (t: TFunction<'translation', undefined, 'translation'>) =>
  [
    {
      key: 'Icon',
      name: '',
      minWidth: 20,
      maxWidth: 40,
    },
    {
      key: 'Title',
      name: t('MAIN.TITLE'),
      minWidth: 200,
    },
    {
      key: 'NumApps',
      name: t('MAIN.NUMBER_OF_APPS'),
      minWidth: 200,
    },
    {
      key: 'OwnerName',
      name: t('MAIN.OWNER'),
      minWidth: 100,
    },
    {
      key: 'Created',
      name: t('MAIN.CREATED'),
      minWidth: 100,
    },
  ] satisfies IColumn[];

const Catalogs: FC = () => {
  const navigate = useNavigate();
  const catalogsService = useContext(catalogServiceContext);
  const languageService = useContext(LanguageServiceContext);

  const [catalogs, setCatalogs] = useState<IAppCatalog[] | null>(null);
  const [filteredCatalogs, setFilteredCatalogs] = useState<IAppCatalog[] | null | undefined>(
    undefined
  );
  const [selectedCatalogs, setSelectedLibraries] = useState<IAppCatalog[]>([]);
  const [commandBarButtons, setCommandBarButtons] = useState<ICommandBarItemProps[]>([]);
  const [hideDeleteDialog, setHideDeleteDialog] = useState<boolean>(true);
  const [filterKeyword, setFilterKeyword] = useState<string | undefined>(undefined);
  const [isMounted, setIsMounted] = useState<boolean>(true);

  const { t } = useTranslation();

  const onRenderItemColumn = (item: IAppCatalog, index?: number, column?: IColumn): any => {
    if (column?.key === 'Icon') {
      return (
        <div style={{ textAlign: 'center' }}>
          <CatalogLogo
            skipLoad={item.LogoUrl === null}
            key={item.Id}
            height={20}
            catalogId={item.Id}
          />
        </div>
      );
    } else if (column?.key === 'OwnerName') {
      return item.OwnerNames[0];
    } else if (column?.key === 'Created') {
      return moment(item.Created).format('DD MMM yyyy');
    } else if (column?.key === 'Title') {
      return <Link onClick={() => navigateSomehwere(item.Id)}>{item.DisplayName}</Link>;
    } else {
      return item![column?.key as keyof IAppCatalog];
    }
  };

  const navigateSomehwere = (libraryId: number) => {
    navigate(libraryId.toString(), {
      relative: 'route',
    });
  };

  const reloadCatalogs = async () => {
    setCatalogs([]);
    setFilteredCatalogs(undefined);
    let catalogs = await catalogsService!.GetAll();
    if (catalogs) {
      catalogs = catalogs!.sort((catA, catB) => catA.DisplayName.localeCompare(catB.DisplayName));
      setFilteredCatalogs(catalogs);
      setCatalogs(catalogs);
    }
  };

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - Catalogs Editor`;
    if (isMounted) reloadCatalogs();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Runs when the selected catalogs change
  useEffect(() => {
    let barButtons: ICommandBarItemProps[] = [];
    if (AuthenticationService.Default.HasRole('admin')) {
      barButtons = [
        ...barButtons,
        {
          key: 'new',
          text: t('MAIN.NEW'),
          iconProps: { iconName: 'Add' },
          subMenuProps: {
            items: [
              {
                key: 'catalog',
                text: t('MAIN.CATALOG'),
                iconProps: { iconName: 'Folder' },
                onClick: (event, item) => {
                  navigate('new', { relative: 'route' });
                },
              },
            ],
          },
        },
        {
          key: 'delete',
          text: t('MAIN.DELETE'),
          iconProps: { iconName: 'Delete' },
          onClick: (event, item) => {
            setHideDeleteDialog(false);
          },
          disabled: selectedCatalogs.length === 0,
        },
      ];
    }

    barButtons = [
      ...barButtons,
      {
        key: 'refresh',
        text: t('MAIN.REFRESH'),
        iconProps: { iconName: 'Refresh' },
        onClick: (event, item) => {
          resetState();
          reloadCatalogs();
        },
      },
    ];

    setCommandBarButtons(barButtons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCatalogs, languageService?.language]);

  const canSelectLibrary = (item: any, index?: number): boolean => {
    return (item as IAppCatalog).PermissionType === 'Owner';
  };

  const selection = new Selection({
    canSelectItem: canSelectLibrary,
    onSelectionChanged: () => {
      setSelectedLibraries(selection.getSelection() as IAppCatalog[]);
    },
  });

  const resetState = (): void => {
    setHideDeleteDialog(true);

    setCatalogs([]);
    setFilteredCatalogs(undefined);
    setSelectedLibraries([]);
    setFilterKeyword(undefined);
  };

  const deleteCatalogs = async (): Promise<void> => {
    const promises: (Promise<void | null> | undefined)[] = [];
    const selectedCats = [...selectedCatalogs];
    resetState();
    for (let i = 0; i < selectedCats.length; i++) {
      const lib = selectedCats[i];
      promises.push(catalogsService?.Delete(lib.Id));
    }
    await Promise.allSettled(promises);
    reloadCatalogs();
  };

  // Runs when the filter keyword changes
  useEffect(() => {
    if (catalogs && filterKeyword !== undefined) {
      setFilteredCatalogs(
        filterKeyword
          ? catalogs?.filter(
              cat =>
                removeDiacritics(cat.DisplayName.toLowerCase()).indexOf(
                  removeDiacritics(filterKeyword.toLowerCase())
                ) !== -1
            )
          : catalogs
      );
    }
  }, [filterKeyword, catalogs]);

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0 }} tokens={{ childrenGap: 20 }}>
        <Stack.Item>
          <CommandBar items={commandBarButtons} ariaLabel='Catalog actions' />
          <SearchBox
            value={filterKeyword}
            onChange={(evt, newValue) => setFilterKeyword(newValue)}
            placeholder={t('FILTERS.FILTER_BY_TITLE')}
            iconProps={{ iconName: 'Filter' }}
            underlined={true}
          />
        </Stack.Item>
        <Stack.Item verticalFill style={{ overflowY: 'auto' }}>
          <DetailsList
            className='catalogs-list'
            setKey='items'
            items={filteredCatalogs || []}
            columns={shimmerColumns(t)}
            selection={selection}
            selectionMode={SelectionMode.multiple}
            onRenderItemColumn={onRenderItemColumn}
            ariaLabelForGrid='Item details'
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            styles={{ root: { verticalAlign: 'middle' } }}
          />
          {(filteredCatalogs === undefined || filteredCatalogs?.length === 0) && (
            <>
              {filteredCatalogs?.length === 0 && (
                <Text
                  variant='large'
                  block
                  style={{
                    textAlign: 'center',
                    opacity: 0.5,
                  }}
                >
                  {t('TABLE.NO_CATALOGS')}
                </Text>
              )}
              {filteredCatalogs === undefined && <Spinner size={SpinnerSize.large} />}
            </>
          )}
        </Stack.Item>
      </Stack>
      <Dialog
        hidden={hideDeleteDialog}
        onDismiss={() => setHideDeleteDialog(true)}
        modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } } }}
        dialogContentProps={{
          type: DialogType.normal,
          title: t('CATALOG.DELETE_CATALOGS'),
          subText: t('CATALOG.DELETE_CATALOGS_CONFIRMATION'),
        }}
      >
        {selectedCatalogs.map(cat => {
          return (
            <Text key={cat.Id} block>
              {cat.DisplayName}
            </Text>
          );
        })}
        <DialogFooter>
          <PrimaryButton onClick={deleteCatalogs} text={t('MAIN.DELETE')} />
          <DefaultButton onClick={() => setHideDeleteDialog(true)} text={t('MAIN.CANCEL')} />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default Catalogs;
