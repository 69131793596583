import React, { useState } from "react";
import {
  Dropdown,
  IDropdownOption,
  Stack,
  TextField,
  StackItem,
  Text,
} from "@fluentui/react";

interface MultipleLanguageSettingProps {
  baseKey: string;
  label: string;
  languageOptions: IDropdownOption[];
  settings: Record<string, string>;
  onSettingsChange: (key: string, value: string) => void;
  rows?: number;
  description?: string;
}

const MultipleLanguageSetting: React.FC<MultipleLanguageSettingProps> = ({
  baseKey,
  label,
  languageOptions,
  settings,
  onSettingsChange,
  rows,
  description,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const getSettingKey = (lang: string) =>
    lang === "en" ? baseKey : `${baseKey}_${lang}`;

  const currentValue = settings[getSettingKey(selectedLanguage)] || "";

  const handleLanguageChange = (lang: string) => {
    setSelectedLanguage(lang);
  };

  const handleValueChange = (value: string) => {
    onSettingsChange(getSettingKey(selectedLanguage), value);
  };

  return (
    <StackItem style={{ maxWidth: 600 }}>
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 25 }}
        style={{ justifyContent: "space-between" }}
      >
        <label>
          <b>{label}</b>
        </label>
        <Dropdown
          selectedKey={selectedLanguage}
          options={languageOptions}
          onChange={(_, option) =>
            option && handleLanguageChange(option.key as string)
          }
          style={{ width: "10rem", marginBottom: "0.5rem" }}
        />
      </Stack>
      <TextField
        value={currentValue}
        onChange={(_, newValue) => handleValueChange(newValue || "")}
        multiline
        rows={rows}
      />
      {description && (
        <Text variant={"xSmall"} block>
            {description}
        </Text>
      )}
    </StackItem>
  );
};

export default MultipleLanguageSetting;
